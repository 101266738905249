// src/core/hooks/graph_gen/common/useCytoscapeSetup.js
import { useEffect } from 'react';

// Helper to sanitize elements to avoid Cytoscape errors.
function sanitizeElement(el) {
  const clone = { ...el, data: { ...el.data } };

  // Ensure 'id', 'source', 'target', 'parent' are strings.
  ['id', 'source', 'target', 'parent'].forEach((key) => {
    if (clone.data[key] != null && typeof clone.data[key] !== 'string') {
      clone.data[key] = String(clone.data[key]);
    }
  });

  // JSON-stringify non-primitive fields.
  for (const [k, v] of Object.entries(clone.data)) {
    const isPrimitive =
      v == null ||
      typeof v === 'string' ||
      typeof v === 'boolean' ||
      typeof v === 'number';
    if (!isPrimitive) {
      clone.data[k] = JSON.stringify(v);
    }
  }

  return clone;
}

/**
 * Sets up Cytoscape with the provided elements and layout.
 * @param {Object} cyRef - React ref to the Cytoscape instance.
 * @param {Array} elements - Cytoscape nodes/edges.
 * @param {String} layoutType - Layout name (e.g., 'fcose', 'dagre', 'myCustomLayout').
 * @param {Object} onElementSelectRef - Ref to a callback for element selection.
 */
const useCytoscapeSetup = (cyRef, elements, layoutType, onElementSelectRef) => {
  useEffect(() => {
    const cy = cyRef.current;
    if (!cy) return;

    // 1) Sanitize elements.
    const sanitized = elements.map((el) => sanitizeElement(el));

    // 2) Remove any existing elements.
    cy.elements().remove();

    // 3) Add nodes first, then edges.
    const nodes = sanitized.filter((el) => !el.data.source);
    const edges = sanitized.filter((el) => el.data.source);
    cy.add(nodes);
    cy.add(edges);

    console.log('useCytoscapeSetup > sanitized nodes:', nodes);
    console.log('useCytoscapeSetup > sanitized edges:', edges);

    // 4) Set layout options.
    const finalLayoutOptions =
      layoutType === 'fcose'
        ? {
            name: 'fcose',
            idealEdgeLength: 200,
            nodeSeparation: 150,
            animate: false,
            fit: true,
            padding: 500, // Increased padding for massive zoom-out.
          }
        : { name: layoutType };

    console.log('useCytoscapeSetup > finalLayoutOptions:', finalLayoutOptions);

    // 5) Run the layout.
    const layoutInstance = cy.layout(finalLayoutOptions);
    layoutInstance.run();

    // 6) When layout stops, override positions with cached ones if available,
    //    then fit the graph.
    layoutInstance.on('layoutstop', () => {
      const cacheKey = 'nodePositions'; // Modify this key if you need per-user caching.
      const cachedPositions = JSON.parse(localStorage.getItem(cacheKey) || '{}');
      cy.nodes().forEach((node) => {
        if (cachedPositions[node.id()]) {
          node.position(cachedPositions[node.id()]);
        }
      });
      cy.fit(null, layoutType === 'fcose' ? 500 : 50);
      cy.nodes().forEach((node) => {
        console.log(`Node ${node.id()} final position:`, node.position());
      });
    });

    // 7) Set up tap event handlers.
    cy.off('tap');
    cy.on('tap', 'node, edge', (e) => {
      if (onElementSelectRef.current) {
        onElementSelectRef.current(e.target.data());
      }
    });
    cy.on('tap', (e) => {
      if (e.target === cy && onElementSelectRef.current) {
        onElementSelectRef.current(null);
      }
    });

    // 8) Listen for node drag events to update cached positions.
    cy.on('dragend', 'node', (e) => {
      const node = e.target;
      const pos = node.position();
      const cacheKey = 'nodePositions';
      const stored = JSON.parse(localStorage.getItem(cacheKey) || '{}');
      stored[node.id()] = pos;
      localStorage.setItem(cacheKey, JSON.stringify(stored));
    });
  }, [cyRef, elements, layoutType, onElementSelectRef]);

  return null;
};

export default useCytoscapeSetup;