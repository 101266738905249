// src/pages/graph_gen/DebugPage.jsx
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { getDomains, getExtractionStatus, getGraph } from '../../services/api/apiHandlers';
import { makeSafeData } from '../../core/helpers/graph_gen/common/makeSafeData';
import { transformModeData } from '../../core/helpers/graph_gen/others/transformModeData';
import GraphOverlayContainer from '../../core/ui/graph_gen/common/GraphOverlayContainer';
import ElementInfoOverlay from '../../core/ui/graph_gen/common/element_info_overlay/ElementInfoOverlay';
import useSliderPosition from '../../core/helpers/graph_gen/common/useSliderPosition';

const DebugPage = () => {
  const [allGenerations, setAllGenerations] = useState([]);
  const [selectedGenerationId, setSelectedGenerationId] = useState('');

  const [rawGraphData, setRawGraphData] = useState(null);
  const [mode, setMode] = useState('Knowledge');
  const [colorMode, setColorMode] = useState('colorless');

  const [selectedElementData, setSelectedElementData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [stateIndex, setStateIndex] = useState(0);
  const [sliderMessage, setSliderMessage] = useState('');
  const stateLabels = Array(16).fill('');
  const graphContainerRef = useRef(null);
  const sourceContainerRef = useRef(null);
  const sliderStyle = useSliderPosition(graphContainerRef, 256);
  const toggleSearchInput = () => setIsSearchVisible((prev) => !prev);
  const handleElementSelect = (data) => setSelectedElementData(data);
  useEffect(() => {
    async function fetchAllDomainsAndGenerations() {
      try {
        const domainRes = await getDomains();
        const fetchedDomains = domainRes.domains || [];

        const allGens = [];
        for (const domain of fetchedDomains) {
          const domainId = domain.domain_id;
          try {
            const statusRes = await getExtractionStatus(domainId);
            const generationArr = domainId === 'mock'
              ? statusRes
              : statusRes.generations;

            if (Array.isArray(generationArr)) {
              generationArr.forEach((gen) => {
                allGens.push({
                  // unify naming:
                  generation_id: gen.generation_id || gen.id,
                  domainId,
                  domainName: domain.name,
                  status: gen.status,
                });
              });
            }
          } catch (e) {
            console.error(
              `Error fetching generations for domain ${domain.domain_id}:`,
              e
            );
          }
        }
        setAllGenerations(allGens);

        if (allGens.length > 0) {
          setSelectedGenerationId(allGens[0].generation_id);
        }
      } catch (err) {
        console.error('Error fetching domains or generations:', err);
      }
    }
    fetchAllDomainsAndGenerations();
  }, []);

  useEffect(() => {
    if (!selectedGenerationId) {
      setRawGraphData(null);
      return;
    }

    getGraph(selectedGenerationId)
      .then((res) => {
        setRawGraphData(res.data || res.graphData || res);
      })
      .catch((err) => {
        console.error('Error fetching graph data:', err);
        setRawGraphData(null);
      });
  }, [selectedGenerationId]);

  const safeData = useMemo(() => {
    if (!rawGraphData) return null;
    return makeSafeData(rawGraphData);
  }, [rawGraphData]);

  const transformedData = useMemo(() => {
    if (!safeData) return null;
    return transformModeData(safeData, mode, colorMode);
  }, [safeData, mode, colorMode]);

  const overlayProps = {
    mode,
    sliderStyle,
    stateIndex,
    stateLabels,
    sliderMessage,
    onSliderChange: (e) => {
      const val = +e.target.value;
      setSliderMessage(val >= stateLabels.length ? 'Locked' : '');
      if (val < stateLabels.length) setStateIndex(val);
    },
    showSearchInput: isSearchVisible,
    toggleSearchInput,
    searchTerm,
    setSearchTerm,
    sourceContainerRef,
    sourceText: '',
    finalHighlightedHTML: '',
    selectedElementData,
    traceRefs: selectedElementData?.references || [],
    handleTrace: () => console.log('Trace pressed'),
    colorMode,
    graphNodes: transformedData?.Entities?.Entities || [],
    relationships:
      mode === 'Knowledge'
        ? transformedData?.Relationships?.AttributeRelationships || []
        : transformedData?.Relationships?.TypeRelationships || [],
  };

  return (
    <div className="min-h-screen p-4 bg-gray-50 relative">
      <h1 className="text-2xl font-bold mb-4">Debug Page (Curate-Style)</h1>

      <div className="mb-4">
        <label htmlFor="graphDropdown" className="mr-2 font-semibold">
          Select Generation:
        </label>
        <select
          id="graphDropdown"
          value={selectedGenerationId}
          onChange={(e) => setSelectedGenerationId(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="">-- No Selection --</option>
          {allGenerations.map((g) => (
            <option key={g.generation_id} value={g.generation_id}>
              {g.domainName
                ? `${g.domainName} - ${g.generation_id}`
                : `Gen ${g.generation_id}`}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="mr-2 font-semibold">Mode:</label>
        <select
          value={mode}
          onChange={(e) => setMode(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="Knowledge">Knowledge</option>
          <option value="Ontology">Ontology</option>
          <option value="Extensive">Extensive</option>
          <option value="Attribute">Attribute</option>
          <option value="Type">Type</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="mr-2 font-semibold">Color Mode:</label>
        <select
          value={colorMode}
          onChange={(e) => setColorMode(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="colorless">Colorless</option>
          <option value="colorful">Colorful</option>
        </select>
      </div>

      <div
        className="relative"
        ref={graphContainerRef}
        style={{ height: '70vh', border: '1px solid #ccc' }}
      >
        {transformedData ? (
          <GraphOverlayContainer
            ref={graphContainerRef}
            graphData={transformedData}
            graphHeight={600}
            onElementSelect={handleElementSelect}
            overlayProps={overlayProps}
          />
        ) : (
          <p className="p-4 text-gray-500">
            Select a generation to visualize...
          </p>
        )}
      </div>

      {selectedElementData && (
        <div className="absolute top-20 right-4 w-64 z-50">
          <ElementInfoOverlay
            {...overlayProps}
            selectedElementData={selectedElementData}
          />
        </div>
      )}
    </div>
  );
};

export default DebugPage;