import React from "react";
import { Trash, RefreshCw, Search } from "lucide-react";

export function ExtractionJobsTable({ 
  extractionJobs, 
  jobsLoading, 
  jobsError, 
  onCurate, 
  onDeleteExtraction,
  onRefresh,
  hideExtractionId,
}) {
  if (jobsLoading) return <p>Loading extraction jobs...</p>;
  if (jobsError) return <p className="text-red-500">{jobsError}</p>;

  return (
    <div className="overflow-x-auto">
      {/* Center the table within a max-width container */}
      <div className="max-w-5xl mx-auto">
        <table className="min-w-full table-fixed divide-y divide-gray-200">
          <thead>
            <tr>
              {!hideExtractionId && (
                <th className="px-4 py-2 text-left">Extraction ID</th>
              )}
              <th className="px-10 py-2 text-left" style={{ width: "250px" }}>
                PDF Name
              </th>
              <th className="px-4 py-2 text-left">Species Name</th>
              <th className="px-4 py-2 text-left">Status</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {extractionJobs.map((job) => (
              <tr key={job.extraction_id}>
                {!hideExtractionId && (
                  <td className="px-4 py-2 text-sm text-gray-900">
                    {job.extraction_id}
                  </td>
                )}
                <td className="px-4 py-2 text-sm text-gray-900 relative">
                  {/* This container centers its content and moves it slightly to the right */}
                  <div className="flex items-center justify-center ml-4">
                    {/* Delete button is absolutely positioned on the left */}
                    <button
                      onClick={() => onDeleteExtraction(job.extraction_id)}
                      className="absolute left-0 ml-2 text-success hover:text-successDark transition"
                      title="Delete Extraction"
                    >
                      <Trash className="h-4 w-4" />
                    </button>
                    {/* PDF name text container with additional margin for spacing */}
                    <span
                      className="block truncate text-center ml-10"
                      style={{ maxWidth: "200px" }}
                      title={job.pdf_key.split("/").pop()}
                    >
                      {job.pdf_key.split("/").pop()}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-2 text-sm text-gray-900">
                  {job.species_name}
                </td>
                <td className="px-4 py-2 text-sm text-gray-900">
                  {job.status === "finished" ? (
                    <button
                      onClick={() => onCurate(job.extraction_id)}
                      className="text-success hover:text-successDark transition"
                      title="Curate Extraction"
                    >
                      <Search className="h-4 w-4" />
                    </button>
                  ) : (
                    job.status
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={onRefresh}
          className="p-2 mt-4"
          title="Refresh Jobs"
        >
          <RefreshCw className="h-4 w-4 text-success hover:text-successDark" />
        </button>
      </div>
    </div>
  );
}