import React from "react";
import IndicatorsTable from "./IndicatorsTable.tsx";


export default function IndicatorsSection({
  sectionIndex,
  label,
  fields,
  indicatorValue,
  pdfExtractions,
  fieldLabels,
  formatValue,
  onDeleteExtraction,
}) {
  if (!fields || fields.length === 0) {
    return indicatorValue !== null ? (
      <div className="p-1 sm:p-2 border rounded-lg space-y-2 bg-white">
        <div className="text-center text-xs font-semibold whitespace-nowrap">
          {label}: <span className="text-green-700">{indicatorValue.toFixed(2)}</span>
        </div>
      </div>
    ) : null;
  }

  return (
    <div className="p-1 sm:p-2 border rounded-lg space-y-2 bg-white">
      <IndicatorsTable
        sectionIndex={sectionIndex}
        fields={fields}
        pdfExtractions={pdfExtractions}
        fieldLabels={fieldLabels}
        formatValue={formatValue}
        onDeleteExtraction={onDeleteExtraction}
      />

      {indicatorValue !== null && (
        <div className="text-center text-xs font-semibold whitespace-nowrap bg-white">
          {label}:{" "}
          <span className="text-green-700">{indicatorValue.toFixed(2)}</span>
        </div>
      )}
    </div>
  );
}