// src/organizations/biodiversity/pages/HomeSpeciesPage.tsx
import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { fetchAllSpecies } from "../services/api.ts";
import { SpeciesHeaderSection } from "../core/home/vis_species/SpeciesHeaderSection.tsx";
import { SpeciesCardsSection } from "../core/home/vis_species/SpeciesCardsSection.tsx";
import { Button } from "../core/common/button.tsx";
import { handleDeleteSpecies } from "../core/home/vis_species/speciesHandlers.ts";

const HomeSpeciesPage = () => {
  const [showNewForm, setShowNewForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showCards, setShowCards] = useState(true);

  const queryClient = useQueryClient();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["species"],
    queryFn: fetchAllSpecies,
    refetchOnWindowFocus: true,
  });

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Loading species...</p>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-red-500">Error: {error.message}</p>
      </div>
    );
  }

  const fetchedSpecies =
    data?.species_data?.map((sp: any) => ({
      id: sp.id,
      name: sp.name,
      latinName: sp.latin_name,
      image: sp.imagelink,
    })) || [];

  const filteredSpecies = fetchedSpecies.filter((s: any) =>
    s.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    s.latinName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-paper p-6 space-y-8">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-2">Unlocking Biodata</h1>
        <p className="text-xl text-gray-600">
          Upload, review, and retrieve your metrics.
        </p>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
        <SpeciesHeaderSection
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
          onToggleNewSpecies={() => setShowNewForm(!showNewForm)}
          showNewForm={showNewForm}
        />
      </div>
      <SpeciesCardsSection
        species={filteredSpecies}
        onDeleteSpecies={(id: number) => handleDeleteSpecies(id, queryClient)}
        showCards={showCards}
        onToggleShowCards={() => setShowCards(!showCards)}
      />
    </div>
  );
};

export default HomeSpeciesPage;