import React from "react";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";

import SpeciesDetailHeader from "./SpeciesDetailHeader.tsx";
import SpeciesIndicators from "./SpeciesIndicators.tsx";
import SpeciesSummaryCard from "./SpeciesSummaryCard.tsx";
import ExportExcelButton from "../common/ExportExcelButton.tsx";
import { deletePdfExtraction } from "../../services/api.ts";

function average(values: (number | null)[]): number | null {
  const valid = values.filter((v): v is number => v !== null);
  if (valid.length === 0) return null;
  return valid.reduce((sum, v) => sum + v, 0) / valid.length;
}

const parseBool = (val: any): boolean | null => {
  if (val === null || val === undefined) return null;
  if (typeof val === "boolean") return val;
  if (typeof val === "string") {
    const lowered = val.trim().toLowerCase();
    if (lowered === "yes" || lowered === "true" || lowered === "1") return true;
    return false;
  }
  if (typeof val === "number") return val === 1;
  return false;
};

const majorityVote = (votes: (boolean | null)[]): boolean => {
  const validVotes = votes.filter((v): v is boolean => v !== null);
  return validVotes.some((v) => v === true);
};

export default function SpeciesDetailMain({
  species,
  extractionData,
}: {
  species: any;
  extractionData: any;
}) {
  const queryClient = useQueryClient();
  const rows = extractionData?.extractions ?? [];
  const stripPath = (path: string): string => {
    if (!path) return "";
    const parts = path.split(/[\\/]/);
    return parts[parts.length - 1];
  };

  const pdfExtractions = rows.map((row: any) => ({
    id: row.id,
    pdfName: stripPath(row.pdf_key),
    fields: {
      Extant_Populations: row.Extant_Populations ?? null,
      Extinct_Populations: row.Extinct_Populations ?? null,
      Population_Sizes_Lower_Bound: row.Population_Sizes_Lower_Bound ?? null,
      Population_Sizes_Upper_Bound: row.Population_Sizes_Upper_Bound ?? null,
      Population_Less_Than_5000_Individuals: row.Population_Less_Than_5000_Individuals ?? null,
      Population_More_Than_5000_Individuals: row.Population_More_Than_5000_Individuals ?? null,
      Phylogenetic_Geographic_Studies: row.Phylogenetic_Geographic_Studies ?? null,
      Population_Genetic_Studies: row.Population_Genetic_Studies ?? null,
      DNA_based_Temporal_Monitoring: row.DNA_based_Temporal_Monitoring ?? null,
      Generation_Time: row.Generation_Time ?? null,
      Dispersal_Distance: row.Dispersal_Distance ?? null,
      Count_Each_Extant_Population: row.Count_Each_Extant_Population ?? null,
    },
  }));

  const avgExtant = average(pdfExtractions.map((pdf) => pdf.fields.Extant_Populations));
  const avgExtinct = average(pdfExtractions.map((pdf) => pdf.fields.Extinct_Populations));
  const avgPopMoreThan5000 = average(
    pdfExtractions.map((pdf) => pdf.fields.Population_More_Than_5000_Individuals)
  );
  const computedIndicator1 =
    avgPopMoreThan5000 == null || avgPopMoreThan5000 === 0 || avgExtant == null || avgExtant === 0
      ? 0
      : avgPopMoreThan5000 / avgExtant;

  const computedIndicator2 =
    avgExtant != null &&
    avgExtinct != null &&
    avgExtant + avgExtinct !== 0
      ? avgExtant / (avgExtant + avgExtinct)
      : null;
  const phyloVotes = pdfExtractions.map((pdf) =>
    parseBool(pdf.fields.Phylogenetic_Geographic_Studies)
  );
  const popGenVotes = pdfExtractions.map((pdf) =>
    parseBool(pdf.fields.Population_Genetic_Studies)
  );
  const dnaVotes = pdfExtractions.map((pdf) =>
    parseBool(pdf.fields.DNA_based_Temporal_Monitoring)
  );
  const majorityPhylo = majorityVote(phyloVotes);
  const majorityPopGen = majorityVote(popGenVotes);
  const majorityDNA = majorityVote(dnaVotes);
  const computedIndicator3 = majorityDNA
    ? 1
    : majorityPhylo && majorityPopGen
    ? 0.5
    : majorityPhylo || majorityPopGen
    ? 0.25
    : 0;

  const groupedFields = [
    { fields: [], indicator: "Ne 500" },
    { fields: ["Extant_Populations", "Extinct_Populations"], indicator: "PM" },
    {
      fields: [
        "Population_Less_Than_5000_Individuals",
        "Population_More_Than_5000_Individuals",
        "Phylogenetic_Geographic_Studies",
        "Population_Genetic_Studies",
        "DNA_based_Temporal_Monitoring",
      ],
      indicator: "DNA Monitoring",
    },
    {
      fields: [
        "Generation_Time",
        "Dispersal_Distance",
        "Population_Sizes_Lower_Bound",
        "Population_Sizes_Upper_Bound",
      ],
      indicator: "Övrigt",
    },
  ];

  const fieldLabels = {
    Extant_Populations: "Extant populations",
    Extinct_Populations: "Extinct populations",
    Population_Sizes_Lower_Bound: "Population Sizes (Lower Bound)",
    Population_Sizes_Upper_Bound: "Population Sizes (Upper Bound)",
    Population_Less_Than_5000_Individuals: "Populations - Less Than 5000 Individuals",
    Population_More_Than_5000_Individuals: "Populations - More than 5000 Individuals",
    Phylogenetic_Geographic_Studies: "Phylogenetic/Geographic Studies",
    Population_Genetic_Studies: "Population Genetic Studies",
    DNA_based_Temporal_Monitoring: "DNA-based Temporal Monitoring",
    Generation_Time: "Generation Time",
    Dispersal_Distance: "Dispersal Distance",
    Count_Each_Extant_Population: "Number of individuals in each population",
  };

  const handleDeleteExtraction = async (extractionId: string) => {
    if (!window.confirm("Are you sure you want to delete this PDF extraction?")) return;
    try {
      await deletePdfExtraction(extractionId);
      toast.success("PDF extraction deleted successfully!");
      queryClient.invalidateQueries(["pdfExtractions", species.id]);
    } catch (err: any) {
      toast.error("Failed to delete extraction: " + err.message);
    }
  };

  return (
    <div className="p-4 sm:p-6 space-y-6 bg-paper">
      <SpeciesDetailHeader species={species} />
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 sm:gap-4">
        <SpeciesIndicators
          pdfExtractions={pdfExtractions}
          fieldLabels={fieldLabels}
          computedIndicator1={computedIndicator1}
          computedIndicator2={computedIndicator2}
          computedIndicator3={computedIndicator3}
          parseBool={parseBool}
          onDeleteExtraction={handleDeleteExtraction}
        />
        <SpeciesSummaryCard
          species={species}
          groupedFields={groupedFields.filter((group) => group.indicator !== "Övrigt")}
          computedIndicator1={computedIndicator1}
          computedIndicator2={computedIndicator2}
          computedIndicator3={computedIndicator3}
          exportExcelButton={
            <ExportExcelButton pdfExtractions={pdfExtractions} fieldLabels={fieldLabels} />
          }
        />
      </div>
    </div>
  );
}