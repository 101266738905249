// src/core/ui/graph_gen/common/GraphArea.jsx
import React from 'react';
import GraphOverlayContainer from './GraphOverlayContainer';

const GraphArea = ({
  transformedData,
  graphHeight,
  handleElementSelect,
  layoutType,
  colorMode,          // <-- new prop here
  overlayProps,
  graphContainerRef,
  styleSheet
}) => (
  <GraphOverlayContainer
    ref={graphContainerRef}
    graphData={transformedData}
    graphHeight={graphHeight}
    onElementSelect={handleElementSelect}
    layoutType={layoutType}
    colorMode={colorMode}  // <-- pass colorMode down
    styleSheet={styleSheet}
    {...overlayProps}
  />
);

export default GraphArea;