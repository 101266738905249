// src/utils/graph_gen/ontology/graphHandlers.js

// Helper function to collect all existing IDs from the ontology object
const getAllIds = (data) => {
  const idArrays = [
    data.Entities.Entities,
    data.Entities.PrimitiveDataTypeEntities,
    data.Relationships.TypeRelationships,
    data.Relationships.AttributeRelationships,
    data.Relationships.EquivalenceRelationships,
    data.Decompositions.TypeDecompositions,
    data.Decompositions.AttributeFunctionalDecompositions,
    data.Decompositions.AttributeAggregationDecompositions,
    data.RelationshipEntities.AttributeRelationshipEntities,
    data.RelationshipEntities.StructuralRelationshipEntities,
  ];
  let allIds = [];
  idArrays.forEach(arr => {
    if (Array.isArray(arr)) {
      arr.forEach(item => {
        if (typeof item.ID === 'number') {
          allIds.push(item.ID);
        }
      });
    }
  });
  return allIds;
};

// Default ontology object with a globalIdCounter property.
// This counter is initialized to 2 so that new items start with ID 2.
const defaultOntology = {
  globalIdCounter: 2,
  Entities: {
    Entities: [],
    PrimitiveDataTypeEntities: [],
  },
  Relationships: {
    TypeRelationships: [],
    AttributeRelationships: [],
    EquivalenceRelationships: [],
  },
  Decompositions: {
    TypeDecompositions: [],
    AttributeFunctionalDecompositions: [],
    AttributeAggregationDecompositions: [],
  },
  RelationshipEntities: {
    AttributeRelationshipEntities: [],
    StructuralRelationshipEntities: [],
  },
};

export const addNodeHandler = (nodeData, updateGraphData) => {
  updateGraphData((prevData) => {
    if (!prevData) {
      prevData = { ...defaultOntology };
    }
    if (!prevData.globalIdCounter) {
      const allIds = getAllIds(prevData);
      prevData.globalIdCounter = allIds.length > 0 ? Math.max(...allIds) + 1 : 2;
    }
    const newID = prevData.globalIdCounter;
    prevData.globalIdCounter++;
    const newEntity = {
      ID: newID,
      Name: nodeData.Name,
      Definition: nodeData.Definition,
      references: [],
      baseColor: nodeData.baseColor,
    };
    return {
      ...prevData,
      Entities: {
        ...prevData.Entities,
        Entities: [...prevData.Entities.Entities, newEntity],
      },
    };
  });
};

export const addRelationshipEntityHandler = (entity, updateGraphData) => {
  updateGraphData((prevData) => {
    if (!prevData) {
      prevData = { ...defaultOntology };
    }
    if (!prevData.globalIdCounter) {
      const allIds = getAllIds(prevData);
      prevData.globalIdCounter = allIds.length > 0 ? Math.max(...allIds) + 1 : 2;
    }
    const newID = prevData.globalIdCounter;
    prevData.globalIdCounter++;
    if (entity.type === 'attribute') {
      const newRelEntity = {
        ID: newID,
        Name: entity.Name,
        Definition: entity.Definition,
        references: [],
        SourceNodeTypeID: entity.SourceNodeTypeID,
        TargetNodeTypeID: entity.TargetNodeTypeID,
      };
      return {
        ...prevData,
        RelationshipEntities: {
          ...prevData.RelationshipEntities,
          AttributeRelationshipEntities: [
            ...prevData.RelationshipEntities.AttributeRelationshipEntities,
            newRelEntity,
          ],
        },
      };
    } else {
      const newRelEntity = {
        ID: newID,
        Name: entity.Name,
        Definition: entity.Definition,
        references: [],
        Transitive: entity.Transitive,
        Bidirectional: entity.Bidirectional,
      };
      return {
        ...prevData,
        RelationshipEntities: {
          ...prevData.RelationshipEntities,
          StructuralRelationshipEntities: [
            ...prevData.RelationshipEntities.StructuralRelationshipEntities,
            newRelEntity,
          ],
        },
      };
    }
  });
};

