// src/utils/prepareGraphElements.js
import { resolveColor } from './cytoscapeHelpers';

export const prepareGraphElements = (data) => {
  const clusters = data.clusters || [];
  const nodesData = data.nodes || [];
  const linksData = data.links || [];

  const clusterElements = clusters.map(cluster => ({
    data: {
      id: cluster.id,
      label: cluster.label,
      color: resolveColor(cluster.color),
      shape: cluster.shape,
    },
    classes: 'cluster',
    group: 'nodes',
  }));

  const nodeElements = nodesData.map(node => {
    const nodeData = {
      id: node.id,
      label: node.label,
      color: resolveColor(node.color),
      shape: 'ellipse',
    };
    if (node.parent) nodeData.parent = node.parent;
    if (node.info) nodeData.info = node.info;
    if (node.references) nodeData.references = node.references;
    if (node.Type) nodeData.Type = node.Type;
    return { data: nodeData, group: 'nodes' };
  });

  const edgeElements = linksData.map(link => {
    const edgeData = {
      id: link.id || `${link.source}-${link.target}-${Math.random()}`,
      source: link.source,
      target: link.target,
      color: 'black',
      width: link.width || 2,
      style: link.style || 'solid',
      arrowShape: link.arrowShape || 'triangle',
      label: link.label,
      Type: link.Type
    };
    if (link.info) edgeData.info = link.info;
    if (link.references) edgeData.references = link.references;
    // Use a larger minLen for "type of" links.
    edgeData.minLen = (link.label && link.label.toLowerCase() === 'type of') ? 3 : 1;
    return { data: edgeData, group: 'edges' };
  });

  return [...clusterElements, ...nodeElements, ...edgeElements];
};