/* src/organizations/species/components/species/SpeciesCard.tsx */

import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent } from "../../common/card.tsx";
import { Trash2 } from "lucide-react";

interface SpeciesCardProps {
  species: {
    id: number;
    name: string;
    latinName: string;
    image?: string;
  };
  onDelete?: (speciesId: number) => void;
}

export const SpeciesCard = ({ species, onDelete }: SpeciesCardProps) => {
  return (
    <div className="relative">
      <Link to={`/species/${species.id}`}>
        <Card className="hover:shadow-lg transition-shadow cursor-pointer bg-white">
          <CardContent className="p-4 text-center ">
            {species.image && (
              <img
                src={species.image}
                alt={species.name}
                loading="lazy"
                className="w-full h-32 object-contain mb-2"
              />
            )}

            <h3 className="text-sm sm:text-base md:text-lg font-semibold mb-1 break-all">
              {species.name}
            </h3>
            <p className="text-gray-600 italic text-xs sm:text-sm">
              {species.latinName}
            </p>
          </CardContent>
        </Card>
      </Link>

      {onDelete && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (
              window.confirm(`Are you sure you want to delete ${species.name}?`)
            ) {
              onDelete(species.id);
            }
          }}
          className="absolute top-2 right-2 p-1 bg-white rounded-full shadow hover:bg-gray-100"
          title="Delete species"
        >
          <Trash2 className="h-4 w-4 text-success" />
        </button>
      )}
    </div>
  );
};