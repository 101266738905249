import { postRequest, getRequest } from './apiService';
import { auth } from '../firebase';
const API_BASE_URL = "https://atlaz-api.com";

export async function fetchGraph({
  text,
  customization,
  document_id,
  domain_id,
  skeleton = null,
  endpoint,
  ontology_id,
}) {
  const payload = { text, customization, document_id, domain_id };
  if (skeleton) payload.skeleton = skeleton;
  if (ontology_id) payload.ontology_id = ontology_id;

  return await postRequest(endpoint, payload);
}

export async function getExtractionStatusAll() {
    const response = await getRequest('/get-status');
    return await response.json();
}

export async function getExtractionStatus(domainId) {
  let endpoint;
  if (domainId && domainId !== "mock") {
    endpoint = `/domains/${domainId}/generations`;
  } else {
    endpoint = "/get-status";
  }
  const response = await getRequest(endpoint);
  return await response.json();
}

export async function storeValidatedGeneration({ generation_id, validated_data }) {
    const response = await postRequest("/store-validated-generation", { generation_id, validated_data });
    return await response.json();
}

export async function getGraph(graphId) {
    const response = await getRequest(`/get-graph/${graphId}`);
    return await response.json();
  }
  
export async function getDomains() {
    const response = await getRequest('/domains');
    return await response.json();
}

export async function createDocument({ document_id, name, text, domain_id }) {
    const payload = { document_id, name, text, domain_id };
    const response = await postRequest("/documents", payload);
    return await response.json();
}

export async function createDomain({ domain_id, name }) {
    const payload = { domain_id, name };
    const response = await postRequest("/domains", payload);
    return await response.json();
  }

export async function getDocumentsForDomain(domainId) {
    try {
      const response = await getRequest(`/domains/${domainId}/documents`);
      return await response.json();
    } catch (error) {
      if (error.message.includes("404")) {
        return { documents: [] };
      }
      throw error;
    }
  }

export async function getOntologies() {
  const response = await getRequest('/ontologies');
  return await response.json();
}

export async function deleteGeneration(generationId) {
  const token = await auth.currentUser.getIdToken(true);
  const response = await fetch(`${API_BASE_URL}/delete-generation/${generationId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }
  return await response.json();
}

export async function getValidatedGenerations(ontology_id) {
  const response = await getRequest(`/validated-generations/${ontology_id}`);
  return await response.json();
}

export async function createOntology(ontology) {
  const response = await postRequest('/ontologies', ontology);
  return await response.json();
}


export async function createGraph({ graph_name, graph_data }) {
  const response = await postRequest("/graphs", { graph_name, graph_data });
  return await response.json();
}

async function putRequest(endpoint, payload) {
  const token = await auth.currentUser.getIdToken(true);
  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }
  return response;
}

export async function updateGraph({ graph_id, graph_name, graph_data }) {
  const response = await putRequest(`/graphs/${graph_id}`, { graph_id, graph_name, graph_data });
  return await response.json();
}

export async function getUserGraphs() {
  const response = await getRequest('/graphs');
  return await response.json();
}