import React, { useState, useRef, useEffect } from 'react';
import { FaSitemap, FaUserSecret, FaSearch } from 'react-icons/fa';
import { FiUpload } from 'react-icons/fi';
import { RiFileExcel2Line } from 'react-icons/ri';
import WebsiteField from './WebsiteField';
import RawTextField from './RawTextField';

const InkHeader = ({
  initialDataset = '',
  initialGoal = '',
  onDatasetChange,
  onGoalChange,
  availableOntologies = [],
  selectedOntology,
  setSelectedOntology,
  onExport, 
  className = ''
}) => {
  const [dataset, setDataset] = useState(initialDataset);
  const [goal, setGoal] = useState(initialGoal);

  const datasetRef = useRef(null);
  const goalRef = useRef(null);

  const headerStyle = {
    WebkitTextStroke: '0.6px rgba(0,0,0,0.5)',
    textShadow:
      '1px 1px 1px rgba(0,0,0,0.4), -1px -1px 1px rgba(255,255,255,0.3), 2px 2px 3px rgba(0,0,0,0.2)'
  };

  useEffect(() => {
    if (datasetRef.current) {
      datasetRef.current.innerText = initialDataset;
    }
  }, [initialDataset]);

  useEffect(() => {
    if (goalRef.current) {
      goalRef.current.innerText = initialGoal;
    }
  }, [initialGoal]);

  const handleDatasetBlur = () => {
    const newValue = datasetRef.current.innerText;
    setDataset(newValue);
    if (onDatasetChange) onDatasetChange(newValue);
  };

  const handleGoalBlur = () => {
    const newValue = goalRef.current.innerText;
    setGoal(newValue);
    if (onGoalChange) onGoalChange(newValue);
  };

  return (
    <div className={`w-full relative h-32 ${className}`}>
      <div className="absolute pl-4 -top-[4px] -left-[13px] z-20">
        <div>
          <span className="text-3xl text-black font-copperplate" style={headerStyle}>
            Dataset:
          </span>
          <span
            ref={datasetRef}
            contentEditable
            suppressContentEditableWarning
            className="text-3xl text-black font-copperplate ml-2 outline-none"
            style={{
              ...headerStyle,
              textAlign: 'left',
              direction: 'ltr',
              unicodeBidi: 'plaintext'
            }}
            onBlur={handleDatasetBlur}
          />
        </div>
        <div className="mt-2 flex items-center gap-2">
          <FaSitemap className="text-success" size={30} />
          <select
            id="ontologySelect"
            value={
              selectedOntology?.ontology_id ||
              (availableOntologies[0] && availableOntologies[0].ontology_id) ||
              ''
            }
            onChange={(e) => {
              const chosen = availableOntologies.find(
                (ont) => ont.ontology_id === e.target.value
              );
              setSelectedOntology(chosen);
            }}
            className="cool-glow"
            style={{ minWidth: '120px' }}
          >
            {availableOntologies.map((ont) => (
              <option key={ont.ontology_id} value={ont.ontology_id}>
                {ont.Name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div
        className="absolute"
        style={{ left: '33.33%', top: '25%', transform: 'translateY(-50%)' }}
      >
        <div>
          <span className="text-3xl text-black font-copperplate" style={headerStyle}>
            Extract
          </span>
          <span
            ref={goalRef}
            contentEditable
            suppressContentEditableWarning
            className="text-3xl text-black font-copperplate mt-1 outline-none"
            style={{
              ...headerStyle,
              textAlign: 'left',
              direction: 'ltr',
              unicodeBidi: 'plaintext'
            }}
            onBlur={handleGoalBlur}
          />
        </div>
        <div
          className="mt-2 flex items-center gap-4"
          style={{ left: "calc(45% - 600px)", transform: "translateX(-6.5%)" }}
        >
          <FaUserSecret size={28} className="text-success" title="Spy" />
          <WebsiteField />
          <RawTextField />
          <FiUpload size={28} className="text-success" title="Upload" />
        </div>
      </div>
      <div
        className="absolute"
        style={{ left: '55.00%', top: '25%', transform: 'translateY(-50%)' }}
      >
        <div>
          <span className="text-3xl text-black font-copperplate" style={headerStyle}>
            Review
          </span>
          <span
            contentEditable
            suppressContentEditableWarning
            className="text-3xl text-black font-copperplate mt-1 outline-none"
            style={{
              ...headerStyle,
              textAlign: 'left',
              direction: 'ltr',
              unicodeBidi: 'plaintext'
            }}
          />
        </div>
        <div
          className="mt-2 flex items-center gap-4"
          style={{ left: "calc(45% - 600px)", transform: "translateX(40%) translateY(-1px)" }}
        >
          <FaSearch size={28} className="text-success" title="Search" />
        </div>
      </div>

      <div
        className="absolute"
        style={{ left: '75.00%', top: '28%', transform: 'translateY(-50%)' }}
      >
        <div>
          <span className="text-3xl text-black font-copperplate" style={headerStyle}>
            Export
          </span>
          <span
            contentEditable
            suppressContentEditableWarning
            className="text-3xl text-black font-copperplate mt-1 outline-none"
            style={{
              ...headerStyle,
              textAlign: 'left',
              direction: 'ltr',
              unicodeBidi: 'plaintext'
            }}
          />
        </div>
        <div className="mt-2 flex items-center gap-4"
        style={{ left: "calc(45% - 600px)", transform: "translateX(38%) translateY(-6px)" }}>
          <button
            onClick={onExport}
            className="focus:outline-none text-success hover:text-successHover"
            title="Export Excel"
          >
            <RiFileExcel2Line size={34} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default InkHeader;