import React, { useState } from 'react';
import './GeometricSpreadsheet.css';

const Spreadsheet = ({ pageNumber }) => {
  // Render even pages with one column, odd pages with three columns.
  if (pageNumber % 2 === 0) {
    return (
      <table className="spreadsheet">
        <thead>
          <tr>
            <th colSpan="1">Page {pageNumber}</th>
          </tr>
          <tr>
            <th>A</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 4 }).map((_, row) => (
            <tr key={row}>
              <td contentEditable>{`R${row + 1}A`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    return (
      <table className="spreadsheet">
        <thead>
          <tr>
            <th colSpan="3">Page {pageNumber}</th>
          </tr>
          <tr>
            <th>A</th>
            <th>B</th>
            <th>C</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 4 }).map((_, row) => (
            <tr key={row}>
              <td contentEditable>{`R${row + 1}A`}</td>
              <td contentEditable>{`R${row + 1}B`}</td>
              <td contentEditable>{`R${row + 1}C`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
};

const GeometricSpreadsheet = () => {
  const numPages = 8;
  const pageWidth = 300;  // page width in pixels
  const pageHeight = 400; // page height in pixels
  
  // Full circle divided equally
  const deltaDeg = 360 / numPages; // 45° per page
  const deltaRad = (deltaDeg * Math.PI) / 180; // in radians
  
  // Calculate the radius so adjacent pages' edges touch.
  const R_center = (pageWidth / 2) / Math.tan(deltaRad / 2);
  
  // For symmetry, we offset by half the delta.
  const pages = Array.from({ length: numPages }, (_, i) => {
    const centerAngleDeg = i * deltaDeg - deltaDeg / 2;
    const centerAngleRad = (centerAngleDeg * Math.PI) / 180;
    const x = R_center * Math.sin(centerAngleRad);
    const z = R_center * Math.cos(centerAngleRad);
    return { x, z, rotateY: centerAngleDeg };
  });

  const [rotation, setRotation] = useState(0);

  return (
    <div className="scene">
      <div className="rotation-slider">
        <input
          type="range"
          min="0"
          max="360"
          value={rotation}
          onChange={(e) => setRotation(e.target.value)}
        />
        <span>{rotation}°</span>
      </div>

      <div className="structure" style={{ transform: `rotateY(${rotation}deg)` }}>
        {pages.map((page, index) => (
          <div
            key={index}
            className="page-face"
            style={{
              width: `${pageWidth}px`,
              height: `${pageHeight}px`,
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: `translate(-50%, -50%) translateX(${page.x}px) translateZ(${page.z}px) rotateY(${page.rotateY}deg)`,
              transformOrigin: '50% 50%',
            }}
          >
            <Spreadsheet pageNumber={index + 1} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GeometricSpreadsheet;