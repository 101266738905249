import React, { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Check, ChevronsUpDown, Loader2 } from "lucide-react"

import { cn } from "../../../../services/utils.ts"
import { Button } from "../../../common/button.tsx"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../common/command.tsx"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../common/popover.tsx"

interface SpeciesSelectProps {
  onSelect?: (speciesData: { id: string; name: string }) => void
}

export function SpeciesSelect({ onSelect }: SpeciesSelectProps) {
  const [open, setOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState("")

  const fetchSpecies = async () => {
    const res = await fetch("https://atlaz-api.com/species/get-species")
    if (!res.ok) {
      throw new Error("Error fetching species.")
    }
    return res.json()
  }

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["allSpeciesForSelect"],
    queryFn: fetchSpecies,
  })

  if (isLoading) {
    return (
      <div className="py-2 px-3 flex items-center space-x-2 text-gray-600">
        <Loader2 className="h-4 w-4 animate-spin" />
        <span>Loading species list...</span>
      </div>
    )
  }

  if (isError) {
    return (
      <p className="text-red-500">
        Error: {(error as Error).message}
      </p>
    )
  }

  const fetchedSpecies = data?.species_data ?? []

  const speciesOptions = fetchedSpecies.map((sp: any) => ({
    value: String(sp.id),
    label: sp.name || "Unnamed Species",
  }))
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[300px] justify-between bg-white"
        >
          {selectedValue
            ? speciesOptions.find((opt) => opt.value === selectedValue)?.label
            : "Select species..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-[300px] p-0 bg-white shadow-md" align="start">
        <Command>
          <CommandInput placeholder="Search species..." />
          <CommandList>
            <CommandEmpty>No species found.</CommandEmpty>
            <CommandGroup>
              {speciesOptions.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.label}
                  onSelect={() => {
                    const isCurrentlySelected = item.value === selectedValue
                    const newValue = isCurrentlySelected ? "" : item.value
                    setSelectedValue(newValue)

                    if (onSelect) {
                      if (!newValue) {
                        onSelect({ id: "", name: "" })
                      } else {
                        onSelect({ id: item.value, name: item.label })
                      }
                    }

                    setOpen(false)
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selectedValue === item.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}