// src/components/common/overlays/NodeDetails.jsx
import React from 'react';
import PropTypes from 'prop-types';

const NodeDetails = ({ selectedElementData, graphNodes, relationships }) => {
  const getNumericID = (id) => {
    return parseInt(id, 10);
  };

  const getEntityName = (id) => {
    if (id == null) return 'Unknown';
    const numericID = getNumericID(id);
    const found = graphNodes.find((ent) => getNumericID(ent.ID) === numericID);
    return found ? found.Name : String(id);
  };

  const entityID = selectedElementData.ID || selectedElementData.id;
  const computedName = selectedElementData.label;
  const numericEntityID = getNumericID(entityID);

  const directTypes = relationships
    ? relationships.filter((rel) => getNumericID(rel.SourceNodeID) === numericEntityID)
    : [];
  const typeNames = directTypes.map((rel) => getEntityName(rel.TargetNodeID));
  const computedType = typeNames.length > 0 ? typeNames.join(' & ') : 'Top Level';

  return (
    <div>
      <p>
        <strong>Name:</strong> {computedName}
      </p>
      <p>
        <strong>Type:</strong> {computedType}
      </p>
      <p>
        <strong>Info:</strong> {selectedElementData.info}
      </p>
    </div>
  );
};

NodeDetails.propTypes = {
  selectedElementData: PropTypes.object.isRequired,
  graphNodes: PropTypes.array.isRequired,
  relationships: PropTypes.array,
};

export default NodeDetails;