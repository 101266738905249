import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';

const SearchToggleInput = ({ showSearchInput, toggleSearchInput, searchTerm, setSearchTerm }) => {
  return (
    <div className="relative mb-2 flex justify-end">
      {showSearchInput ? (
        <input
          type="text"
          className="w-full bg-white rounded p-2 border border-white focus:outline-none focus:border-success focus:ring-0"
          placeholder="Type a term to find..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      ) : (
        <button
          onClick={toggleSearchInput}
          className="flex items-center justify-center w-8 h-8 bg-white rounded-full shadow"
          title="Search in text"
        >
          <FaSearch className="text-success" size={16} />
        </button>
      )}
    </div>
  );
};

SearchToggleInput.propTypes = {
  showSearchInput: PropTypes.bool.isRequired,
  toggleSearchInput: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};

export default SearchToggleInput;