// src/features/species/components/upload/ReviewDetailsPanel.tsx

import React from "react";
import { TableRowData } from "./ReviewTable.tsx";

interface ReviewDetailsPanelProps {
  row: TableRowData;
  nameMapping: Record<string, string>;
}

export const ReviewDetailsPanel: React.FC<ReviewDetailsPanelProps> = ({
  row,
  nameMapping,
}) => {
  return (
    <div className="mt-4 p-4 border rounded bg-gray-100">
      <h4 className="text-md font-semibold mb-2">
        Motivation for "{nameMapping[row.Name] || row.Name}"
      </h4>
      <p className="text-gray-700 mb-4">
        {row.Motivation || "(No motivation text)"}
      </p>
    </div>
  );
};