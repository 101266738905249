
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DomainCard from '../../core/ui/graph_gen/domain/DomainCard.jsx';
import DomainCreationForm from '../../core/ui/graph_gen/domain/DomainCreationForm.jsx';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { getDomains, createDocument, createDomain, getDocumentsForDomain } from '../../services/api/apiHandlers.js';

const DomainsPage = () => {
  const navigate = useNavigate();

  // Define your default mock domain.
  const defaultMockDomain = {
    domain_id: 'mock',
    name: 'Mock Domain',
    documents: [
      {
        document_id: 'mock',
        name: 'Mock Document',
        text: '',
      },
    ],
  };

  // Initialize state with the mock domain.
  const [domains, setDomains] = useState([defaultMockDomain]);
  const [isLoadingDomains, setIsLoadingDomains] = useState(true);
  const [newDomainName, setNewDomainName] = useState('');
  const [isCreatingDomain, setIsCreatingDomain] = useState(false);
  // Maintain form state for each domain (keyed by domain_id).
  const [formData, setFormData] = useState({});
  // Documents fetched for each domain.
  const [documentsByDomain, setDocumentsByDomain] = useState({});

  // Fetch domains using the getDomains handler.
  const fetchDomains = async () => {
    try {
      const data = await getDomains();
      const fetchedDomains = data.domains;
      const mockExists = fetchedDomains.some(domain => domain.domain_id === defaultMockDomain.domain_id);
      setDomains(mockExists ? fetchedDomains : [defaultMockDomain, ...fetchedDomains]);
      // Initialize form state for each domain.
      const initialFormData = {};
      fetchedDomains.forEach(domain => {
        initialFormData[domain.domain_id] = { showForm: false, documentName: "", documentText: "" };
        // Also fetch documents.
        fetchDocumentsForDomain(domain.domain_id);
      });
      // Also initialize for the default mock domain if not included.
      if (!mockExists) {
        initialFormData[defaultMockDomain.domain_id] = { showForm: false, documentName: "", documentText: "" };
        fetchDocumentsForDomain(defaultMockDomain.domain_id);
      }
      setFormData(initialFormData);
    } catch (error) {
      console.error("Failed to fetch domains:", error);
      toast.error("Failed to load domains");
    } finally {
      setIsLoadingDomains(false);
    }
  };

  // For the mock domain, bypass the API call and use default documents.
  const fetchDocumentsForDomain = async (domainId) => {
    if (domainId === 'mock') {
      setDocumentsByDomain(prev => ({ ...prev, [domainId]: defaultMockDomain.documents }));
      return;
    }
    try {
      const data = await getDocumentsForDomain(domainId);
      setDocumentsByDomain(prev => ({ ...prev, [domainId]: data.documents }));
    } catch (error) {
      console.error(`Failed to fetch documents for domain ${domainId}:`, error);
      toast.error("Failed to load documents for a domain");
    }
  };

  useEffect(() => {
    fetchDomains();
  }, []);

  // Create a new domain using the createDomain handler.
  const handleDomainSubmit = async (e) => {
    e.preventDefault();
    if (!newDomainName.trim()) {
      toast.error("Please enter a domain name");
      return;
    }
    setIsCreatingDomain(true);
    const domainId = uuidv4();
    try {
      await createDomain({
        domain_id: domainId,
        name: newDomainName
      });
      toast.success("Domain created successfully!");
      setNewDomainName("");
      fetchDomains();
    } catch (error) {
      console.error("Error creating domain:", error);
      toast.error("Error creating domain");
    } finally {
      setIsCreatingDomain(false);
    }
  };

  // Toggle the visibility of the document form for a given domain.
  const toggleFormVisibility = (domainId) => {
    setFormData(prev => ({
      ...prev,
      [domainId]: {
        ...prev[domainId],
        showForm: !prev[domainId].showForm
      }
    }));
  };

  // Update form data for a given domain.
  const handleInputChange = (domainId, field, value) => {
    setFormData(prev => ({
      ...prev,
      [domainId]: {
        ...prev[domainId],
        [field]: value
      }
    }));
  };

  // Handle document submission using the createDocument handler.
  const handleDocumentSubmit = async (domainId, e) => {
    e.preventDefault();
    const { documentName, documentText } = formData[domainId];
    if (!documentName.trim() || !documentText.trim()) {
      toast.error("Please fill in both document name and text");
      return;
    }
    try {
      const documentId = uuidv4();
      await createDocument({
        document_id: documentId,
        name: documentName,
        text: documentText,
        domain_id: domainId
      });
      toast.success("Document created successfully!");
      // Reset form.
      setFormData(prev => ({
        ...prev,
        [domainId]: { showForm: false, documentName: "", documentText: "" }
      }));
      fetchDocumentsForDomain(domainId);
    } catch (error) {
      console.error("Error creating document:", error);
      toast.error("Error creating document");
    }
  };

  const handleNavigateToBuildBrain = (domain) => {
    const docs = documentsByDomain[domain.domain_id] || [];
    const documentIds = docs.map(doc => doc.document_id);
    navigate("/build-brain", { state: { domainId: domain.domain_id, documentIds } });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Domains</h1>
      <div className="grid gap-4 md:grid-cols-2">
        {domains.map((domain) => (
          <DomainCard
            key={domain.domain_id}
            domain={domain}
            documents={documentsByDomain[domain.domain_id] || []}
            formState={formData[domain.domain_id] || { showForm: false, documentName: "", documentText: "" }}
            toggleFormVisibility={toggleFormVisibility}
            handleInputChange={handleInputChange}
            handleDocumentSubmit={handleDocumentSubmit}
            handleNavigateToBuildBrain={handleNavigateToBuildBrain}
          />
        ))}
      </div>
      <DomainCreationForm
        newDomainName={newDomainName}
        setNewDomainName={setNewDomainName}
        isCreatingDomain={isCreatingDomain}
        handleDomainSubmit={handleDomainSubmit}
        toggleFormVisibility={toggleFormVisibility}
        handleInputChange={handleInputChange}
        handleDocumentSubmit={handleDocumentSubmit}
      />
    </div>
  );
};

export default DomainsPage;