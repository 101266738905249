// src/components/common/overlays/ElementDetails.jsx
import React from 'react';
import PropTypes from 'prop-types';
import NodeDetails from './NodeDetails';
import EdgeDetails from './EdgeDetails';

const ElementDetails = ({ selectedElementData, graphNodes, relationships }) => {
  if (!selectedElementData) return null;

  const isEdge = typeof selectedElementData.source !== 'undefined';

  return (
    <div className="border border-white p-2 rounded bg-white shadow-md">
      <h2 className="text-lg font-semibold mb-1">Element</h2>
      {isEdge ? (
        <EdgeDetails selectedElementData={selectedElementData} graphNodes={graphNodes} />
      ) : (
        <NodeDetails
          selectedElementData={selectedElementData}
          graphNodes={graphNodes}
          relationships={relationships}
        />
      )}
    </div>
  );
};

ElementDetails.propTypes = {
  selectedElementData: PropTypes.object,
  graphNodes: PropTypes.array,
  relationships: PropTypes.array,
};

export default ElementDetails;