import React, { Component, ReactNode } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"

class SpeciesErrorBoundary extends Component<{
  onError: (error: Error, errorInfo: React.ErrorInfo) => void
  children?: ReactNode
}> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.onError(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return null
    }
    return this.props.children
  }
}

export function SpeciesErrorBoundaryWrapper({ children }: { children?: ReactNode }) {
  const navigate: NavigateFunction = useNavigate()

  const handleError = (error: Error) => {
    console.error("[SpeciesErrorBoundary caught an error]", error)
    navigate("/species")
  }

  return (
    <SpeciesErrorBoundary onError={handleError}>
      {children}
    </SpeciesErrorBoundary>
  )
}