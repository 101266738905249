import React from "react";
import PDFViewer from "./PDFViewer.tsx";

interface PDFPreviewPanelProps {
  markdown: string;
  highlightText: string | null;
  highlightVersion: number;
}

export const PDFPreviewPanel: React.FC<PDFPreviewPanelProps> = ({
  markdown,
  highlightText,
  highlightVersion,
}) => {
  return (
    <div>
      <PDFViewer
        markdown={markdown}
        highlightText={highlightText}
        highlightVersion={highlightVersion}
      />
    </div>
  );
};