// src/hooks/useSliderPosition.js
import { useState, useEffect } from 'react';


const useSliderPosition = (containerRef, sliderWidth = 256) => {
  const [sliderStyle, setSliderStyle] = useState({
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'none'
  });

  useEffect(() => {
    const updateSliderPosition = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        // Hide slider if container is off-screen.
        if (rect.bottom <= 0 || rect.top >= window.innerHeight) {
          setSliderStyle(prev => ({ ...prev, display: 'none' }));
          return;
        }
        const top = rect.top < 0 ? 0 : rect.top;
        const left = rect.left + rect.width / 2 - sliderWidth / 2;
        setSliderStyle({
          position: 'fixed',
          top,
          left,
          display: 'block'
        });
      }
    };

    updateSliderPosition();
    window.addEventListener('scroll', updateSliderPosition);
    window.addEventListener('resize', updateSliderPosition);
    return () => {
      window.removeEventListener('scroll', updateSliderPosition);
      window.removeEventListener('resize', updateSliderPosition);
    };
  }, [containerRef, sliderWidth]);

  return sliderStyle;
};

export default useSliderPosition;