// src/pages/graph_gen/OntologyPage.jsx
import React, { useState, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import useSliderPosition from '../../core/helpers/graph_gen/common/useSliderPosition';
import GraphEditor from '../../core/containers/graph_gen/ontology/GraphEditor';
import GraphDataProvider from '../../core/containers/graph_gen/ontology/GraphDataProvider';
import {
  addNodeHandler,
  addRelationshipEntityHandler,
} from '../../core/handlers/graph_gen/ontology/graphHandlers';
import { createOntology } from '../../services/api/apiHandlers';

const OntologyPage = () => {
  // Fixed mode to ExtensiveOntology.
  const [mode] = useState('ExtensiveOntology');
  const [colorMode, setColorMode] = useState('colorless');

  // State for the selected graph element.
  const [selectedElementData, setSelectedElementData] = useState(null);

  // State for the ontology name input.
  const [ontologyName, setOntologyName] = useState('');

  // States for search overlay.
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  // States for slider overlay.
  const [stateIndex, setStateIndex] = useState(0);
  const [sliderMessage, setSliderMessage] = useState('');
  const stateLabels = Array(16).fill('');

  // Refs for the graph container and source overlay.
  const graphContainerRef = useRef(null);
  const sourceContainerRef = useRef(null);
  const sliderStyle = useSliderPosition(graphContainerRef, 256);
  const toggleSearchInput = () => setIsSearchVisible((prev) => !prev);
  const handleElementSelect = (data) => setSelectedElementData(data);

  // Overlay configuration for element details.
  const overlayProps = useMemo(() => ({
    mode, // ExtensiveOntology
    sliderStyle,
    stateIndex,
    stateLabels,
    sliderMessage,
    onSliderChange: (e) => {
      const val = +e.target.value;
      setSliderMessage(val >= stateLabels.length ? 'Locked' : '');
      if (val < stateLabels.length) setStateIndex(val);
    },
    showSearchInput: isSearchVisible,
    toggleSearchInput,
    searchTerm,
    setSearchTerm,
    sourceContainerRef,
    sourceText: '',
    finalHighlightedHTML: '',
    selectedElementData,
    traceRefs: selectedElementData?.references || [],
    handleTrace: () => console.log('Trace pressed'),
    colorMode,
    // graphNodes and relationships will be set in GraphEditor.
  }), [
    mode,
    sliderStyle,
    stateIndex,
    stateLabels,
    sliderMessage,
    isSearchVisible,
    searchTerm,
    sourceContainerRef,
    selectedElementData,
    colorMode,
  ]);

  return (
    <GraphDataProvider mode={mode} colorMode={colorMode}>
      {({ safeData, transformedData, updateGraphData }) => {
        // Save Ontology handler using the API helper.
        const handleSaveOntology = async () => {
          if (!ontologyName.trim()) {
            toast.error("Please provide a name for the ontology.");
            return;
          }
          try {
            // Construct the payload to match the expected schema.
            const payload = {
              ontology_id: uuidv4(),         // Generate a unique identifier
              Name: ontologyName,            // Use the user's input
              ontology_data: safeData,       // The ontology JSON data
            };
            const result = await createOntology(payload);
            toast.success("Ontology saved successfully!");
          //  console.log("Saved ontology:", result);
          } catch (error) {
            console.error("Error saving ontology:", error);
            toast.error("Failed to save ontology");
          }
        };

        return (
          <div className="min-h-screen p-4 bg-gray-50 relative">
            <div className="flex flex-col md:flex-row md:items-center mb-4">
              <h1 className="text-2xl font-bold">New Ontology</h1>
              <div className="mt-2 md:mt-0 md:ml-4 flex-grow">
                <label
                  htmlFor="ontologyName"
                  className="block text-sm font-medium mb-1"
                >
                  Ontology Name
                </label>
                <input
                  id="ontologyName"
                  type="text"
                  value={ontologyName}
                  onChange={(e) => setOntologyName(e.target.value)}
                  className="w-full md:max-w-xs p-2 border rounded"
                  placeholder="Enter ontology name"
                />
              </div>
              <button
                onClick={handleSaveOntology}
                className="mt-2 md:mt-0 md:ml-auto px-4 py-2 bg-blue-600 text-white rounded"
              >
                Save Ontology
              </button>
            </div>
            <GraphEditor
              ref={graphContainerRef}
              colorMode={colorMode}
              setColorMode={setColorMode}
              handleAddNode={(nodeData) => addNodeHandler(nodeData, updateGraphData)}
              handleAddRelationshipEntity={(entityData) =>
                addRelationshipEntityHandler(entityData, updateGraphData)
              }
              transformedData={transformedData}
              safeData={safeData}
              updateGraphData={updateGraphData}
              handleElementSelect={handleElementSelect}
              overlayProps={{
                ...overlayProps,
                selectedElementData,
                graphNodes: transformedData?.Entities?.Entities || [],
                relationships: transformedData?.Relationships?.TypeRelationships || [],
              }}
            />
          </div>
        );
      }}
    </GraphDataProvider>
  );
};

export default OntologyPage;