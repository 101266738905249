// src/organizations/biodiversity/core/home/upload/components/FileUpload.tsx
import React, { useState } from "react";
import { Upload } from "lucide-react";
import { Card } from "../../../common/card.tsx";
import {
  createHandleDragEnter,
  createHandleDragOver,
  createHandleDragLeave,
  createHandleDrop,
  createHandleFileSelect,
} from "../fileUploadHandlers.ts";

interface FileUploadProps {
  files: File[];
  onFileSelect: (files: File[]) => void;
  isUploading?: boolean;
  uploadComplete?: boolean; // New optional prop
}

export const FileUpload = ({ files, onFileSelect, isUploading, uploadComplete }: FileUploadProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const onDragEnter = createHandleDragEnter(setIsDragging);
  const onDragOver = createHandleDragOver(setIsDragging);
  const onDragLeave = createHandleDragLeave(setIsDragging);
  const onDrop = createHandleDrop(files, onFileSelect, setIsDragging);
  const onChange = createHandleFileSelect(files, onFileSelect);

  return (
    <Card className="p-6">
      <div
        className={`border-2 border-dashed rounded-lg p-8 text-center ${
          isDragging ? "border-primary bg-primary/5" : "border-primary"
        }`}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <Upload className="mx-auto h-12 w-12 text-primary" />
        <p className="mt-2 text-sm text-gray-600">
          Drop the PDF file(s) here or{" "}
          <label className="text-primary font-semibold cursor-pointer hover:underline">
            browse
            <input
              type="file"
              className="hidden"
              accept=".pdf"
              multiple
              onChange={onChange}
            />
          </label>
        </p>
        {files.length > 0 && (
          <p className="mt-2 text-sm text-gray-500">
            {isUploading
              ? "Uploading file(s)..."
              : uploadComplete
              ? "Uploaded"
              : "Selected files: " + files.map((f) => f.name).join(", ")}
          </p>
        )}
      </div>
    </Card>
  );
};

export default FileUpload;