import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { ArrowLeft } from "lucide-react";
import { ExtractionJobsTable } from "../core/review/selection/ExtractionJobsTable.jsx";
import { ReviewForm } from "../core/review/curation/ReviewForm.tsx";
import { getPdfExtractionStatus, deleteExtractionStatus } from "../services/api.ts";
import { useExtractionJobs } from "../core/review/selection/useExtractionJobs.ts";
import { Button } from "../core/common/button.tsx";

export default function ReviewPage() {
  const [extractedData, setExtractedData] = useState(null);
  const [currentStep, setCurrentStep] = useState("table");
  const [speciesName, setSpeciesName] = useState("");

  const { extractionJobs, jobsLoading, jobsError, fetchExtractionJobs } = useExtractionJobs();

  async function handleCurate(extractionId) {
    try {
      const data = await getPdfExtractionStatus(extractionId);
      const curatedData = { ...data.extracted_data };
      curatedData.extraction_id = data.extraction_id;
      curatedData.pdf_key = data.pdf_key;
      curatedData.status = data.status;
      setExtractedData(curatedData);
      setSpeciesName(data.species_name);
      setCurrentStep("review");
    } catch (error) {
      toast.error("Failed to fetch extraction details: " + error.message);
    }
  }

  async function handleDeleteExtraction(extractionId) {
    if (!window.confirm("Are you sure you want to delete this extraction?")) return;
    try {
      await deleteExtractionStatus(extractionId);
      toast.success("Extraction deleted successfully!");
      fetchExtractionJobs();
    } catch (error) {
      toast.error("Failed to delete extraction: " + error.message);
    }
  }

  function handleBackToJobs() {
    setExtractedData(null);
    setCurrentStep("table");
  }

  return (
    <div className="p-6 space-y-8">
      <div className="relative flex items-center justify-center">
        <div className="absolute left-0">
          {currentStep === "table" ? (
            <Link to="/species" title="Back to Species Home">
              <Button variant="outline" size="sm" className="flex items-center">
                <ArrowLeft className="mr-1 h-4 w-4" />
                Back
              </Button>
            </Link>
          ) : (
            <Button onClick={handleBackToJobs} variant="outline" size="sm" className="flex items-center">
              <ArrowLeft className="mr-1 h-4 w-4 text-success hover:text-successDark" />
              Back
            </Button>
          )}
        </div>
        <h1 className="text-xl sm:text-2xl font-bold text-center">
          {speciesName ? `Review: ${speciesName}` : "Review"}
        </h1>
      </div>

      {currentStep === "table" && (
        <ExtractionJobsTable
          extractionJobs={extractionJobs}
          jobsLoading={jobsLoading}
          jobsError={jobsError}
          onCurate={handleCurate}
          onDeleteExtraction={handleDeleteExtraction}
          onRefresh={fetchExtractionJobs}
          hideExtractionId={true}
        />
      )}

      {currentStep === "review" && extractedData && (
        <ReviewForm
          extractedData={extractedData}
          selectedFile={null}
          speciesName={speciesName}
          onConfirm={() => {
            toast.success("Data saved successfully!");
            handleBackToJobs();
          }}
        />
      )}
    </div>
  );
}