// src/core/hooks/graph_gen/build_brain/useOntologyManager.js
import { useState, useEffect } from 'react';
// Import local ontologies
import primeKGOntology from '../../../../services/primekg_ontology.json';
import exampleCuration from '../../../../services/example_curation.json';
import narrowedGraph from '../../../../services/narrowed_graph.json';
// Removed simulationOntology import as it's no longer needed

const useOntologyManager = () => {
  const [availableOntologies, setAvailableOntologies] = useState([]);
  const [selectedOntology, setSelectedOntology] = useState(null);
  const [validatedExtractions, setValidatedExtractions] = useState([]);
  const [showOntologyExtraction, setShowOntologyExtraction] = useState(true);

  useEffect(() => {
    // Include only the three options: PrimeKG, PrimeKG-curation, and PrimeKG-narrowed
    const localOntologies = [
      {
        ontology_id: "primekg",
        Name: "PrimeKG Ontology",
        ontology_data: primeKGOntology
      },
      {
        ontology_id: "primekg-curation",
        Name: "PrimeKG Ontology",
        ontology_data: exampleCuration
      },
      {
        ontology_id: "primekg-narrowed",
        Name: "PrimeKG Ontology",
        ontology_data: narrowedGraph
      }
    ];
    setAvailableOntologies(localOntologies);
  }, []);

  useEffect(() => {
    if (availableOntologies.length > 0 && !selectedOntology) {
      setSelectedOntology(availableOntologies[0]);
    }
  }, [availableOntologies, selectedOntology]);

  return {
    availableOntologies,
    selectedOntology,
    setSelectedOntology,
    validatedExtractions,
    setValidatedExtractions,
    setShowOntologyExtraction,
  };
};

export default useOntologyManager;