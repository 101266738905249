// src/core/helpers/graph_gen/common/knowledge_graph/prepare_new_graph_elements/prepareTypeEdges.js

export const prepareTypeEdges = (data) => {
  const edges = [];
  if (data.Relationships && Array.isArray(data.Relationships.TypeRelationships)) {
    data.Relationships.TypeRelationships.forEach((rel) => {
      edges.push({
        data: {
          id: `${rel.ID}`,
          source: `${rel.SourceNodeID}`,
          target: `${rel.TargetNodeID}`,
          label: "Is A",
          info: rel.Motivation,
          relationshipType: "Type",
          // Explicitly set edge color to black
          color: "#000000",
        },
      });
    });
  }
  return edges;
};