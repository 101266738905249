// src/core/hooks/graph_gen/build_brain/useValidatedExtractionData.js
import { useMemo } from 'react';
import { mergeValidatedExtractionsWithOntology } from '../../../handlers/graph_gen/build_brain/mergeValidatedExtractionsWithOntology';

const useValidatedExtractionData = (validatedExtractions, selectedOntology, commonOverlayProps, colorMode) => {
  return useMemo(() => {
    // If no ontology or validated extractions are provided, return empty data.
    if (!selectedOntology || !validatedExtractions || validatedExtractions.length === 0) {
      return {
        ontologyOnlyData: null,
        validatedAttributeData: null,
        rawExtractionsData: null,
        ontologyOverlayProps: commonOverlayProps,
        attributeOverlayProps: commonOverlayProps,
      };
    }

    // NORMAL CASE: For all ontologies, merge validated extractions with ontology data.
    let mergedValidatedData = null;
    let validatedAttributeData = null;
    console.log("selectedOntology", selectedOntology);

    let actualOntologyData = {};
    if (selectedOntology.ontology_data) {
      if (typeof selectedOntology.ontology_data === 'string') {
        try {
          actualOntologyData = JSON.parse(selectedOntology.ontology_data);
        } catch (err) {
          console.error("Error parsing ontology data:", err);
        }
      } else {
        actualOntologyData = selectedOntology.ontology_data;
      }
    } else {
      console.warn("Selected ontology does not have an 'ontology_data' field:", selectedOntology);
    }
    console.log("Merging validated extractions with ontology");
    mergedValidatedData = mergeValidatedExtractionsWithOntology(validatedExtractions, actualOntologyData);
    validatedAttributeData = mergedValidatedData;

    // Transform ontology-only data (for overlay) by copying all relationship arrays.
    const transformOntologyOnlyData = (ontologyData, colorMode) => {
      const graph = {
        Entities: { Entities: [] },
        Relationships: {
          TypeRelationships: [],
          AttributeRelationships: [],
          DefinitionMapping: [],
        },
        RelationshipEntities: {
          AttributeRelationshipEntities: [],
          StructuralRelationshipEntities: [],
        },
      };

      if (ontologyData.Entities && Array.isArray(ontologyData.Entities.Entities)) {
        graph.Entities.Entities = ontologyData.Entities.Entities.map((node) => ({
          ...node,
          ID: `${node.ID}`,
          baseColor: node.baseColor || "#ffffff",
        }));
        if (ontologyData.Relationships && Array.isArray(ontologyData.Relationships.TypeRelationships)) {
          graph.Relationships.TypeRelationships = ontologyData.Relationships.TypeRelationships.map((rel) => ({
            ...rel,
            SourceNodeID: `${rel.SourceNodeID}`,
            TargetNodeID: `${rel.TargetNodeID}`,
          }));
        }
        if (ontologyData.Relationships && Array.isArray(ontologyData.Relationships.AttributeRelationships)) {
          graph.Relationships.AttributeRelationships = ontologyData.Relationships.AttributeRelationships.map((rel) => ({
            ...rel,
            SourceNodeID: `${rel.SourceNodeID}`,
            TargetNodeID: `${rel.TargetNodeID}`,
          }));
        }
        if (ontologyData.Relationships && Array.isArray(ontologyData.Relationships.DefinitionMapping)) {
          graph.Relationships.DefinitionMapping = ontologyData.Relationships.DefinitionMapping.map((rel) => ({
            ...rel,
            SourceNodeID: `${rel.SourceNodeID}`,
            TargetNodeID: `${rel.TargetNodeID}`,
          }));
        }
      }
      return graph;
    };

    let ontologyOnlyData = null;
    if (selectedOntology && selectedOntology.ontology_data) {
      let parsedOntology = {};
      if (typeof selectedOntology.ontology_data === 'string') {
        try {
          parsedOntology = JSON.parse(selectedOntology.ontology_data);
        } catch (err) {
          console.error("Error parsing ontology data:", err);
        }
      } else {
        parsedOntology = selectedOntology.ontology_data;
      }
      ontologyOnlyData = transformOntologyOnlyData(parsedOntology, colorMode);
    } else {
      console.warn("No ontology_data found in selectedOntology:", selectedOntology);
    }

    const ontologyOverlayProps = {
      ...commonOverlayProps,
      mode: "Ontology",
      graphNodes: ontologyOnlyData && ontologyOnlyData.Entities ? ontologyOnlyData.Entities.Entities : [],
      relationships: ontologyOnlyData && ontologyOnlyData.Relationships ? ontologyOnlyData.Relationships.TypeRelationships || [] : [],
    };

    const attributeOverlayProps = {
      ...commonOverlayProps,
      mode: "Attribute",
      graphNodes: validatedAttributeData && validatedAttributeData.Entities
        ? validatedAttributeData.Entities.Entities
        : [],
      relationships: [
        ...(validatedAttributeData && validatedAttributeData.Relationships
          ? validatedAttributeData.Relationships.AttributeRelationships || []
          : []),
        ...(validatedAttributeData && validatedAttributeData.Relationships
          ? validatedAttributeData.Relationships.DefinitionMapping || []
          : []),
        ...(ontologyOnlyData && ontologyOnlyData.Relationships
          ? ontologyOnlyData.Relationships.TypeRelationships || []
          : [])
      ],
    };

    const rawExtractionsData = mergedValidatedData;
    return { 
      ontologyOnlyData, 
      validatedAttributeData, 
      rawExtractionsData,
      ontologyOverlayProps, 
      attributeOverlayProps 
    };
    
  }, [validatedExtractions, selectedOntology, commonOverlayProps, colorMode]);
};

export default useValidatedExtractionData;