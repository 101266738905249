// src/core/ui/general/controls/Button.jsx
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Button = ({
  children,
  onClick,
  variant,
  disabled,
  iconOnly,
  additionalClasses,
  to,
}) => {
  // Move padding and rounded styles to an inner span so the clickable element covers the full area.
  const innerClasses = 'px-6 py-3 rounded-md text-sm font-medium transition';
  const baseClasses = iconOnly
    ? 'p-2 inline-flex items-center justify-center focus:outline-none transition'
    : innerClasses;

  const variants = {
    primary: 'bg-blue-500 text-white hover:bg-blue-600',
    secondary: 'bg-gray-200 text-gray-700 hover:bg-gray-300',
    success: 'bg-[#1d1d22] text-white hover:bg-[#5a5a5f]',
    danger: 'bg-danger text-white hover:bg-danger',
  };

  // Combine classes for the outer element.
  const outerClasses = classNames(
    baseClasses,
    variants[variant],
    { 'opacity-50 cursor-not-allowed': disabled },
    additionalClasses
  );

  if (to) {
    return (
      <Link to={to} className={`${outerClasses} flex`}>
        {/* The inner span takes up full width/height so that the entire area is clickable */}
        <span className="w-full h-full block">
          {children}
        </span>
      </Link>
    );
  }
  
  return (
    <button onClick={onClick} className={outerClasses} disabled={disabled}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger']),
  disabled: PropTypes.bool,
  iconOnly: PropTypes.bool,
  additionalClasses: PropTypes.string,
  to: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => {},
  variant: 'primary',
  disabled: false,
  iconOnly: false,
  additionalClasses: '',
  to: null,
};

export default Button;