// src/core/helpers/graph_gen/common/knowledge_graph/prepare_new_graph_elements/prepareAttributeEdges.js

export const prepareAttributeEdges = (data) => {
  const edges = [];
  if (data.Relationships && Array.isArray(data.Relationships.AttributeRelationships)) {
    data.Relationships.AttributeRelationships.forEach((rel) => {
      let label = "has attribute";
      if (
        data.RelationshipEntities &&
        Array.isArray(data.RelationshipEntities.AttributeRelationshipEntities)
      ) {
        const relEntity = data.RelationshipEntities.AttributeRelationshipEntities.find(
          (ent) => ent.ID === rel.EntityID
        );
        if (relEntity && relEntity.Name) {
          label = relEntity.Name;
        }
      }
      const edgeData = {
        id: `${rel.ID}`,
        source: `${rel.SourceNodeID}`,
        target: `${rel.TargetNodeID}`,
        label,
        info: rel.Motivation,
        relationshipType: "Defined",
        // Explicitly set edge color to black
        color: "#000000",
      };
      console.log("Processing attribute relationship:", rel);
      if (rel.PluralityType && rel.PluralityType.toLowerCase() === "onetoone") {
        edgeData.oneToOne = true;
        console.log("Flagging as oneToOne in AttributeRelationship:", edgeData);
      }
      edges.push({ data: edgeData });
    });
  }
  return edges;
};