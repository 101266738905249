// src/components/WebsiteField.jsx
import React, { useState, useRef } from 'react';
import { FaGlobe } from 'react-icons/fa';

const WebsiteField = () => {
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef(null);

  const handleContainerClick = () => {
    setIsActive(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleBlur = () => {
    setIsActive(false);
  };
  const computedClasses = isActive 
    ? "opacity-100 pointer-events-auto visible"
    : "opacity-0 pointer-events-none invisible group-hover:opacity-100 group-hover:pointer-events-auto group-focus-within:opacity-100 group-focus-within:pointer-events-auto";

  return (
    <div className="relative inline-block group" onClick={handleContainerClick}>
      <span className="cursor-pointer">
        <FaGlobe size={20} />
      </span>
      <input
        ref={inputRef}
        type="text"
        placeholder="Website URL"
        onBlur={handleBlur}
        className={`absolute left-full top-1/2 transform -translate-y-1/2 ml-2 transition-opacity duration-200 z-50 ${computedClasses}`}
      />
    </div>
  );
};

export default WebsiteField;