import React, { forwardRef } from 'react';
import GraphOverlayContainer from '../common/GraphOverlayContainer';

const GraphVisualization = forwardRef(({ graphData, graphHeight, onElementSelect, overlayProps }, ref) => {
  return (
    <div
      className="relative"
      ref={ref}
      style={{ height: `${graphHeight}px`, border: '1px solid #ccc' }}
    >
      {graphData ? (
        <GraphOverlayContainer
          graphData={graphData}
          graphHeight={graphHeight}
          onElementSelect={onElementSelect}
          overlayProps={overlayProps}
        />
      ) : (
        <p className="p-4 text-gray-500">Select a generation to visualize...</p>
      )}
    </div>
  );
});

export default GraphVisualization;