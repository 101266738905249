export const makeSafeData = (rawData) => {
    if (!rawData) {
      return {
        Entities: { Entities: [] },
        Relationships: { TypeRelationships: [], AttributeRelationships: [], DefinitionMapping: [] }
      };
    }
    return {
      ...rawData,
      Entities: rawData.Entities || { Entities: [] },
      Relationships: rawData.Relationships || { TypeRelationships: [], AttributeRelationships: [], DefinitionMapping: [] }
    };
  };