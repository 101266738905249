// src/core/containers/graph_gen/common/GraphContainer.jsx
import React, { useEffect, useRef } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import useCytoscapeSetup from '../../../hooks/graph_gen/common/useCytoscapeSetup';
import useClampPan from '../../../hooks/graph_gen/common/useClampPan';
import MyCustomLayout from '../../../helpers/graph_gen/common/myCustomLayout';

const GraphContainer = ({ elements, layoutType, onElementSelect, stylesheet, height }) => {
  const cyRef = useRef(null);
  const containerRef = useRef(null);
  const onElementSelectRef = useRef(onElementSelect);

  useEffect(() => {
    onElementSelectRef.current = onElementSelect;
  }, [onElementSelect]);

  // Use the dynamic layout type: if "fcose" then use fcose, otherwise use the provided layoutType.
  useCytoscapeSetup(
    cyRef,
    elements,
    layoutType === 'fcose' ? 'fcose' : layoutType,
    onElementSelectRef
  );
  useClampPan(cyRef, containerRef);

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: `${height}px`, backgroundColor: '#f9fafb', overflow: 'hidden' }}
    >
      <CytoscapeComponent
        cy={(cy) => { cyRef.current = cy; }}
        elements={[]}
        stylesheet={stylesheet}
        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        wheelSensitivity={0.5}
      />
    </div>
  );
};

export default GraphContainer;