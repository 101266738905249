// src/core/ui/general/home/BookCalendarModal.jsx
import React from 'react';
import Modal from '../controls/Modal';

const BookCalendarModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Schedule a Meeting">
      <iframe
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ12c-di5o0hYiT-FYvpv78_SQk8ziuxQlTcwmWa3J8mFXp2nwApjk0I-2p3waQObwoEnVmzX0tU?gv=true"
        style={{ border: 0 }}
        width="100%"
        height="600"
        frameBorder="0"
      ></iframe>
    </Modal>
  );
};

export default BookCalendarModal;
