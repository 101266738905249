// src/App.jsx
import React from 'react';
import './styling/index.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './services/firebase';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import Header from './core/containers/general/Header';
import Footer from './core/ui/general/layout/Footer';

import Home from './pages/general/Home';
import LoginPage from './pages/general/LoginPage';
import ManageAccount from './pages/general/ManageAccount';
import BuilderPage from './pages/legacy/BuilderPage';
import SpeciesLoginPage from './organizations/biodiversity/pages/SpeciesLoginPage';
import SpeciesPortal from './organizations/biodiversity/SpeciesPortal';
import BuildBrainPage from './pages/graph_gen/BuildBrainPage';
import DomainsPage from './pages/graph_gen/DomainsPage';
import DebugPage from './pages/graph_gen/DebugPage';
import OntologyPage from './pages/graph_gen/OntologyPage';
import ManualGraph from './pages/graph_gen/ManualGraph';
import MorphingPage from './pages/demo/MorphingPage';
import GeometricSpreadsheet from './pages/demo/GeometricSpreadsheet';
import MorphingSpreadsheetHighScale from './pages/demo/MorphingSpreadsheet';
import PdfViewer from './pages/general/PdfViewer';

const ProtectedRoute = ({ user, children }) =>
  user ? children : <Navigate to="/login" replace />;
const ProtectedSpeciesRoute = ({ user, children }) =>
  user ? children : <Navigate to="/species-login" replace />;

const AppContent = () => {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        Loading ...
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header user={user} onSignOut={() => auth.signOut()} />
      <div className="relative flex-grow">
        <div className="absolute inset-0 bg-paper -z-10"></div>

        <div className="relative z-10 flex flex-col min-h-full">
          <Routes>
          <Route path="/pdf-viewer" element={<PdfViewer />} />
            <Route path="/home" element={<Home />} />
            <Route path="/ycombinator" element={<BuilderPage />} />
            <Route path="/demo" element={<BuilderPage />} />
            <Route
              path="/login"
              element={
                user ? <Navigate to="/builder" replace /> : <LoginPage />
              }
            />
            <Route path="/spreadsheet" element={<GeometricSpreadsheet />} />
            <Route
              path="/morphing-grid"
              element={<MorphingSpreadsheetHighScale />}
            />
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route
              path="/build-brain"
              element={
                <ProtectedRoute user={user}>
                  <BuildBrainPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage-account"
              element={
                <ProtectedRoute user={user}>
                  <ManageAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="/builder"
              element={
                <ProtectedRoute user={user}>
                  <BuilderPage />
                </ProtectedRoute>
              }
            />
            <Route path="/morph" element={<MorphingPage />} />
            <Route
              path="/debug"
              element={
                <ProtectedRoute user={user}>
                  <DebugPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ontology"
              element={
                <ProtectedRoute user={user}>
                  <OntologyPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manual-graph"
              element={
                <ProtectedRoute user={user}>
                  <ManualGraph />
                </ProtectedRoute>
              }
            />
            <Route
              path="/species-login"
              element={
                user ? <Navigate to="/species" replace /> : <SpeciesLoginPage />
              }
            />
            <Route
              path="/species/*"
              element={
                <ProtectedSpeciesRoute user={user}>
                  <SpeciesPortal />
                </ProtectedSpeciesRoute>
              }
            />
            <Route
              path="/domains"
              element={
                <ProtectedRoute user={user}>
                  <DomainsPage />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
          {/* Footer with a transparent background so the bg-paper shows through */}
          <Footer />
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        closeOnClick
      />
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;