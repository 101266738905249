import React, { useState } from 'react';
import HeroSection from '../../core/ui/general/home/HeroSection';
import DNAStrand from '../../core/ui/general/home/DNAStrand';
import KnowledgeGraphWithFiles from '../../core/ui/general/home/KnowledgeGraphWithFiles';
import BookCalendarModal from '../../core/ui/general/home/BookCalendarModal';
import CreativeDesignIcons from '../../core/ui/general/home/ModernElegantDesignIcons';
import ArchitectureDesignTriangle from '../../core/ui/general/home/ArchitectureDesignTriangle';
const Home = () => {
  const [showDemoModal, setShowDemoModal] = useState(false);

  return (
    <div className="min-h-screen flex flex-col bg-paper relative">
      <HeroSection onDemoClick={() => setShowDemoModal(true)} />
      
      <div className="relative my-8">
        <div 
          className="flex justify-center"
          style={{ left: "calc(45% - 600px)", transform: "translateX(-1.5%)" }}
        >
          <DNAStrand />
        </div>
        <div 
          className="absolute top-1/2" 
          style={{ left: "calc(45% - 600px)", transform: "translateY(-80%)" }}
        >
          <KnowledgeGraphWithFiles />
        </div> 
        <div
          className="absolute top-1/2"
          style={{ right: "calc(45% - 600px)", transform: "translateY(-80%)" }}
        >
          <ArchitectureDesignTriangle />
        </div>
      </div>
      
      <BookCalendarModal 
        isOpen={showDemoModal} 
        onClose={() => setShowDemoModal(false)} 
      />
      
      {/* Section displaying 50 creative design icon variants */}
      
    </div>
  );
};

export default Home;