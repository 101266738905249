
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function highlightMatches(text, query) {
    let safe = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    
    if (!query.trim()) {
      return safe;
    }
  
    const escaped = query.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    const regex = new RegExp(`(${escaped})`, 'gi');
  
    let replaced = safe.replace(regex, '<mark class="bg-green-300">$1</mark>');
    
    return replaced;
  }
 
  export function highlightMultipleMatches2(html, queries) {
    if (!Array.isArray(queries)) return html;
    
    let highlightedHtml = html;
    queries.forEach((query) => {
      if (typeof query !== 'string') {
        console.warn("Skipping query because it's not a string:", query);
        return;
      }
      
      const trimmedQuery = query.trim();
      if (!trimmedQuery) {
        console.warn("Skipping query because it's empty:", query);
        return;
      }
      
      try {
        // Escape the query for regex use.
        const regex = new RegExp(escapeRegExp(trimmedQuery), 'gi');
        highlightedHtml = highlightedHtml.replace(regex, (match) => `<mark>${match}</mark>`);
      } catch (error) {
        console.error("Error creating regex for query:", query, error);
      }
    });
    
    return highlightedHtml;
  }