// src/organizations/biodiversity/core/home/upload/components/UploadSection.tsx
import React from "react";
import { UploadForm } from "./UploadForm.tsx";

interface UploadSectionProps {
  selectedFiles: File[];
  onFileSelect: (files: File[]) => void;
  onSpeciesSelect: (species: { id: string; name: string }) => void;
  onExtractData: () => void;
  isUploading: boolean;
  uploadComplete?: boolean; // New optional prop
}

export const UploadSection: React.FC<UploadSectionProps> = ({
  selectedFiles,
  onFileSelect,
  onSpeciesSelect,
  onExtractData,
  isUploading,
  uploadComplete,
}) => {
  return (
    // Added mx-auto to center horizontally
    <div className="w-full sm:w-1/2 mx-auto">
      <UploadForm
        selectedFiles={selectedFiles}
        onFileSelect={onFileSelect}
        onExtractData={onExtractData}
        onSpeciesSelect={onSpeciesSelect}
        isUploading={isUploading}
        uploadComplete={uploadComplete} // Pass along the state
      />
    </div>
  );
};

export default UploadSection;