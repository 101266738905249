import { getRelEntityName } from './relationshipUtils';

export const groupOntologyEdgesByPivot = (relEdges, rawData, config) => {
  const groups = {};

  const determinePivotAndValue = (edge) => {
    const label = getRelEntityName(edge, rawData);
    const lowerLabel = label.toLowerCase();
    if (lowerLabel.includes(config.founderKeyword.toLowerCase())) {
      return { pivot: edge.TargetNodeID, value: edge.SourceNodeID };
    } else if (lowerLabel.includes(config.yearKeyword.toLowerCase())) {
      return { pivot: edge.SourceNodeID, value: edge.TargetNodeID };
    } else {
      return { pivot: edge.TargetNodeID, value: edge.SourceNodeID };
    }
  };

  relEdges.forEach(edge => {
    const label = getRelEntityName(edge, rawData);
    const { pivot, value } = determinePivotAndValue(edge);
    if (!groups[pivot]) {
      groups[pivot] = {};
    }
    if (!groups[pivot][label]) {
      groups[pivot][label] = [];
    }
    groups[pivot][label].push(value);
  });
  return groups;
};