import { TableRowData } from "../curation/ReviewTable.tsx";
import { parseNumberOrNull, parseBoolOrNull } from "./dataParsers.ts";

function parseListOfNumbers(val: any): number[] {
  if (Array.isArray(val)) {
    const result = val
      .map((item, index) => {
        const num = Number(item);
        return num;
      })
      .filter((item, index) => {
        if (isNaN(item)) {
          console.warn(
            `[parseListOfNumbers] Array item at index ${index} is not a number and will be filtered out.`
          );
          return false;
        }
        return true;
      });
    return result;
  }
  if (typeof val === "string") {
    const parts = val.split(",");
    const result = parts
      .map((item, index) => {
        const trimmed = item.trim();
        const num = Number(trimmed);
        return num;
      })
      .filter((item, index) => {
        if (isNaN(item)) {
          console.warn(
            `[parseListOfNumbers] String item at index ${index} is not a number and will be filtered out.`
          );
          return false;
        }
        return true;
      });
    return result;
  }
  return [];
}

export function buildExtractedDataPayload(
  tableData: TableRowData[],
  pdfKey: string,
  speciesName: string
) {

  const getDataPoint = (name: string) => {
    const dp = tableData.find((r) => r.Name === name)?.DataPoint;
    return dp === "" ? null : dp ?? null;
  };

  const countEach = parseListOfNumbers(getDataPoint("Count_Each_Extant_Population"));

  const payload = {
    pdf_key: pdfKey,
    species_name: speciesName,
    Extant_Populations: parseNumberOrNull(getDataPoint("Extant_Populations")),
    Extinct_Populations: parseNumberOrNull(getDataPoint("Extinct_Populations")),
    Population_Sizes_Lower_Bound: parseNumberOrNull(getDataPoint("Population_Sizes_Lower_Bound")),
    Population_Sizes_Upper_Bound: parseNumberOrNull(getDataPoint("Population_Sizes_Upper_Bound")),
    Population_Less_Than_5000_Individuals: parseNumberOrNull(getDataPoint("Population_Less_Than_5000_Individuals")),
    Population_More_Than_5000_Individuals: parseNumberOrNull(getDataPoint("Population_More_Than_5000_Individuals")),
    Phylogenetic_Geographic_Studies: parseBoolOrNull(getDataPoint("Phylogenetic_Geographic_Studies")),
    Population_Genetic_Studies: parseBoolOrNull(getDataPoint("Population_Genetic_Studies")),
    DNA_based_Temporal_Monitoring: parseBoolOrNull(getDataPoint("DNA_based_Temporal_Monitoring")),
    Dispersal_Distance: parseNumberOrNull(getDataPoint("Dispersal_Distance")),
    Generation_Time: parseNumberOrNull(getDataPoint("Generation_Time")),
    Count_Each_Extant_Population: countEach,
  };

  return payload;
}