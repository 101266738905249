import React, { useEffect, useRef } from 'react';
import cytoscape from 'cytoscape';

const InteractiveMarbleGraphHeart = ({ numNodes = 2000 }) => {
  const cyRef = useRef(null);

  const generateGraphData = () => {
    const nodes = [];
    const edges = [];
    for (let i = 0; i < numNodes; i++) {
      nodes.push({
        data: { id: `n${i}`, label: `` }
      });
    }
    for (let i = 0; i < numNodes; i++) {
      for (let j = 0; j < 2; j++) {
         let target = Math.floor(Math.random() * numNodes);
         while (target === i) {
           target = Math.floor(Math.random() * numNodes);
         }
         edges.push({
           data: { id: `e${i}_${j}`, source: `n${i}`, target: `n${target}` }
         });
      }
    }
    return { nodes, edges };
  };

  useEffect(() => {
    const { nodes, edges } = generateGraphData();
    const cy = cytoscape({
      container: cyRef.current,
      elements: [
        ...nodes.map(n => ({ data: n.data })),
        ...edges.map(e => ({ data: e.data }))
      ],
      style: [
        {
          selector: 'node',
          style: {
            // Apply marble texture on nodes.
            'background-image': "url('/stone.png')",
            'background-fit': 'cover',
            'background-color': '#ffffff',
            'border-color': '#ddd',
            'border-width': 2,
            shape: 'ellipse',
            label: 'data(label)',
            'font-family': 'serif',
            'font-size': '10px',
            color: '#555',
            'text-valign': 'center',
            'text-halign': 'center',
            width: 60,
            height: 60,
          }
        },
        {
          selector: 'edge',
          style: {
            'line-color': '#aaa',
            width: 1,
            'line-dash-pattern': [4, 2],
            'curve-style': 'bezier',
            'target-arrow-shape': 'none',
            'line-opacity': 0  // edges are invisible by default
          }
        },
        {
          selector: 'edge.active',
          style: {
            'line-opacity': 1,
            'line-color': '#8B0000',
            width: 3,
          }
        }
      ],
      layout: {
        name: 'cose',  // irregular, organic layout
        animate: true,
        animationDuration: 1500,
      },
      wheelSensitivity: 0.2,
      maxZoom: 2,
      minZoom: 0.2,
    });

    // When a node is tapped, animate its connected edges to become visible and colored.
    cy.on('tap', 'node', (evt) => {
      const node = evt.target;
      node.connectedEdges().forEach(edge => {
         edge.addClass('active');
         edge.animate({
           style: { 'line-opacity': 1, 'line-color': '#8B0000', width: 3 }
         }, { duration: 300 });
      });
    });

    // Tapping on the background resets all edges to invisible.
    cy.on('tap', (evt) => {
      if (evt.target === cy) {
         cy.edges().removeClass('active');
         cy.edges().animate({
           style: { 'line-opacity': 0, width: 1 }
         }, { duration: 300 });
      }
    });

    // Heartbeat animation: each node pulsates like a beating heart.
    const heartBeat = () => {
      cy.nodes().forEach(node => {
        node.animate({
          style: { width: 70, height: 70 }
        }, {
          duration: 300,
          complete: () => {
            node.animate({
              style: { width: 60, height: 60 }
            }, { duration: 300 });
          }
        });
      });
    };

    const heartBeatInterval = setInterval(heartBeat, 1000);

    return () => {
      clearInterval(heartBeatInterval);
      cy.destroy();
    };
  }, [numNodes]);

  return (
    <div
      ref={cyRef}
      style={{
        width: '100%',
        height: '800px',
        background: "url('/marble-texture.jpg') center center / cover",
        border: '1px solid #ccc'
      }}
    />
  );
};

export default InteractiveMarbleGraphHeart;