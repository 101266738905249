import React from "react";
import { Card, CardContent } from "../common/card.tsx";

interface SpeciesSummaryCardProps {
  species: {
    image?: string;
    name: string;
    latinName: string;
  };
  groupedFields: { indicator: string; fields: string[] }[];
  computedIndicator1: number | null;
  computedIndicator2: number | null;
  computedIndicator3: number | null;
  exportExcelButton?: React.ReactNode; // New optional prop for the export button
}

export default function SpeciesSummaryCard({
  species,
  groupedFields,
  computedIndicator1,
  computedIndicator2,
  computedIndicator3,
  exportExcelButton,
}: SpeciesSummaryCardProps) {
  return (
    <Card>
      <CardContent className="p-1 sm:p-2">
        <img
          src={species.image}
          alt={species.name}
          className="w-full h-32 sm:h-40 object-cover rounded-lg"
          loading="lazy"
        />
        <h2 className="text-xs sm:text-sm font-bold mt-2">{species.name}</h2>
        <p className="italic text-[8px] sm:text-[10px]">{species.latinName}</p>
        <div className="mt-1 space-y-1">
          {groupedFields.map((group, index) => (
            <div
              key={index}
              className="flex justify-between text-[8px] sm:text-[10px] font-medium"
            >
              <span>{group.indicator}</span>
              <span className="text-green-700">
                {group.indicator === "Ne 500"
                  ? computedIndicator1 != null
                    ? computedIndicator1.toFixed(2)
                    : "-"
                  : group.indicator === "PM"
                  ? computedIndicator2 != null
                    ? computedIndicator2.toFixed(2)
                    : "-"
                  : group.indicator === "DNA Monitoring"
                  ? computedIndicator3 != null
                    ? computedIndicator3.toFixed(2)
                    : "-"
                  : "-"}
              </span>
            </div>
          ))}
        </div>
        {/* Render the export button within the info section if provided */}
        {exportExcelButton && (
          <div className="mt-2 flex justify-end">
            {exportExcelButton}
          </div>
        )}
      </CardContent>
    </Card>
  );
}