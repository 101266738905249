import colors from './theme_colors.js';

const darkNodeTextRule = {
  selector: 'node[isDark = "true"]',
  style: {
    'color': '#ffffff'
  }
};

const baseStylesheet = [
  // Rule to force white text on dark nodes
  darkNodeTextRule,
  {
    // Combined rule for both node and parent selectors with specified text color.
    selector:
      'node[color = "#1d1d22"], node[color = "#45454a"], node[color = "#3b3b40"], node[color = "#2a2d2f"], node[color = "#5C5C62"], ' +
      'node:parent[color = "#1d1d22"], node:parent[color = "#45454a"], node:parent[color = "#3b3b40"], node:parent[color = "#2a2d2f"], node:parent[color = "#5C5C62"]',
    style: { color: '#ffffff' },
  },
  {
    selector: 'node',
    style: {
      'background-color': 'data(color)', // Use the computed node color
      shape: 'data(shape)',
      label: 'data(label)',
      // Disable wrapping so that long labels expand the node width
      'text-wrap': 'none',
      // Removed text-max-width so the label can utilize the node's width
      //'text-max-width': 100,
      'font-size': '14px',
      'text-valign': 'center',
      'text-halign': 'center',
      width: 'label',
      height: 'label',
      padding: '10px',
      'border-color': '#000000',
      'border-width': '2px',
      'border-style': 'solid',
      'z-index': 10,
      cursor: 'pointer',
    },
  },
  {
    selector: 'node:parent',
    style: {
      'background-color': 'data(color)', // Also use computed color here
      shape: 'data(shape)',
      label: 'data(label)',
      'text-valign': 'top',
      'text-halign': 'center',
      'text-wrap': 'none',
      // Removed text-max-width for parent nodes as well
      //'text-max-width': 150,
      'font-size': '16px',
      padding: '20px',
      'border-color': '#000000',
      'border-width': '1px',
      'border-style': 'solid',
      'z-index': 10,
      cursor: 'pointer',
    },
  },
  {
    selector: 'edge',
    style: {
      'line-color': 'data(color)',
      'curve-style': 'taxi',
      'taxi-turn-min-distance': 10,
      width: 1,
      'target-arrow-shape': 'data(arrowShape)',
      'target-arrow-color': 'data(color)',
      label: 'data(label)',
      'font-size': '12px',
      'text-rotation': 'autorotate',
      'text-margin-y': '-10px',
      'z-index': 10,
      cursor: 'pointer',
    },
  },
  {
    selector: '.cluster',
    style: {
      'background-color': 'data(color)',
      shape: 'data(shape)',
      label: 'data(label)',
      'text-valign': 'top',
      'text-halign': 'center',
      'font-size': '16px',
      'z-index': 10,
      cursor: 'pointer',
    },
  },
  {
    selector: '.highlighted',
    style: {
      'border-color': colors.graphBorderHighlight,
      'border-width': 4,
      'background-color': colors.graphHighlight,
      width: 100,
      height: 100,
      'font-size': '16px',
    },
  },
];

const colorlessOverrides = [
  {
    // For nodes, use the computed data(color) regardless of isRelationshipEntity flag.
    selector: 'node',
    style: {
      'background-color': 'data(color)',
      'background-fit': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center',
      shape: 'round-rectangle',
      'border-width': '1px',
      'border-color': '#1d1d22',
      'shadow-blur': '3px',
      'shadow-color': '#000',
      'shadow-offset-x': '2px',
      'shadow-offset-y': '2px',
      'shadow-opacity': 0.2,
      label: 'data(label)',
      'text-valign': 'center',
      'text-halign': 'center',
      'font-family': 'serif',
      width: 'data(nodeWidth)',
      height: 'data(nodeHeight)',
    },
  },
  {
    selector: 'edge[relationshipType = "Type"]',
    style: {
      visibility: 'hidden',
      'pointer-events': 'none',
    },
  },
  {
    selector: 'edge[relationshipType = "Defined"]',
    style: {
      'line-color': "#000000",
      'target-arrow-color': "#000000",
      'curve-style': 'bezier',
      'line-style': 'solid',
      'font-size': '10px',
      'font-family': 'serif',
      'text-margin-y': '-10px',
    },
  },
  {
    selector: 'edge[relationshipType = "DefinitionMapping"]',
    style: {
      'line-color': "#000000",
      'target-arrow-color': "#000000",
      'curve-style': 'bezier',
      'line-style': 'dotted',
      'font-size': '10px',
      'font-family': 'serif',
      'text-margin-y': '-10px',
    },
  },
];

export const createLocalPrimeKGStylesheet = () => [...baseStylesheet, ...colorlessOverrides];
export const createColorlessStylesheet = () => [...baseStylesheet, ...colorlessOverrides];