import { groupOntologyEdgesByPivot } from './export_ontology_to_excel/groupOntologyEdges';
import { exportArrayToExcel } from './export_ontology_to_excel/excelExport';
import { getEntityNameFromMap, getRelEntityName } from './export_ontology_to_excel/relationshipUtils';

export const exportOntologyToExcel = (rawData, config) => {
  const cfg = config ;
  if (
    !rawData ||
    !rawData.Entities ||
    !rawData.Entities.Entities ||
    !rawData.Relationships
  ) {
    alert("No data available to export.");
    return;
  }
  const entityMap = {};
  rawData.Entities.Entities.forEach(entity => {
    entityMap[entity.ID] = entity;
  });
  const getEntityName = (id) => getEntityNameFromMap(id, entityMap);
  let relEdges = [];
  if (
    rawData.Relationships.AttributeRelationships &&
    rawData.Relationships.AttributeRelationships.length > 0
  ) {
    relEdges = rawData.Relationships.AttributeRelationships;
  } else if (
    rawData.Relationships.DefinitionMapping &&
    rawData.Relationships.DefinitionMapping.length > 0
  ) {
    relEdges = rawData.Relationships.DefinitionMapping;
  }

  // Look for YC-style relationships.
  const founderEdges = relEdges.filter(edge => {
    const label = getRelEntityName(edge, rawData);
    return label.toLowerCase().includes(cfg.founderKeyword.toLowerCase());
  });
  const foundingYearEdges = relEdges.filter(edge => {
    const label = getRelEntityName(edge, rawData);
    return label.toLowerCase().includes(cfg.yearKeyword.toLowerCase());
  });

  if (founderEdges.length > 0 && foundingYearEdges.length > 0) {
    const startupToYear = {};
    foundingYearEdges.forEach(edge => {
      if (!startupToYear[edge.SourceNodeID]) {
        startupToYear[edge.SourceNodeID] = getEntityName(edge.TargetNodeID);
      }
    });
    const rows = founderEdges.map(edge => {
      const founderName = getEntityName(edge.SourceNodeID);
      const startupName = getEntityName(edge.TargetNodeID);
      const foundingYear = startupToYear[edge.TargetNodeID] || "";
      return [founderName, startupName, foundingYear];
    });
    const header = ["YC Founder", "YC Startup", "Founding Year of Startup"];
    const dataArray = [header, ...rows];
    exportArrayToExcel(dataArray, "curated_ontology.xlsx");
  } else {
    const grouped = groupOntologyEdgesByPivot(relEdges, rawData, cfg);
    const header = ["Pivot Entity", "Relationship Definition", "Related Entities"];
    const sheetData = [header];
    Object.keys(grouped).forEach(pivot => {
      const group = grouped[pivot];
      Object.keys(group).forEach(relLabel => {
        const relatedNames = group[relLabel]
          .map(id => getEntityName(id))
          .join(", ");
        const pivotName = getEntityName(pivot) || pivot;
        sheetData.push([pivotName, relLabel, relatedNames]);
      });
    });
    exportArrayToExcel(sheetData, "curated_ontology.xlsx");
  }
};