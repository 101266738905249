import React from "react";
import { FileText } from "lucide-react";

export default function CountEachExtantPopulation({ pdfExtractions }) {
  return (
    <div className="p-1 sm:p-2 border rounded-lg space-y-2 bg-white">
      <table className="table-fixed w-full text-[10px] sm:text-[11px]">
        <tbody>
          {pdfExtractions.map((pdf, pdfIndex) => {
            const counts = pdf.fields.Count_Each_Extant_Population;
            if (!counts || counts.length === 0) {
              return null;
            }
            return (
              <tr key={pdfIndex}>
                <td className="whitespace-nowrap font-medium text-left pr-1 align-middle p-0.5">
                  <div className="relative group inline-flex items-center justify-start cursor-pointer">
                    <FileText className="h-3 w-3 text-gray-600" />
                    <span
                      className="absolute bottom-full mb-1 hidden whitespace-nowrap rounded bg-black px-1 py-0.5 text-[8px] text-white group-hover:block transition-opacity duration-200 ease-in-out"
                      role="tooltip"
                    >
                      {pdf.pdfName}
                    </span>
                  </div>
                </td>
                {counts.map((count, countIndex) => (
                  <td
                    key={countIndex}
                    className="text-center whitespace-nowrap align-middle p-0.5"
                  >
                    {count !== undefined ? count : "-"}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="text-center text-xs font-semibold whitespace-nowrap">
        Number of individuals in each population
      </div>
    </div>
  );
}