import React from "react";
import { Card, CardContent } from "../common/card.tsx";
import IndicatorsSection from "./IndicatorsSection.tsx";
import CountEachExtantPopulation from "./CountEachExtantPopulation.tsx";

export default function SpeciesIndicators({
  pdfExtractions,
  fieldLabels,
  computedIndicator1,
  computedIndicator2,
  computedIndicator3,
  parseBool,
  onDeleteExtraction,
}) {
  // Helper to format field values as "Yes"/"No" or numeric
  const formatValue = (field, value) => {
    if (value === null) return "-";
    const booleanFields = [
      "Phylogenetic_Geographic_Studies",
      "Population_Genetic_Studies",
      "DNA_based_Temporal_Monitoring",
    ];
    if (booleanFields.includes(field)) {
      return parseBool(value) ? "Yes" : "No";
    }
    return value.toString();
  };

  // Define your "sections" array for each indicator group.
  // Note: The "Övrigt" section now includes Population Sizes (Lower & Upper Bound)
  const sections = [
    {
      label: "Ne 500",
      fields: ["Extant_Populations", "Extinct_Populations"],
      indicatorValue: computedIndicator1,
    },
    {
      label: "Proportion of Population Maintained",
      fields: [
        "Population_Less_Than_5000_Individuals",
        "Population_More_Than_5000_Individuals",
      ],
      indicatorValue: computedIndicator2,
    },
    {
      label: "DNA Based Monitoring",
      fields: [
        "Phylogenetic_Geographic_Studies",
        "Population_Genetic_Studies",
        "DNA_based_Temporal_Monitoring",
      ],
      indicatorValue: computedIndicator3,
    },
    {
      label: "Övrigt",
      fields: [
        "Generation_Time",
        "Dispersal_Distance",
        "Population_Sizes_Lower_Bound",
        "Population_Sizes_Upper_Bound",
      ],
      indicatorValue: null, // no computed indicator value
    },
  ];

  return (
    <Card className="lg:col-span-2 bg-transparent border-0 shadow-none">
      <CardContent className="p-1 sm:p-2 space-y-3">
        {/* Render each indicator section */}
        {sections.map((section, idx) => (
          <IndicatorsSection
            key={idx}
            sectionIndex={idx}
            label={section.label}
            fields={section.fields}
            indicatorValue={section.indicatorValue}
            pdfExtractions={pdfExtractions}
            fieldLabels={fieldLabels}
            formatValue={formatValue}
            onDeleteExtraction={onDeleteExtraction}
          />
        ))}

        <CountEachExtantPopulation pdfExtractions={pdfExtractions} />
      </CardContent>
    </Card>
  );
}