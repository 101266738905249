// src/utils/graph_gen/ontology/graphHandlers.js

// Helper function to collect all existing IDs from the ontology object
const getAllIds = (data) => {
  const idArrays = [
    data.Entities.Entities,
    data.Entities.PrimitiveDataTypeEntities,
    data.Relationships.TypeRelationships,
    data.Relationships.AttributeRelationships,
    data.Relationships.EquivalenceRelationships,
    data.Decompositions.TypeDecompositions,
    data.Decompositions.AttributeFunctionalDecompositions,
    data.Decompositions.AttributeAggregationDecompositions,
    data.RelationshipEntities.AttributeRelationshipEntities,
    data.RelationshipEntities.StructuralRelationshipEntities,
  ];
  let allIds = [];
  idArrays.forEach(arr => {
    if (Array.isArray(arr)) {
      arr.forEach(item => {
        if (typeof item.ID === 'number') {
          allIds.push(item.ID);
        }
      });
    }
  });
  return allIds;
};

// Default ontology object with a globalIdCounter property.
// This counter is initialized to 2 so that new items start with ID 2.
const defaultOntology = {
  globalIdCounter: 2,
  Entities: {
    Entities: [],
    PrimitiveDataTypeEntities: [],
  },
  Relationships: {
    TypeRelationships: [],
    AttributeRelationships: [],
    EquivalenceRelationships: [],
  },
  Decompositions: {
    TypeDecompositions: [],
    AttributeFunctionalDecompositions: [],
    AttributeAggregationDecompositions: [],
  },
  RelationshipEntities: {
    AttributeRelationshipEntities: [],
    StructuralRelationshipEntities: [],
  },
};

// Updated add node handler using the globalIdCounter.
export const addNodeHandlerManual = (nodeData, updateGraphData) => {
  updateGraphData((prevData) => {
    if (!prevData) {
      prevData = { ...defaultOntology };
    }
    // Ensure globalIdCounter exists; if not, compute it from existing IDs.
    if (!prevData.globalIdCounter) {
      const allIds = getAllIds(prevData);
      prevData.globalIdCounter = allIds.length > 0 ? Math.max(...allIds) + 1 : 2;
    }
    const newID = prevData.globalIdCounter;
    prevData.globalIdCounter++;

    const newEntity = {
      ID: newID,
      Name: nodeData.Name,
      Definition: nodeData.Definition,
      references: [],
      baseColor: nodeData.baseColor,
    };
    return {
      ...prevData,
      Entities: {
        ...prevData.Entities,
        Entities: [...prevData.Entities.Entities, newEntity],
      },
    };
  });
};

// Updated add relationship entity handler using the globalIdCounter.
export const addRelationshipEntityHandlerManual = (entity, updateGraphData) => {
  updateGraphData((prevData) => {
    if (!prevData) {
      prevData = { ...defaultOntology };
    }
    // Ensure globalIdCounter exists; if not, compute it from existing IDs.
    if (!prevData.globalIdCounter) {
      const allIds = getAllIds(prevData);
      prevData.globalIdCounter = allIds.length > 0 ? Math.max(...allIds) + 1 : 2;
    }
    const newID = prevData.globalIdCounter;
    prevData.globalIdCounter++;

    if (entity.type === 'attribute') {
      const newRelEntity = {
        ID: newID,
        Name: entity.Name,
        Definition: entity.Definition,
        references: [],
        SourceNodeTypeID: entity.SourceNodeTypeID,
        TargetNodeTypeID: entity.TargetNodeTypeID,
      };
      return {
        ...prevData,
        RelationshipEntities: {
          ...prevData.RelationshipEntities,
          AttributeRelationshipEntities: [
            ...prevData.RelationshipEntities.AttributeRelationshipEntities,
            newRelEntity,
          ],
        },
      };
    } else {
      const newRelEntity = {
        ID: newID,
        Name: entity.Name,
        Definition: entity.Definition,
        references: [],
        Transitive: entity.Transitive,
        Bidirectional: entity.Bidirectional,
      };
      return {
        ...prevData,
        RelationshipEntities: {
          ...prevData.RelationshipEntities,
          StructuralRelationshipEntities: [
            ...prevData.RelationshipEntities.StructuralRelationshipEntities,
            newRelEntity,
          ],
        },
      };
    }
  });
};


// Add this new handler in graphHandlersManual.js

export const addTypeRelationshipHandlerManual = (relationshipData, updateGraphData) => {
  updateGraphData((prevData) => {
    if (!prevData) {
      prevData = { ...defaultOntology };
    }
    // Ensure globalIdCounter exists; if not, compute it.
    if (!prevData.globalIdCounter) {
      const allIds = getAllIds(prevData);
      prevData.globalIdCounter = allIds.length > 0 ? Math.max(...allIds) + 1 : 2;
    }
    const newID = prevData.globalIdCounter;
    prevData.globalIdCounter++;

    // Create a new type relationship.
    const newTypeRelationship = {
      ID: newID,
      SourceNodeID: parseInt(relationshipData.sourceNodeId, 10),
      TargetNodeID: parseInt(relationshipData.targetNodeId, 10),
      Motivation: relationshipData.motivation || "Is A", // default label
    };

    return {
      ...prevData,
      Relationships: {
        ...prevData.Relationships,
        TypeRelationships: [
          ...prevData.Relationships.TypeRelationships,
          newTypeRelationship,
        ],
      },
    };
  });
};

export const addAttributeRelationshipInstanceHandlerManual = (relationshipData, updateGraphData) => {
  updateGraphData((prevData) => {
    if (!prevData) {
      prevData = { ...defaultOntology };
    }
    // Make sure the globalIdCounter exists
    if (!prevData.globalIdCounter) {
      const allIds = getAllIds(prevData);
      prevData.globalIdCounter = allIds.length > 0 ? Math.max(...allIds) + 1 : 2;
    }
    const newID = prevData.globalIdCounter;
    prevData.globalIdCounter++;

    // Create the new attribute relationship instance.
    // Note: We use "EntityID" to link to the relationship definition.
    const newAttributeRelationship = {
      ID: newID,
      SourceNodeID: parseInt(relationshipData.sourceNodeId, 10),
      TargetNodeID: parseInt(relationshipData.targetNodeId, 10),
      Motivation: relationshipData.motivation || "",
      EntityID: parseInt(relationshipData.entityId, 10), // selected relationship definition ID
    };

    return {
      ...prevData,
      Relationships: {
        ...prevData.Relationships,
        AttributeRelationships: [
          ...prevData.Relationships.AttributeRelationships,
          newAttributeRelationship,
        ],
      },
    };
  });
};