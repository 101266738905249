// src/organizations/biodiversity/handlers/fileUploadHandlers.ts
import { toast } from "sonner";

/**
 * Returns a handler for the dragEnter event.
 */
export function createHandleDragEnter(
  setIsDragging: (isDragging: boolean) => void
) {
  return (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };
}

/**
 * Returns a handler for the dragOver event.
 */
export function createHandleDragOver(
  setIsDragging: (isDragging: boolean) => void
) {
  return (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };
}

/**
 * Returns a handler for the dragLeave event.
 */
export function createHandleDragLeave(
  setIsDragging: (isDragging: boolean) => void
) {
  return (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };
}

/**
 * Returns a handler for the drop event.
 */
export function createHandleDrop(
  files: File[],
  onFileSelect: (files: File[]) => void,
  setIsDragging: (isDragging: boolean) => void
) {
  return (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files).filter(
      (file) => file.type === "application/pdf"
    );
    if (droppedFiles.length > 0) {
      const updatedFiles = [...files, ...droppedFiles];
      onFileSelect(updatedFiles);
      toast.success(`${droppedFiles.length} PDF file(s) selected successfully`);
    } else {
      toast.error("Please upload PDF file(s)");
    }
  };
}

/**
 * Returns a handler for the file input change event.
 */
export function createHandleFileSelect(
  files: File[],
  onFileSelect: (files: File[]) => void
) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e.target.files
      ? Array.from(e.target.files).filter(
          (file) => file.type === "application/pdf"
        )
      : [];
    if (selected.length > 0) {
      const updatedFiles = [...files, ...selected];
      onFileSelect(updatedFiles);
      toast.success(`${selected.length} PDF file(s) selected successfully`);
    } else {
      toast.error("Please upload PDF file(s)");
    }
    e.target.value = "";
  };
}