import { useState } from "react"
import { Card } from "../../common/card.tsx"
import { Input } from "../common/input.tsx"
import { Button } from "../../common/button.tsx"
import { ImageSearch } from "./ImageSearch.tsx"
import { toast } from "sonner"
import { createSpecies } from "../../../services/api.ts"
import { Check } from "lucide-react"

export function AddSpeciesForm() {
  const [name, setName] = useState("")
  const [latinName, setLatinName] = useState("")
  const [selectedImage, setSelectedImage] = useState("")

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await createSpecies({
        name,
        latinName,
        imageKey: selectedImage,
      })
      toast.success("Species created successfully in the database!")
      setName("")
      setLatinName("")
      setSelectedImage("")
    } catch (err: any) {
      toast.error("Error creating species: " + err.message)
      console.error("Error creating species:", err)
    }
  }

  return (
    <Card className="p-6 mb-6">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1 font-semibold">Name</label>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="block mb-1 font-semibold">Latin Name</label>
          <Input
            value={latinName}
            onChange={(e) => setLatinName(e.target.value)}
            required
          />
        </div>
        {name && (
          <div className="mt-4">
            <ImageSearch
              speciesName={name}
              onImageSelect={(imgUrl) => setSelectedImage(imgUrl)}
            />
            {selectedImage && (
              <div className="mt-2 text-sm">
                <strong>Selected Image:</strong>
                <div className="mt-1">
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="w-32 h-32 object-cover border"
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="flex justify-end">
          <Button
            type="submit"
            variant="default"
            className="mt-4 flex items-center bg-transparent border-none text-success hover:text-successDark disabled:opacity-50 focus:outline-none focus:ring-0"
            title="Confirm New Species"
          >
            <Check style={{ width: "30px", height: "30px" }} />
          </Button>
        </div>
      </form>
    </Card>
  )
}