
export const getEntityNameFromMap = (id, entityMap) => {
    return entityMap[id] ? entityMap[id].Name : "";
  };
  

  export const getRelEntityName = (rel, rawData) => {
    if (rel.EntityID && rawData.RelationshipEntities && rawData.RelationshipEntities.AttributeRelationshipEntities) {
      const relDef = rawData.RelationshipEntities.AttributeRelationshipEntities.find(e => e.ID === rel.EntityID);
      if (relDef) return relDef.Name.trim();
    }
    return (rel.Name || rel.relationshipEntityName || "").trim();
  };