// src/core/helpers/graph_gen/common/knowledge_graph/prepareNewGraphElements.js
import { prepareNodes } from './prepare_new_graph_elements/prepareNodes';
import { prepareTypeEdges } from './prepare_new_graph_elements/prepareTypeEdges';
import { prepareAttributeEdges } from './prepare_new_graph_elements/prepareAttributeEdges';
import { prepareDefinitionMappingEdges } from './prepare_new_graph_elements/prepareDefinitionMappingEdges';

export const prepareNewGraphElements = (data, colorMode = "colorful") => {
  console.log("[PMNE] Starting preparation of new graph elements...");

  const nodes = prepareNodes(data);
  const typeEdges = prepareTypeEdges(data);
  const attributeEdges = prepareAttributeEdges(data);
  const defMappingEdges = prepareDefinitionMappingEdges(data);

  console.log(
    `[PMNE] Prepared ${nodes.length} nodes, ${typeEdges.length} type edges, ` +
    `${attributeEdges.length} attribute edges, and ${defMappingEdges.length} definition mapping edges.`
  );

  // Process one-to-one attribute relationships.
  const nonTypeEdges = [...attributeEdges, ...defMappingEdges];
  nonTypeEdges.forEach((edge) => {
    const isOneToOne =
      edge.data.oneToOne ||
      (edge.data.PluralityType && edge.data.PluralityType.toLowerCase() === "onetoone");
    if (isOneToOne) {
      const targetId = edge.data.target;
      const targetNode = nodes.find((n) => n.data.id === targetId);
      if (targetNode) {
        targetNode.data.HardColumns = true;
        console.log(
          `[PMNE] Marked node ${targetId} as HardColumns because of one-to-one relationship.`
        );
      }
    }
  });

  // --- Dynamic color assignment based on relationship counts ---
  if (colorMode !== "grey") {
    const allEdgesForCount = [...typeEdges, ...attributeEdges, ...defMappingEdges];
    const relationshipTypeCounts = {};

    // Initialize counts for every node.
    nodes.forEach((node) => {
      relationshipTypeCounts[node.data.id] = {
        type: 0,
        attribute: 0,
        definitionMapping: 0,
      };
    });

    allEdgesForCount.forEach((edge) => {
      const relType = edge.data.relationshipType;
      if (edge.data.source && relationshipTypeCounts[edge.data.source]) {
        if (relType === "Type") {
          relationshipTypeCounts[edge.data.source].type++;
        } else if (relType === "Defined") {
          relationshipTypeCounts[edge.data.source].attribute++;
        } else if (relType === "DefinitionMapping") {
          relationshipTypeCounts[edge.data.source].definitionMapping++;
        }
      }
      if (edge.data.target && relationshipTypeCounts[edge.data.target]) {
        if (relType === "Type") {
          relationshipTypeCounts[edge.data.target].type++;
        } else if (relType === "Defined") {
          relationshipTypeCounts[edge.data.target].attribute++;
        } else if (relType === "DefinitionMapping") {
          relationshipTypeCounts[edge.data.target].definitionMapping++;
        }
      }
    });

    nodes.forEach((node) => {
      const counts = relationshipTypeCounts[node.data.id];
      const typesParticipated = [];
      if (counts.type > 0) typesParticipated.push("type");
      if (counts.attribute > 0) typesParticipated.push("attribute");
      if (counts.definitionMapping > 0) typesParticipated.push("definitionMapping");

      if (typesParticipated.length === 1) {
        switch (typesParticipated[0]) {
          case "type":
            node.data.color = "#ff7f0e"; // Orange for Type relationships.
            break;
          case "attribute":
            node.data.color = "#2ca02c"; // Green for Attribute relationships.
            break;
          case "definitionMapping":
            node.data.color = node.data.originalColor || "#ffffff";
            break;
          default:
            node.data.color = node.data.originalColor || "#ffffff";
        }
      } else if (typesParticipated.length > 1) {
        node.data.color = "#9467bd"; // Purple for multiple relationship types.
      } else {
        node.data.color = node.data.originalColor || "#ffffff";
      }
    });
  } else {
    // If colorMode is "grey", revert each node's color to its computed original grey.
    nodes.forEach(node => {
      node.data.color = node.data.originalColor || "#ffffff";
    });
  }

  const elements = [
    ...nodes,
    ...typeEdges,
    ...attributeEdges,
    ...defMappingEdges,
  ];
  return elements;
};