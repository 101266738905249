import { useState } from "react"
import { toast } from "sonner"

export function useExtractionProcess() {
  const [isExtracting, setIsExtracting] = useState(false)
  const [streamStatus, setStreamStatus] = useState("")
  const [extractedData, setExtractedData] = useState(null)

  async function handleDirectS3Upload(file: File): Promise<string> {
    const filename = encodeURIComponent(file.name)
    const presignedUrl = `https://atlaz-api.com/species/api/generate-presigned-url?filename=${filename}`
   // console.log("[useExtractionProcess] Requesting presigned URL from:", presignedUrl)

    const presignedResp = await fetch(presignedUrl)
    if (!presignedResp.ok) {
      const errorText = await presignedResp.text()
      console.error("[useExtractionProcess] Failed to get presigned URL. Response text:", errorText)
      throw new Error("Failed to get presigned URL")
    }
    const { url, fields, key } = await presignedResp.json()
   // console.log("[useExtractionProcess] Presigned JSON:", { url, fields, key })

    const formData = new FormData()
    Object.entries(fields).forEach(([fieldName, fieldValue]) => {
      formData.append(fieldName, fieldValue as string)
    })
    formData.append("file", file)

   // console.log("[useExtractionProcess] Uploading file to S3 presigned URL...")
    const uploadRes = await fetch(url, {
      method: "POST",
      body: formData,
    })
    if (!uploadRes.ok) {
      const errorText = await uploadRes.text()
      console.error("[useExtractionProcess] S3 upload failed. Response text:", errorText)
      throw new Error("S3 upload failed with status " + uploadRes.status)
    }
   // console.log("[useExtractionProcess] S3 upload successful. Returning key:", key)
    return key
  }

  async function handleExtractData(file: File, speciesName: string) {
    if (!file) {
      toast.error("No PDF file selected!")
      return
    }
    if (!speciesName) {
      toast.error("No species selected!")
      return
    }
    setIsExtracting(true)
    setStreamStatus("Starting extraction...")

    try {
      const pdfKey = await handleDirectS3Upload(file)

      const extractionForm = new FormData()
      extractionForm.append("pdf_key", pdfKey)
      extractionForm.append("species_name", speciesName)

     // console.log("[useExtractionProcess] POST /species/api/stream-extract-pdf-with-status with:")
      extractionForm.forEach((val, key) => console.log("   ", key, "=", val))

      const response = await fetch(
        "https://atlaz-api.com/species/api/stream-extract-pdf-with-status",
        {
          method: "POST",
          body: extractionForm,
        }
      )
      //console.log("[useExtractionProcess] Extraction response:", response.status, response.statusText)
      if (!response.ok) {
        const errText = await response.text()
        console.error("[useExtractionProcess] Extraction error text:", errText)
        throw new Error(`HTTP ${response.status} - ${response.statusText}`)
      }

      const reader = response.body?.getReader()
      if (!reader) {
        throw new Error("No body returned or ReadableStream not supported.")
      }

      const decoder = new TextDecoder()
      let buffer = ""

      while (true) {
        const { value, done } = await reader.read()
        if (done) break
        const textChunk = decoder.decode(value, { stream: true })
        buffer += textChunk
        const lines = buffer.split("\n")
        buffer = lines.pop() || ""
        for (const line of lines) {
          const trimmed = line.trim()
          if (!trimmed) continue
          try {
            const chunk = JSON.parse(trimmed)
            if (chunk.status === "still_processing") {
              setStreamStatus("Extraction still in progress...")
            } else if (chunk.status === "error") {
              toast.error(chunk.detail || "Server error during extraction.")
              console.error("[useExtractionProcess] chunk.error detail:", chunk.detail)
            } else if (chunk.status === "completed" || chunk.data) {
              setExtractedData(chunk)
            }
          } catch (parseErr) {
            console.warn("[useExtractionProcess] JSON parse error for line:", trimmed, parseErr)
          }
        }
      }
    } catch (err: any) {
      console.error("[useExtractionProcess] Extraction failed:", err)
      toast.error(err.message || "Extraction failed.")
    } finally {
      setIsExtracting(false)
    }
  }

  function handleReset() {
    setExtractedData(null)
    setStreamStatus("")
  }

  return { 
    isExtracting, 
    streamStatus, 
    extractedData, 
    handleExtractData, 
    handleReset, 
    setExtractedData,
    handleDirectS3Upload  // <-- Return the function so it can be used by the component.
  }
}