// src/components/graph/CurveView.jsx
import React, { useMemo } from 'react';
import KnowledgeGraphOld from '../../containers/legacy/KnowledgeGraphOld';
import { createGraphData } from '../../helpers/legacy/graphData';

const CurveView = ({ graphData, height = 600, onElementSelect, highlightNodes = [], layoutType = 'fcose' }) => {
  const processedGraphData = useMemo(() => createGraphData(graphData), [graphData]);

  return (
    <div style={{ width: '100%', height: `${height}px` }}>
      <KnowledgeGraphOld
        data={processedGraphData}
        height={height}
        onElementSelect={onElementSelect}
        highlightNodes={highlightNodes}
        layoutType={layoutType}
      />
    </div>
  );
};

export default CurveView;