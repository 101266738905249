
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
    export function highlightMultipleMatches2Species(html, queries) {
      if (!Array.isArray(queries)) return html;
      
      let highlightedHtml = html;
      queries.forEach((query) => {
        if (typeof query !== 'string') {
          console.warn("Skipping query because it's not a string:", query);
          return;
        }
        
        const trimmedQuery = query.trim();
        if (!trimmedQuery) {
          console.warn("Skipping query because it's empty:", query);
          return;
        }
        
        try {
          const regex = new RegExp(escapeRegExp(trimmedQuery), 'gi');
          highlightedHtml = highlightedHtml.replace(regex, (match) => `<mark>${match}</mark>`);
        } catch (error) {
          console.error("Error creating regex for query:", query, error);
        }
      });
      
      return highlightedHtml;
    }