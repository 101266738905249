// src/utils/modeModifiers/OntologyDataModifier.js

// Helper function to remove transitive "Is A" edges.
function removeTransitiveEdges(edges) {
    // Build an adjacency list from the edges.
    const adj = {};
    edges.forEach((edge) => {
      const { SourceNodeID, TargetNodeID } = edge;
      if (!adj[SourceNodeID]) adj[SourceNodeID] = [];
      adj[SourceNodeID].push(TargetNodeID);
    });
  
    // Returns true if there exists an alternate path (length ≥ 2) from source to target.
    const hasAlternatePath = (source, target) => {
      const visited = new Set();
      const stack = [];
      if (adj[source]) {
        for (const neighbor of adj[source]) {
          if (neighbor !== target) stack.push(neighbor);
        }
      }
      while (stack.length > 0) {
        const node = stack.pop();
        if (node === target) return true;
        if (!visited.has(node)) {
          visited.add(node);
          if (adj[node]) {
            for (const nb of adj[node]) {
              stack.push(nb);
            }
          }
        }
      }
      return false;
    };
  
    return edges.filter((edge) => {
      const { SourceNodeID, TargetNodeID } = edge;
      return !hasAlternatePath(SourceNodeID, TargetNodeID);
    });
  }
  
  export default function modifyDataOntology(rawData) {
    const dataCopy = JSON.parse(JSON.stringify(rawData));
  
    // 1) Remove entity with ID 1
    dataCopy.Entities.Entities = dataCopy.Entities.Entities.filter(
      (entity) => entity.ID !== 1
    );
  
    // 2) Remove edges referencing entity 1
    if (dataCopy.Relationships) {
      if (Array.isArray(dataCopy.Relationships.TypeRelationships)) {
        dataCopy.Relationships.TypeRelationships =
          dataCopy.Relationships.TypeRelationships.filter(
            (rel) => rel.SourceNodeID !== 1 && rel.TargetNodeID !== 1
          );
      }
      if (Array.isArray(dataCopy.Relationships.AttributeRelationships)) {
        dataCopy.Relationships.AttributeRelationships =
          dataCopy.Relationships.AttributeRelationships.filter(
            (rel) => rel.SourceNodeID !== 1 && rel.TargetNodeID !== 1
          );
      }
      if (Array.isArray(dataCopy.Relationships.DefinitionMapping)) {
        dataCopy.Relationships.DefinitionMapping =
          dataCopy.Relationships.DefinitionMapping.filter(
            (rel) => rel.SourceNodeID !== 1 && rel.TargetNodeID !== 1
          );
      }
    }
  
    // Remove direct AttributeRelationships so only TypeRelationships + DefinitionMapping remain.
    if (dataCopy.Relationships) {
      dataCopy.Relationships.AttributeRelationships = [];
      if (Array.isArray(dataCopy.Relationships.TypeRelationships)) {
        dataCopy.Relationships.TypeRelationships = removeTransitiveEdges(
          dataCopy.Relationships.TypeRelationships
        );
      }
    }
  
    // 3) Determine "OK" nodes based on RelationshipEntities & TypeRelationships.
    const ok = new Set();
    if (dataCopy.RelationshipEntities) {
      if (
        Array.isArray(dataCopy.RelationshipEntities.AttributeRelationshipEntities)
      ) {
        dataCopy.RelationshipEntities.AttributeRelationshipEntities.forEach(
          (relEntity) => {
            if (
              relEntity.SourceNodeTypeID !== undefined &&
              relEntity.TargetNodeTypeID !== undefined
            ) {
              ok.add(`${relEntity.SourceNodeTypeID}`);
              ok.add(`${relEntity.TargetNodeTypeID}`);
            }
          }
        );
      }
      if (
        Array.isArray(
          dataCopy.RelationshipEntities.StructuralRelationshipEntities
        )
      ) {
        dataCopy.RelationshipEntities.StructuralRelationshipEntities.forEach(
          (relEntity) => {
            if (
              relEntity.SourceNodeTypeID !== undefined &&
              relEntity.TargetNodeTypeID !== undefined
            ) {
              ok.add(`${relEntity.SourceNodeTypeID}`);
              ok.add(`${relEntity.TargetNodeTypeID}`);
            }
          }
        );
      }
    }
  
    // 4) Propagate OK status along TypeRelationships ("Is A").
    if (
      dataCopy.Relationships &&
      Array.isArray(dataCopy.Relationships.TypeRelationships)
    ) {
      let added = true;
      while (added) {
        added = false;
        dataCopy.Relationships.TypeRelationships.forEach((rel) => {
          const sourceId = `${rel.SourceNodeID}`;
          const targetId = `${rel.TargetNodeID}`;
          if (ok.has(sourceId) && !ok.has(targetId)) {
            ok.add(targetId);
            added = true;
          }
        });
      }
    }
  
    // 5) Keep only Entities that appear in `ok`.
    dataCopy.Entities.Entities = dataCopy.Entities.Entities.filter((entity) =>
      ok.has(`${entity.ID}`)
    );
  
    // 6) Filter TypeRelationships to only include those with both endpoints in `ok`.
    if (
      dataCopy.Relationships &&
      Array.isArray(dataCopy.Relationships.TypeRelationships)
    ) {
      dataCopy.Relationships.TypeRelationships =
        dataCopy.Relationships.TypeRelationships.filter(
          (rel) =>
            ok.has(`${rel.SourceNodeID}`) &&
            ok.has(`${rel.TargetNodeID}`)
        );
    }
  
    return dataCopy;
  }