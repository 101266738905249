// src/core/handlers/graph_gen/build_brain/merge/remapRelationshipEntities.js
export function remapRelationshipEntities(merged, ontologyIdMap, extractionIdMap) {
  // Remap SourceNodeTypeID and TargetNodeTypeID for relationship entities.
  merged.RelationshipEntities.AttributeRelationshipEntities =
    merged.RelationshipEntities.AttributeRelationshipEntities.map(defEntity => {
      let finalSource = defEntity.SourceNodeTypeID;
      let finalTarget = defEntity.TargetNodeTypeID;

      // Normalize keys for ontologyIdMap: convert numeric strings to numbers.
      const sourceKey = isNaN(finalSource) ? finalSource : Number(finalSource);
      const targetKey = isNaN(finalTarget) ? finalTarget : Number(finalTarget);

      if (ontologyIdMap.has(sourceKey)) {
        finalSource = ontologyIdMap.get(sourceKey);
      } else if (extractionIdMap.has(String(finalSource))) {
        finalSource = extractionIdMap.get(String(finalSource));
      }
      if (ontologyIdMap.has(targetKey)) {
        finalTarget = ontologyIdMap.get(targetKey);
      } else if (extractionIdMap.has(String(finalTarget))) {
        finalTarget = extractionIdMap.get(String(finalTarget));
      }

      return {
        ...defEntity,
        SourceNodeTypeID: finalSource,
        TargetNodeTypeID: finalTarget
      };
    });

  // Promote relationship entities as definition mapping edges.
  const promotedEdges = merged.RelationshipEntities.AttributeRelationshipEntities.map(relEnt => {
    const remappedSource = relEnt.SourceNodeTypeID;
    const remappedTarget = relEnt.TargetNodeTypeID;
    return {
      id: `edge_relent_${String(relEnt.ID)}`,
      source: remappedSource,
      target: remappedTarget,
      label: relEnt.Name,
      relationshipType: 'DefinitionMapping'
    };
  });

  // Combine with original DefinitionMapping edges.
  const combinedDefinitionMappingEdges = [
    ...merged.Relationships.DefinitionMapping.map(edge => ({
      id: edge.ID,
      source: edge.SourceNodeID,
      target: edge.TargetNodeID,
      label: edge.label,
      relationshipType: edge.relationshipType
    })),
    ...promotedEdges
  ];

  // Deduplicate edges.
  const uniqueDefinitionMappingEdges = [];
  const seenEdgeKeys = new Set();
  combinedDefinitionMappingEdges.forEach(edge => {
    const key = `${edge.source}-${edge.target}-${edge.label}-${edge.relationshipType}`;
    if (!seenEdgeKeys.has(key)) {
      uniqueDefinitionMappingEdges.push(edge);
      seenEdgeKeys.add(key);
    }
  });

  // Remove any edges referencing non-existent nodes.
  const validNodeIds = new Set(merged.Entities.Entities.map(n => n.ID));
  const validDefinitionMappingEdges = uniqueDefinitionMappingEdges.filter(edge => {
    const sourceExists = validNodeIds.has(edge.source);
    const targetExists = validNodeIds.has(edge.target);
    return sourceExists && targetExists;
  });

  merged.Relationships.DefinitionMapping = validDefinitionMappingEdges;
  return merged;
}