// src/components/DomainCreationForm.jsx
import React from 'react';
import { FaPlus } from 'react-icons/fa';

const DomainCreationForm = ({ newDomainName, setNewDomainName, isCreatingDomain, handleDomainSubmit }) => {
  return (
    <div className="max-w-lg mx-auto bg-white shadow rounded p-6 flex items-center">
      <input
        type="text"
        value={newDomainName}
        onChange={(e) => setNewDomainName(e.target.value)}
        placeholder="Enter new domain name"
        className="flex-grow border rounded px-3 py-2 focus:outline-none focus:border-success"
      />
      <button
        onClick={handleDomainSubmit}
        disabled={isCreatingDomain}
        title="Add Domain"
        className="ml-4 p-2 rounded-full bg-success hover:bg-successHover text-white focus:outline-none"
      >
        <FaPlus size={18} />
      </button>
    </div>
  );
};

export default DomainCreationForm;