// src/pages/graph_gen/BuildBrainPage.jsx
import React, { useState, useEffect, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { highlightMatches } from '../../core/helpers/graph_gen/common/highlightMatches';
import useSliderPosition from '../../core/helpers/graph_gen/common/useSliderPosition';
import { useGraphGeneration } from '../../core/hooks/graph_gen/build_brain/useGraphGeneration';
import ExtractionStatus from '../../core/containers/graph_gen/build_brain/ExtractionStatus';
import { getValidatedGenerations } from '../../services/api/apiHandlers';
import useOntologyManager from '../../core/hooks/graph_gen/build_brain/useOntologyManager';
import useValidatedExtractionData from '../../core/hooks/graph_gen/build_brain/useValidatedExtractionData';
import GraphArea from '../../core/ui/graph_gen/common/GraphArea';
import { exportOntologyToExcel } from '../../core/helpers/graph_gen/legacy/exportOntologyToExcel';
import InkHeader from '../../core/ui/graph_gen/build_brain/InkHeader';
import { createColorlessStylesheet } from '../../core/helpers/graph_gen/common/knowledge_graph/graphStyles';

const BuildBrainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const domainIdFromState = location.state?.domainId;
  const documentIdsFromState = location.state?.documentIds;
  if (domainIdFromState) localStorage.setItem('domainId', domainIdFromState);
  if (documentIdsFromState)
    localStorage.setItem('documentIds', JSON.stringify(documentIdsFromState));
  const domainId =
    domainIdFromState || localStorage.getItem('domainId') || "mock";
  const isMockDomain = domainId === "mock";

  const {
    availableOntologies,
    selectedOntology,
    setSelectedOntology,
    validatedExtractions,
    setValidatedExtractions,
    setShowOntologyExtraction,
  } = useOntologyManager();

  const {
    inputText,
    setInputText,
    generatedSourceText,
    handleSkeletonSubmit,
  } = useGraphGeneration(domainId, selectedOntology);

  useEffect(() => {
    setShowOntologyExtraction(true);
  }, [setShowOntologyExtraction]);

  useEffect(() => {
    setInputText("");
  }, [isMockDomain, setInputText]);

  // For local "primekg", "primekg-curation", and "primekg-narrowed"
  // we set validatedExtractions from local JSON instead of fetching from the API.
  useEffect(() => {
    if (selectedOntology) {
      if (
        selectedOntology.ontology_id === "primekg" ||
        selectedOntology.ontology_id === "primekg-curation" ||
        selectedOntology.ontology_id === "primekg-narrowed"
      ) {
        setValidatedExtractions([{ generated_data: selectedOntology.ontology_data }]);
      } else {
        getValidatedGenerations(selectedOntology.ontology_id)
          .then((res) => {
            setValidatedExtractions(res.generations || []);
          })
          .catch((err) => {
            console.error("Error fetching validated extractions:", err);
            setValidatedExtractions([]);
          });
      }
    }
  }, [selectedOntology, setValidatedExtractions]);

  // Force all ontologies with "primekg" in their ID to use grey mode (using a case‑insensitive check)
  const isPrimeKg = selectedOntology?.ontology_id?.toLowerCase().includes("primekg");
  const colorMode = isPrimeKg ? "grey" : "colorless";

  const [selectedElementData, setSelectedElementData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [stateIndex, setStateIndex] = useState(0);
  const stateLabels = Array(16).fill("");
  const [sliderMessage, setSliderMessage] = useState("");
  const graphContainerRef = useRef(null);
  const sourceContainerRef = useRef(null);
  const sliderStyle = useSliderPosition(graphContainerRef, 256);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const toggleSearchInput = () => setIsSearchVisible((prev) => !prev);
  const finalHighlightedHTML = highlightMatches(inputText, searchTerm);

  const commonOverlayProps = {
    sliderStyle,
    stateIndex,
    stateLabels,
    sliderMessage,
    onSliderChange: (e) => {
      const newVal = +e.target.value;
      setSliderMessage(newVal >= stateLabels.length ? "Locked" : "");
      if (newVal < stateLabels.length) setStateIndex(newVal);
    },
    showSearchInput: isSearchVisible,
    toggleSearchInput,
    searchTerm,
    setSearchTerm,
    sourceContainerRef,
    sourceText: generatedSourceText,
    finalHighlightedHTML,
    selectedElementData,
    traceRefs: selectedElementData?.references || [],
    handleTrace: () => {
      if (selectedElementData)
        console.log("Trace pressed", selectedElementData.references);
    },
    colorMode,
  };

  const {
    ontologyOnlyData,
    validatedAttributeData,
    rawExtractionsData,
    ontologyOverlayProps,
    attributeOverlayProps,
  } = useValidatedExtractionData(
    validatedExtractions,
    selectedOntology,
    commonOverlayProps,
    colorMode
  );

  // Log the final data to see if we have the correct edges
  useEffect(() => {
    console.log("[DEBUG] Final rawExtractionsData:", JSON.stringify(rawExtractionsData, null, 2));
  }, [rawExtractionsData]);

  const graphHeight = window.innerHeight - 80;
  // Always use dagre layout now that simulation is removed
  const layoutType = "dagre";

  // Build a stylesheet; if we're in a PrimeKG ontology, filter out rules that affect relationship entity nodes.
  let chosenStylesheet = createColorlessStylesheet();
  if (isPrimeKg) {
    chosenStylesheet = chosenStylesheet.filter(
      (rule) =>
        !(rule.selector && rule.selector.includes('node[isRelationshipEntity = "true"]'))
    );
  }

  return (
    <div className="min-h-screen p-4 bg-neenah-grid relative">
      <div className="w-full mb-4">
        <InkHeader
          availableOntologies={availableOntologies}
          selectedOntology={selectedOntology}
          setSelectedOntology={setSelectedOntology}
          onExport={() => {
            if (rawExtractionsData) {
              exportOntologyToExcel(rawExtractionsData);
            } else {
              alert("No data available.");
            }
          }}
        />
      </div>

      <div className="p-4 rounded relative shadow">
        {rawExtractionsData ? (
          <GraphArea
            transformedData={rawExtractionsData}
            graphHeight={graphHeight}
            handleElementSelect={setSelectedElementData}
            layoutType={layoutType}
            styleSheet={chosenStylesheet}
            colorMode={colorMode}
            {...{ ...attributeOverlayProps, layoutType }}
          />
        ) : (
          <p>No data available.</p>
        )}
      </div>
    </div>
  );
};

export default BuildBrainPage;