import React from "react";
import { Edit, Save, Info, Trash } from "lucide-react";

export interface TableRowData {
  Name: string;
  DataPoint: number | boolean | string[] | null;
  Source: string;
  Motivation: string;
}

interface ReviewTableProps {
  tableData: TableRowData[];
  editIndexes: Record<number, boolean>;
  sourceIndex: number | null;
  nameMapping: Record<string, string>;
  fieldTypes: Record<string, "number" | "boolean" | "array">;
  onDataPointChange: (rowIndex: number, newValue: string) => void;
  onEditClick: (rowIndex: number) => void;
  onInfoClick: (rowIndex: number) => void;
  onDeleteClick: (rowIndex: number) => void;
}

export const ReviewTable: React.FC<ReviewTableProps> = ({
  tableData,
  editIndexes,
  sourceIndex,
  nameMapping,
  fieldTypes,
  onDataPointChange,
  onEditClick,
  onInfoClick,
  onDeleteClick,
}) => {
  return (
    <table className="w-full text-sm border-collapse">
      <thead>
        <tr className="bg-gray-50 border-b">
          <th className="p-2 text-left">Name</th>
          <th className="p-2 text-left">DataPoint</th>
          <th className="p-2 text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, rowIdx) => {
          const isEditing = !!editIndexes[rowIdx];
          const expectedType = fieldTypes[row.Name] || "number";
          const isBoolean = expectedType === "boolean";
          const isList = Array.isArray(row.DataPoint);
          const value = row.DataPoint;

          return (
            <tr
              key={rowIdx}
              className={`border-b hover:bg-gray-50 ${
                sourceIndex === rowIdx ? "bg-gray-300" : ""
              }`}
            >
              <td className="p-2">{nameMapping[row.Name] || row.Name}</td>
              <td className="p-2">
                {isEditing ? (
                  isBoolean ? (
                    <select
                      value={
                        value === true ? "yes" : value === false ? "no" : ""
                      }
                      onChange={(e) =>
                        onDataPointChange(rowIdx, e.target.value)
                      }
                      className="border p-1 rounded"
                    >
                      <option value=""></option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  ) : isList ? (
                    <input
                      type="text"
                      value={(value ?? []).join(", ")}
                      onChange={(e) =>
                        onDataPointChange(rowIdx, e.target.value)
                      }
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    <input
                      type="number"
                      value={value ?? ""}
                      onChange={(e) =>
                        onDataPointChange(rowIdx, e.target.value)
                      }
                      className="w-full p-1 border rounded"
                    />
                  )
                ) : isBoolean ? (
                  value === true ? "Yes" : value === false ? "No" : ""
                ) : isList ? (
                  (value ?? []).join(", ")
                ) : (
                  value ?? ""
                )}
              </td>
              <td className="p-2 text-right flex justify-end">
                <button
                  onClick={() => onEditClick(rowIdx)}
                  className="mr-2 text-gray-600 hover:text-gray-900"
                  aria-label={isEditing ? "Save" : "Edit"}
                >
                  {isEditing ? (
                    <Save className="h-4 w-4" />
                  ) : (
                    <Edit className="h-4 w-4" />
                  )}
                </button>
                {row.Source && row.Source.trim() !== "" && (
                  <button
                    onClick={() => onInfoClick(rowIdx)}
                    className="mr-2 text-gray-600 hover:text-gray-900"
                    aria-label="Info"
                  >
                    <Info className="h-4 w-4" />
                  </button>
                )}
                <button
                  onClick={() => onDeleteClick(rowIdx)}
                  className="text-red-600 hover:text-red-800"
                  aria-label="Delete"
                >
                  <Trash className="h-4 w-4" />
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};