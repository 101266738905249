// src/components/graph_gen/manual_graph/graph_editor_manual/AddEntityPopoverManual.jsx
import React, { useState, useRef, useEffect } from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';

const AddEntityPopoverManual = ({
  onAddNode,
  onAddRelationship,
  onAddTypeRelationship,
  onAddAttributeInstance,
  nodeOptions,
  relationshipDefinitionOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [entityType, setEntityType] = useState('node');
  const [nodeName, setNodeName] = useState('');
  const [nodeDefinition, setNodeDefinition] = useState('');
  const defaultNodeColor = '#ffffff';

  const [relName, setRelName] = useState('');
  const [relDefinition, setRelDefinition] = useState('');
  const [sourceNode, setSourceNode] = useState('');
  const [targetNode, setTargetNode] = useState('');
  const [motivation, setMotivation] = useState('');
  const [relationshipDefinitionId, setRelationshipDefinitionId] = useState('');

  const popoverRef = useRef(null);

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  // Close popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (entityType === 'node') {
      if (!nodeName.trim()) return;
      onAddNode({ Name: nodeName, Definition: nodeDefinition, baseColor: defaultNodeColor });
      setNodeName('');
      setNodeDefinition('');
    } else if (entityType === 'relationship') {
      if (!relName.trim() || !sourceNode || !targetNode) return;
      onAddRelationship({
        type: 'attribute', // ensures proper handling for relationship entities
        Name: relName,
        Definition: relDefinition,
        SourceNodeTypeID: sourceNode,
        TargetNodeTypeID: targetNode,
      });
      setRelName('');
      setRelDefinition('');
      setSourceNode('');
      setTargetNode('');
    } else if (entityType === 'type') {
      if (!sourceNode || !targetNode) return;
      onAddTypeRelationship({
        sourceNodeId: sourceNode,
        targetNodeId: targetNode,
        motivation: motivation,
      });
      setSourceNode('');
      setTargetNode('');
      setMotivation('');
    } else if (entityType === 'attribute-instance') {
      if (!sourceNode || !targetNode || !relationshipDefinitionId) return;
      onAddAttributeInstance({
        sourceNodeId: sourceNode,
        targetNodeId: targetNode,
        motivation: motivation,
        entityId: relationshipDefinitionId,
      });
      setSourceNode('');
      setTargetNode('');
      setRelationshipDefinitionId('');
      setMotivation('');
    }
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block" ref={popoverRef}>
      {/* Plus button */}
      <button
        className="flex items-center justify-center p-3 rounded-full bg-success text-white hover:bg-successHover focus:outline-none focus:ring-2 focus:ring-success"
        onClick={togglePopover}
        title="Add Entity"
      >
        {isOpen ? <FaTimes size={20} /> : <FaPlus size={20} />}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
          <div className="px-4 py-3 border-b border-gray-200 flex items-center justify-between">
            <h3 className="text-lg font-semibold">
              {entityType === 'node'
                ? 'Add Node'
                : entityType === 'relationship'
                ? 'Define a Relationship'
                : entityType === 'type'
                ? 'Add Type Relationship'
                : 'Add Attribute Relationship Instance'}
            </h3>
            <button onClick={togglePopover} className="text-gray-500 hover:text-gray-700">
              <FaTimes size={18} />
            </button>
          </div>
          <div className="px-4 py-3">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Type</label>
              <select
                value={entityType}
                onChange={(e) => setEntityType(e.target.value)}
                className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:ring-success focus:border-successHover"
              >
                <option value="node">Node</option>
                <option value="relationship">Relationship Definition</option>
                <option value="type">Type Relationship</option>
                <option value="attribute-instance">Attribute Relationship Instance</option>
              </select>
            </div>

            {entityType === 'node' && (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Node Name</label>
                  <input
                    type="text"
                    value={nodeName}
                    onChange={(e) => setNodeName(e.target.value)}
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                    placeholder="Enter node name"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Definition</label>
                  <textarea
                    value={nodeDefinition}
                    onChange={(e) => setNodeDefinition(e.target.value)}
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                    placeholder="Enter definition"
                  />
                </div>
                <button type="submit" className="w-full py-2 bg-success text-white rounded-md focus:outline-none">
                  Add Node
                </button>
              </form>
            )}

            {entityType === 'relationship' && (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Relationship Name</label>
                  <input
                    type="text"
                    value={relName}
                    onChange={(e) => setRelName(e.target.value)}
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                    placeholder="Enter relationship name"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Definition</label>
                  <textarea
                    value={relDefinition}
                    onChange={(e) => setRelDefinition(e.target.value)}
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                    placeholder="Enter definition"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Source Node</label>
                  <select
                    value={sourceNode}
                    onChange={(e) => setSourceNode(e.target.value)}
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                  >
                    <option value="">Select source</option>
                    {nodeOptions.map((node) => (
                      <option key={node.ID} value={node.ID}>
                        {node.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Target Node</label>
                  <select
                    value={targetNode}
                    onChange={(e) => setTargetNode(e.target.value)}
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                  >
                    <option value="">Select target</option>
                    {nodeOptions.map((node) => (
                      <option key={node.ID} value={node.ID}>
                        {node.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <button type="submit" className="w-full py-2 bg-success text-white rounded-md focus:outline-none">
                  Define Relationship
                </button>
              </form>
            )}

            {entityType === 'type' && (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Source Node</label>
                  <select
                    value={sourceNode}
                    onChange={(e) => setSourceNode(e.target.value)}
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                  >
                    <option value="">Select source</option>
                    {nodeOptions.map((node) => (
                      <option key={node.ID} value={node.ID}>
                        {node.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Target Node</label>
                  <select
                    value={targetNode}
                    onChange={(e) => setTargetNode(e.target.value)}
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                  >
                    <option value="">Select target</option>
                    {nodeOptions.map((node) => (
                      <option key={node.ID} value={node.ID}>
                        {node.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Motivation (optional)
                  </label>
                  <input
                    type="text"
                    value={motivation}
                    onChange={(e) => setMotivation(e.target.value)}
                    placeholder="e.g., Is A"
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                  />
                </div>
                <button type="submit" className="w-full py-2 bg-success text-white rounded-md focus:outline-none">
                  Add Type Relationship
                </button>
              </form>
            )}

            {entityType === 'attribute-instance' && (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Source Node</label>
                  <select
                    value={sourceNode}
                    onChange={(e) => setSourceNode(e.target.value)}
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                  >
                    <option value="">Select source</option>
                    {nodeOptions.map((node) => (
                      <option key={node.ID} value={node.ID}>
                        {node.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Target Node</label>
                  <select
                    value={targetNode}
                    onChange={(e) => setTargetNode(e.target.value)}
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                  >
                    <option value="">Select target</option>
                    {nodeOptions.map((node) => (
                      <option key={node.ID} value={node.ID}>
                        {node.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Relationship Definition</label>
                  <select
                    value={relationshipDefinitionId}
                    onChange={(e) => setRelationshipDefinitionId(e.target.value)}
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                  >
                    <option value="">Select relationship definition</option>
                    {relationshipDefinitionOptions.map((relDef) => (
                      <option key={relDef.ID} value={relDef.ID}>
                        {relDef.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Motivation (optional)</label>
                  <input
                    type="text"
                    value={motivation}
                    onChange={(e) => setMotivation(e.target.value)}
                    placeholder="e.g., has attribute"
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-success focus:border-successHover"
                  />
                </div>
                <button type="submit" className="w-full py-2 bg-success text-white rounded-md focus:outline-none">
                  Add Attribute Relationship Instance
                </button>
              </form>
            )}

          </div>
        </div>
      )}
    </div>
  );
};

export default AddEntityPopoverManual;