import * as XLSX from 'xlsx';

/**
 * exportArrayToExcel
 *
 * Given a two-dimensional array (dataArray) and a file name,
 * creates an Excel worksheet and triggers a download.
 *
 * @param {Array[]} dataArray - Array of arrays representing rows.
 * @param {string} fileName - Name of the Excel file to save.
 */
export const exportArrayToExcel = (dataArray, fileName) => {
  const worksheet = XLSX.utils.aoa_to_sheet(dataArray);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Ontology");
  XLSX.writeFile(workbook, fileName);
};