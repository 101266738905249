import React, { useEffect, useRef, useMemo } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import Cytoscape from 'cytoscape';
import fcose from 'cytoscape-fcose';
import dagre from 'cytoscape-dagre';
import { fcoseLayoutOld, dagreLayoutOld } from '../../helpers/legacy/cystoscapeLayoutOld';
import { cyStylesheet } from '../../helpers/legacy/graph/cytoscapeStylesOld';
import { prepareGraphElements } from '../../helpers/legacy/graph/prepareGraphElements';
import { clamp } from '../../helpers/legacy/graph/cytoscapeHelpers';

// Register plugins
Cytoscape.use(fcose);
Cytoscape.use(dagre);

const KnowledgeGraphOld = ({ data, onElementSelect, highlightNodes = [], layoutType = 'fcose' }) => {
  const cyRef = useRef(null);
  const containerRef = useRef(null);
  const prevDataRef = useRef(null);

  // Prepare graph elements from data
  const elements = useMemo(() => prepareGraphElements(data), [data]);

  // Highlight nodes based on highlightNodes array
  useEffect(() => {
    const cy = cyRef.current;
    if (!cy) return;
    cy.nodes().removeClass('highlighted');
    highlightNodes.forEach((id) => {
      const trimmedId = id.toString().trim();
      if (!trimmedId) return;
      const element = cy.getElementById(trimmedId);
      if (element && element.length > 0) {
        element.addClass('highlighted');
      }
    });
  }, [highlightNodes]);

  // Effect to add elements when data changes
  useEffect(() => {
    const cy = cyRef.current;
    if (!cy) return;
    const dataString = JSON.stringify(data);
    if (prevDataRef.current === dataString) return;
    prevDataRef.current = dataString;
    cy.elements().remove();
    cy.add(elements);

    // Run layout based on the current layoutType
    const layoutConfig = layoutType === 'dagre' ? dagreLayoutOld : fcoseLayoutOld;
    cy.layout(layoutConfig).run();

    cy.off('tap');
    cy.on('tap', 'node, edge', (e) => {
      if (onElementSelect) onElementSelect(e.target.data());
    });
    cy.on('tap', (e) => {
      if (e.target === cy && onElementSelect) onElementSelect(null);
    });
  }, [data, elements, onElementSelect]);

  // Separate effect to re-run layout when layoutType changes
  useEffect(() => {
    const cy = cyRef.current;
    if (!cy) return;
    const layoutConfig = layoutType === 'dagre' ? dagreLayoutOld : fcoseLayoutOld;
    cy.layout(layoutConfig).run();
  }, [layoutType]);

  // Clamp pan on zoom and pan end.
  useEffect(() => {
    const cy = cyRef.current;
    const container = containerRef.current;
    if (!cy || !container) return;

    const clampPan = () => {
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      const zoom = cy.zoom();
      const bb = cy.elements().boundingBox();

      const minPanX = containerWidth - zoom * bb.x2;
      const maxPanX = -zoom * bb.x1;
      const minPanY = containerHeight - zoom * bb.y2;
      const maxPanY = -zoom * bb.y1;

      const pan = cy.pan();
      const newPanX = clamp(pan.x, minPanX, maxPanX);
      const newPanY = clamp(pan.y, minPanY, maxPanY);

      if (newPanX !== pan.x || newPanY !== pan.y) {
        cy.pan({ x: newPanX, y: newPanY });
      }
    };

    cy.on('panend zoomend', clampPan);
    clampPan();

    return () => {
      cy.off('panend zoomend', clampPan);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="w-full h-full rounded-md"
      style={{ backgroundColor: '#f9fafb', overflow: 'hidden' }}
    >
      <CytoscapeComponent
        cy={(cy) => {
          cyRef.current = cy;
        }}
        elements={[]}
        stylesheet={cyStylesheet}
        style={{ width: '100%', height: '100%' }}
        wheelSensitivity={0.5}
      />
    </div>
  );
};

export default KnowledgeGraphOld;