const BASE_URL = "https://atlaz-api.com/species"

export async function fetchAllSpecies() {
  const res = await fetch(`${BASE_URL}/get-species`)
  if (!res.ok) {
    throw new Error("Error fetching species.")
  }
  return res.json()
}

export async function getPdfExtractions(speciesIdOrName: string) {
    const url = `${BASE_URL}/api/get-pdf-extractions?species_name=${encodeURIComponent(speciesIdOrName)}`
    const res = await fetch(url)
    if (!res.ok) {
      const errorBody = await res.text() // might be HTML if it's a 404
      throw new Error(`Failed to fetch (HTTP ${res.status}): ${errorBody}`)
    }
    return res.json() // => { extractions: [ { id, pdf_key, Extant_Populations, ...}, ... ] }
  }
export async function createSpecies(payload: {
  name: string
  latinName: string
  imageKey?: string
}) {
  const res = await fetch(`${BASE_URL}/new-species`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  })
  if (!res.ok) {
    const errorData = await res.json()
    throw new Error(errorData.detail || "Failed to create species.")
  }
  return res.json()
}

export async function gatherImagesForSpecies(speciesName: string) {
  const url = `${BASE_URL}/api/images?query=${encodeURIComponent(speciesName)}`
  const res = await fetch(url)
  if (!res.ok) {
    const errorData = await res.json()
    throw new Error(errorData.error || "Failed to fetch images.")
  }
  return res.json()
}

export async function saveImageForSpecies(
  speciesName: string,
  imageUrl: string
) {
  const res = await fetch(`${BASE_URL}/api/save-image`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      species_name: speciesName,
      image_url: imageUrl,
    }),
  })

  if (!res.ok) {
    throw new Error("Failed to save image")
  }

  return res.json()
}

export async function saveExtractedData(payload: any) {
  const res = await fetch(`${BASE_URL}/api/save-extracted-data`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  })

  if (!res.ok) {
    const errorData = await res.json()
    throw new Error(errorData.detail || `Save failed: HTTP ${res.status}`)
  }

  return res.json()
}

/**
 * Fetch species details by species ID
 */
export async function getSpeciesDetail(speciesId: number) {
    const res = await fetch(`${BASE_URL}/api/species/${speciesId}`);
    if (!res.ok) {
      const errorBody = await res.text();
      throw new Error(`Failed to fetch species details (HTTP ${res.status}): ${errorBody}`);
    }
    return res.json(); // Expected: { id, name, latinName, description, status, image }
  }

  export async function getPdfExtractionStatus(extractionId: string) {
    const res = await fetch(`${BASE_URL}/api/extraction-status/${extractionId}`);
    if (!res.ok) {
      const errorText = await res.text();
      throw new Error(`Failed to fetch extraction status: ${errorText}`);
    }
    return res.json();
  }

  export async function deleteExtractionStatus(extractionId: string) {
    const res = await fetch(`${BASE_URL}/api/extraction-status/${extractionId}`, {
      method: "DELETE",
    })
    if (!res.ok) {
      const errorText = await res.text()
      throw new Error(`Failed to delete extraction status: ${errorText}`)
    }
    return res.json()
  }

  export async function deletePdfExtraction(extractionId) {
    const res = await fetch(`${BASE_URL}/api/pdf-extraction/${extractionId}`, {
      method: "DELETE",
    });
    if (!res.ok) {
      const errorText = await res.text();
      throw new Error(`Failed to delete PDF extraction: ${errorText}`);
    }
    return res.json();
  }

export async function deleteSpecies(speciesId: number) {
    const res = await fetch(`${BASE_URL}/api/species/${speciesId}`, {
      method: "DELETE",
    });
    if (!res.ok) {
      const errorData = await res.text();
      throw new Error(errorData || "Failed to delete species.");
    }
    return res.json();
  }