import { useState, useEffect } from "react"

export function useExtractionJobs() {
  const [extractionJobs, setExtractionJobs] = useState<any[]>([])
  const [jobsLoading, setJobsLoading] = useState(false)
  const [jobsError, setJobsError] = useState("")

  async function fetchExtractionJobs() {
    setJobsLoading(true)
    setJobsError("")
    try {
      const res = await fetch("https://atlaz-api.com/species/api/get-all-extraction-statuses")
      if (!res.ok) {
        throw new Error(`HTTP ${res.status}`)
      }
      const data = await res.json()
      setExtractionJobs(data.extraction_statuses || [])
    } catch (error) {
      console.error("Error fetching extraction jobs:", error)
      setJobsError("Failed to fetch extraction jobs.")
    } finally {
      setJobsLoading(false)
    }
  }

  useEffect(() => {
    fetchExtractionJobs()
  }, [])

  return { extractionJobs, jobsLoading, jobsError, fetchExtractionJobs }
}