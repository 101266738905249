import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import SearchToggleInput from './SearchToggleInput';
import { highlightMatches } from '../../../../helpers/graph_gen/common/highlightMatches';

const SourceTextPanel = ({
  showSearchInput,
  toggleSearchInput,
  searchTerm,
  setSearchTerm,
  sourceContainerRef,
  sourceText,
  traceRefs,
}) => {
  let highlightedHTML = highlightMatches(sourceText, searchTerm);
  const filteredRefs = (traceRefs || []).filter(
    (r) => typeof r === 'string' && r.trim() !== ''
  );
  let finalHighlightedHTML = highlightedHTML;
  filteredRefs.forEach((q) => {
    const escaped = q.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    const regex = new RegExp(`(${escaped})`, 'gi');
    finalHighlightedHTML = finalHighlightedHTML.replace(
      regex,
      '<mark class="reference-highlight">$1</mark>'
    );
  });
  useLayoutEffect(() => {
    if (!sourceContainerRef || !sourceContainerRef.current) {
      console.error('SourceTextPanel: sourceContainerRef.current is undefined');
      return;
    }
    const firstMark = sourceContainerRef.current.querySelector('mark');
    if (firstMark) {
      firstMark.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [finalHighlightedHTML, sourceContainerRef]);

  return (
    <div className="border border-white rounded bg-white p-2 text-sm text-gray-800">
      <SearchToggleInput
        showSearchInput={showSearchInput}
        toggleSearchInput={toggleSearchInput}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div
        ref={sourceContainerRef}
        className="overflow-auto"
        style={{ whiteSpace: 'pre-wrap', height: '25vh' }}
        dangerouslySetInnerHTML={{ __html: finalHighlightedHTML }}
      />
    </div>
  );
};

SourceTextPanel.propTypes = {
  showSearchInput: PropTypes.bool.isRequired,
  toggleSearchInput: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  sourceContainerRef: PropTypes.object.isRequired,
  sourceText: PropTypes.string.isRequired,
  traceRefs: PropTypes.arrayOf(PropTypes.string),
};

export default SourceTextPanel;