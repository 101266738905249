// src/core/ui/general/home/DNAStrand.jsx
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

const DNAStrand = () => {
  const baseColors = {
    A: "#45454a",
    T: "#a0a0a0",
    G: "#1d1d22",
    C: "#F1F1F1",
  };

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    // Added "pointer-events-none" so that this container and its children do not intercept clicks.
    <motion.div
      className="relative w-full max-w-4xl mx-auto pointer-events-none"
      style={{
        height: "12rem",
        marginTop: "10rem",
        marginBottom: "0rem",
        transform: "rotate(-90deg)",
        transformOrigin: "center center",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {/* 15 rods (like the original), but spaced more tightly */}
      {[...Array(15)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute left-1/2 w-48 h-2 rounded-full"
          style={{
            // The rods start at 16px from the top and move 16px apart
            top: `${(i * 16) + 16}px`,
            transform: `translateX(-50%) rotate(${i * 24}deg)`,
            background:
              "linear-gradient(90deg, rgba(241,241,241,0.9) 0%, rgba(160,160,160,0.9) 50%, rgba(241,241,241,0.9) 100%)",
            boxShadow: "0 0 20px rgba(69,69,74,0.2)",
          }}
          animate={{
            rotate: [i * 24, (i * 24) + 360],
            scale: [1, 1.02, 1],
          }}
          transition={{
            rotate: {
              duration: 20,
              repeat: Infinity,
              ease: "linear",
            },
            scale: {
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut",
            },
          }}
        >
          {/* Left base pair */}
          <motion.div
            className="absolute -left-2 top-1/2 w-4 h-4 rounded-full transform -translate-y-1/2"
            style={{
              backgroundColor: baseColors[["A", "T", "G", "C"][i % 4]],
              boxShadow: `0 0 15px ${baseColors[["A", "T", "G", "C"][i % 4]]}, 
                          inset 0 0 8px rgba(241,241,241,0.5)`,
              border: "2px solid rgba(241,241,241,0.4)",
            }}
            whileHover={{ scale: 1.2 }}
          >
            <div className="absolute inset-1 rounded-full bg-[#F1F1F1]/20 transform rotate-45" />
          </motion.div>

          {/* Right base pair */}
          <motion.div
            className="absolute -right-2 top-1/2 w-4 h-4 rounded-full transform -translate-y-1/2"
            style={{
              backgroundColor: baseColors[["T", "A", "C", "G"][i % 4]],
              boxShadow: `0 0 15px ${baseColors[["T", "A", "C", "G"][i % 4]]},
                          inset 0 0 8px rgba(241,241,241,0.5)`,
              border: "2px solid rgba(241,241,241,0.4)",
            }}
            whileHover={{ scale: 1.2 }}
          >
            <div className="absolute inset-1 rounded-full bg-[#F1F1F1]/20 transform rotate-45" />
          </motion.div>

          {/* Connecting line */}
          <div
            className="absolute top-1/2 left-0 right-0 h-[1px] transform -translate-y-1/2"
            style={{
              background:
                "linear-gradient(90deg, transparent 0%, rgba(241,241,241,0.4) 20%, rgba(241,241,241,0.4) 80%, transparent 100%)",
              boxShadow: "0 0 8px rgba(69,69,74,0.3)",
            }}
          />
        </motion.div>
      ))}

      {/* Floating particles */}
      {[...Array(30)].map((_, i) => (
        <motion.div
          key={`particle-${i}`}
          className="absolute rounded-full"
          style={{
            width: `${1 + Math.random() * 2}px`,
            height: `${1 + Math.random() * 2}px`,
            background: `linear-gradient(135deg, ${
              i % 2 ? "#45454a" : "#F1F1F1"
            }40, ${i % 2 ? "#1d1d22" : "#a0a0a0"}40)`,
            boxShadow: "0 0 10px rgba(241,241,241,0.3)",
          }}
          initial={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * 500,
          }}
          animate={{
            x: [
              Math.random() * window.innerWidth,
              Math.random() * window.innerWidth,
              Math.random() * window.innerWidth,
            ],
            y: [
              Math.random() * 500,
              Math.random() * 500,
              Math.random() * 500,
            ],
            scale: [1, 1.5, 1],
            opacity: [0.3, 0.7, 0.3],
          }}
          transition={{
            duration: 8 + Math.random() * 8,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        />
      ))}
    </motion.div>
  );
};

export default DNAStrand;