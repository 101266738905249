import React from "react";
import * as XLSX from "xlsx";
import { Button } from "./button.tsx";

interface ExportExcelButtonProps {
  pdfExtractions: any[];
  fieldLabels: Record<string, string>;
}

export default function ExportExcelButton({
  pdfExtractions,
  fieldLabels,
}: ExportExcelButtonProps) {
  // Helper to strip relative paths from PDF names.
  const stripPath = (path: string): string => {
    if (!path) return "";
    const parts = path.split(/[\\/]/);
    return parts[parts.length - 1];
  };

  const exportToExcel = () => {
    // Updated fields array now includes the two population size fields.
    const fields = [
      "Extant_Populations",
      "Extinct_Populations",
      "Population_Less_Than_5000_Individuals",
      "Population_More_Than_5000_Individuals",
      "Phylogenetic_Geographic_Studies",
      "Population_Genetic_Studies",
      "DNA_based_Temporal_Monitoring",
      "Generation_Time",
      "Dispersal_Distance",
      "Population_Sizes_Lower_Bound",
      "Population_Sizes_Upper_Bound",
    ];

    const header = ["Field", ...pdfExtractions.map((pdf) => stripPath(pdf.pdfName))];
    const mainData = [header];

    fields.forEach((field) => {
      const row = [];
      row.push(fieldLabels[field] || field);
      pdfExtractions.forEach((pdf) => {
        const value = pdf.fields[field];
        row.push(value === null || value === undefined ? "-" : value);
      });
      mainData.push(row);
    });
    let maxCounts = 0;
    pdfExtractions.forEach((pdf) => {
      const counts = pdf.fields.Count_Each_Extant_Population;
      if (Array.isArray(counts) && counts.length > maxCounts) {
        maxCounts = counts.length;
      }
    });

    const countHeader = [
      "PDF",
      ...Array.from({ length: maxCounts }, (_, i) => `Count ${i + 1}`),
    ];
    const countData = [countHeader];

    pdfExtractions.forEach((pdf) => {
      const row = [];
      row.push(stripPath(pdf.pdfName));
      const counts = pdf.fields.Count_Each_Extant_Population || [];
      for (let i = 0; i < maxCounts; i++) {
        row.push(counts[i] !== undefined ? counts[i] : "");
      }
      countData.push(row);
    });

    const wb = XLSX.utils.book_new();
    const wsMain = XLSX.utils.aoa_to_sheet(mainData);
    XLSX.utils.book_append_sheet(wb, wsMain, "Main Data Points");

    const wsCount = XLSX.utils.aoa_to_sheet(countData);
    XLSX.utils.book_append_sheet(wb, wsCount, "Extant Population Counts");

    XLSX.writeFile(wb, "SpeciesDetailData.xlsx");
  };

  return (
    <Button onClick={exportToExcel} variant="outline">
      Export to Excel
    </Button>
  );
}