import React, { useState, useMemo } from 'react';
import { makeSafeData } from '../../../helpers/graph_gen/common/makeSafeData';
import { transformModeData } from '../../../helpers/graph_gen/others/transformModeData';

const defaultOntology = {
  globalIdCounter: 2,
  Entities: {
    Entities: [],
    PrimitiveDataTypeEntities: [],
  },
  Relationships: {
    TypeRelationships: [],
    AttributeRelationships: [],
    EquivalenceRelationships: [],
  },
  Decompositions: {
    TypeDecompositions: [],
    AttributeFunctionalDecompositions: [],
    AttributeAggregationDecompositions: [],
  },
  RelationshipEntities: {
    AttributeRelationshipEntities: [],
    StructuralRelationshipEntities: [],
  },
};

const GraphDataProviderManual = ({ mode, colorMode, children }) => {
  // Automatically initialize with a blank ontology.
  const [rawGraphData, setRawGraphData] = useState(defaultOntology);

  const safeData = useMemo(() => makeSafeData(rawGraphData), [rawGraphData]);
  const transformedData = useMemo(
    () => transformModeData(safeData, mode, colorMode),
    [safeData, mode, colorMode]
  );

  return children({
    // Since we are creating a new ontology, we have no generations.
    allGenerations: [],
    selectedGenerationId: "",
    setSelectedGenerationId: () => {},
    rawGraphData,
    safeData,
    transformedData,
    updateGraphData: setRawGraphData,
  });
};

export default GraphDataProviderManual;