// src/hooks/useClampPan.js
import { useEffect } from 'react';

const useClampPan = (cyRef, containerRef) => {
  useEffect(() => {
    const cy = cyRef.current;
    const container = containerRef.current;
    if (!cy || !container) return;
  
    const clampPan = () => {
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      const zoom = cy.zoom();
      const bb = cy.elements().boundingBox();
  
      const minPanX = containerWidth - zoom * bb.x2;
      const maxPanX = -zoom * bb.x1;
      const minPanY = containerHeight - zoom * bb.y2;
      const maxPanY = -zoom * bb.y1;
  
      const pan = cy.pan();
      const newPanX = Math.min(maxPanX, Math.max(minPanX, pan.x));
      const newPanY = Math.min(maxPanY, Math.max(minPanY, pan.y));
  
      if (newPanX !== pan.x || newPanY !== pan.y) {
        cy.pan({ x: newPanX, y: newPanY });
      }
    };
  
    cy.on('panend zoomend', clampPan);
    clampPan();
  
    return () => {
      cy.off('panend zoomend', clampPan);
    };
  }, [cyRef, containerRef]);
};

export default useClampPan;