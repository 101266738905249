// src/utils/modeModifiers/ExtensiveDataModifier.js

export default function modifyDataExtensive(rawData) {
    const dataCopy = JSON.parse(JSON.stringify(rawData));
  
    // 1) Remove entity with ID 1
    dataCopy.Entities.Entities = dataCopy.Entities.Entities.filter(
      (entity) => entity.ID !== 1
    );
  
    // 2) Remove edges referencing entity 1
    if (dataCopy.Relationships) {
      if (Array.isArray(dataCopy.Relationships.TypeRelationships)) {
        dataCopy.Relationships.TypeRelationships =
          dataCopy.Relationships.TypeRelationships.filter(
            (rel) => rel.SourceNodeID !== 1 && rel.TargetNodeID !== 1
          );
      }
      if (Array.isArray(dataCopy.Relationships.AttributeRelationships)) {
        dataCopy.Relationships.AttributeRelationships =
          dataCopy.Relationships.AttributeRelationships.filter(
            (rel) => rel.SourceNodeID !== 1 && rel.TargetNodeID !== 1
          );
      }
      if (Array.isArray(dataCopy.Relationships.DefinitionMapping)) {
        dataCopy.Relationships.DefinitionMapping =
          dataCopy.Relationships.DefinitionMapping.filter(
            (rel) => rel.SourceNodeID !== 1 && rel.TargetNodeID !== 1
          );
      }
    }
  
    // 3) Add relationship entity nodes (skipping IDs 100, 101, 102, etc. if needed)
    const relEntityNodes = [];
    if (dataCopy.RelationshipEntities) {
      if (Array.isArray(dataCopy.RelationshipEntities.AttributeRelationshipEntities)) {
        dataCopy.RelationshipEntities.AttributeRelationshipEntities.forEach((relEntity) => {
          if ([100, 101, 102].includes(relEntity.ID)) return;
          relEntityNodes.push({
            ID: relEntity.ID,
            Name: relEntity.Name,
            Definition: relEntity.Definition,
            references: relEntity.references,
            isRelationshipEntity: true,
          });
        });
      }
      if (Array.isArray(dataCopy.RelationshipEntities.StructuralRelationshipEntities)) {
        dataCopy.RelationshipEntities.StructuralRelationshipEntities.forEach((relEntity) => {
          if ([100, 101, 102].includes(relEntity.ID)) return;
          relEntityNodes.push({
            ID: relEntity.ID,
            Name: relEntity.Name,
            Definition: relEntity.Definition,
            references: relEntity.references,
            isRelationshipEntity: true,
          });
        });
      }
    }
    dataCopy.Entities.Entities = dataCopy.Entities.Entities.concat(relEntityNodes);
  
    // 4) In Extensive mode, remove transitive edges from TypeRelationships.
    if (
      dataCopy.Relationships &&
      Array.isArray(dataCopy.Relationships.TypeRelationships)
    ) {
      dataCopy.Relationships.TypeRelationships = removeTransitiveEdges(
        dataCopy.Relationships.TypeRelationships
      );
    }
  
    return dataCopy;
  }
  
  // Helper function to remove transitive edges.
  function removeTransitiveEdges(edges) {
    // Build an adjacency list from the edges.
    const adj = {};
    edges.forEach((edge) => {
      const { SourceNodeID, TargetNodeID } = edge;
      if (!adj[SourceNodeID]) adj[SourceNodeID] = [];
      adj[SourceNodeID].push(TargetNodeID);
    });
  
    // Returns true if there's an alternate path (≥ 2 hops) from source to target.
    const hasAlternatePath = (source, target) => {
      const visited = new Set();
      const stack = [];
      if (adj[source]) {
        for (const neighbor of adj[source]) {
          if (neighbor !== target) stack.push(neighbor);
        }
      }
      while (stack.length > 0) {
        const node = stack.pop();
        if (node === target) return true;
        if (!visited.has(node)) {
          visited.add(node);
          if (adj[node]) {
            for (const nb of adj[node]) {
              stack.push(nb);
            }
          }
        }
      }
      return false;
    };
  
    // Keep only those edges for which there is no indirect path from Source to Target.
    return edges.filter((edge) => {
      const { SourceNodeID, TargetNodeID } = edge;
      return !hasAlternatePath(SourceNodeID, TargetNodeID);
    });
  }