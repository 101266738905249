// src/utils/cytoscapeLayout.js
export const fcoseLayoutOld = {
    name: 'fcose',
    rankDir: 'LR',
    nodeSep: 50,
    edgeSep: 10,
    rankSep: 100,
    ranker: 'longest-path'
  };


export const dagreLayoutOld = {
    name: 'dagre',
    rankDir: 'LR',
    nodeSep: 50,
    edgeSep: 10,
    rankSep: 50,
  };