// src/core/helpers/graph_gen/common/knowledge_graph/prepare_new_graph_elements/prepareNodes.js
export function getTextWidth(text, font = "16px serif") {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  return context.measureText(text).width;
}

export const prepareNodes = (data) => {
  const nodes = [];
  if (!data || !data.Entities || !Array.isArray(data.Entities.Entities)) {
    console.error("Invalid data format for nodes:", data);
    return nodes;
  }
  console.log("Creating nodes...");
  data.Entities.Entities.forEach((entity) => {
    const nodeId = `${entity.ID}`;
    const nodeData = {
      id: nodeId,
      label: entity.Name,
      info: entity.Definition,
      references: entity.references,
      baseColor: entity.baseColor || "#ffffff",
      isRelationshipEntity: "false"
    };
    nodes.push({ data: nodeData });
    console.log(`Created node: ${nodeId} (Name: ${entity.Name})`);
  });
  
  // Compute a fixed width for all nodes.
  let maxWidth = 0;
  const padding = 10;
  nodes.forEach((node) => {
    const label = node.data.label || "";
    const width = getTextWidth(label, "16px serif") + padding;
    if (width > maxWidth) maxWidth = width;
  });
  nodes.forEach((node) => {
    node.data.nodeSize = maxWidth;
  });
  
  // Always use the continuous gradient logic for color assignment.
  nodes.forEach((node, index) => {
    if (node.data.baseColor.toLowerCase() === "#ffffff") {
      const totalNodes = nodes.length;
      const lightness = 70 - (totalNodes > 1 ? (index / (totalNodes - 1)) * 40 : 0);
      const grey = `hsl(0, 0%, ${lightness}%)`;
      node.data.color = grey;
      node.data.originalColor = grey;
      node.data.isDark = lightness < 50 ? "true" : "false";
    } else {
      node.data.color = node.data.baseColor;
      node.data.originalColor = node.data.baseColor;
      node.data.isDark = "false";
    }
  });
  
  return nodes;
};