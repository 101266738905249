// src/features/species/components/upload/dataParsers.ts

export function parseNumberOrNull(val: number | boolean | null): number | null {
    if (val === null) return null;
    if (typeof val === "boolean") return null;
    return val;
  }
  
  export function parseBoolOrNull(val: number | boolean | null): boolean | null {
    if (val === null) return null;
    if (typeof val === "number") return null;
    return val;
  }