import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Filter, Upload } from 'lucide-react'; // Added Upload icon

const Header = ({ user, onSignOut }) => {
  const location = useLocation();
  const isSpeciesPage = location.pathname.startsWith('/species');

  const linkBaseClasses =
    "px-4 py-2 text-sm font-medium transition flex items-center";
  const activeClasses = "opacity-90";
  const defaultClasses = "opacity-100 hover:opacity-90";

  const isActive = (path) => location.pathname === path;

  return (
    <header className="header-metal-navy text-white shrink-0 relative">
      <div className="w-full px-6 py-4 flex items-center justify-between">
        <div className="flex items-center w-40">
          <Link to={isSpeciesPage ? "/species" : "/home"}>
            <img
              src="/logo-2-2.png"
              alt="Logo"
              className="h-auto w-auto"
              style={{ maxHeight: '40px' }}
            />
          </Link>
        </div>

        <div className="flex items-center">
          {user ? (
            <>
              <Link
                to="/manage-account"
                className={`${linkBaseClasses} ${isActive('/manage-account') ? activeClasses : defaultClasses}`}
              >
                Account
              </Link>
              <button onClick={onSignOut} className={`${linkBaseClasses} ${defaultClasses}`}>
                Logout
              </button>
            </>
          ) : (
            <div className={`${linkBaseClasses} ${defaultClasses}`} style={{ visibility: 'hidden' }}>
              <span>Login</span>
            </div>
          )}
        </div>
      </div>
      {isSpeciesPage && (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center space-x-4">
          <Link
            to="/species"
            className={`${linkBaseClasses} ${isActive('/species') ? activeClasses : defaultClasses}`}
          >
            <Home className="mr-2 h-4 w-4" />
            <span>Home</span>
          </Link>
          <Link
            to="/species/upload"
            className={`${linkBaseClasses} ${isActive('/species/upload') ? activeClasses : defaultClasses}`}
          >
            <Upload className="mr-2 h-4 w-4" />
            <span>Upload</span>
          </Link>
          <Link
            to="/species/review"
            className={`${linkBaseClasses} ${isActive('/species/review') ? activeClasses : defaultClasses}`}
          >
            <Filter className="mr-2 h-4 w-4" />
            <span>Review</span>
          </Link>
          
        </div>
      )}
    </header>
  );
};

export default Header;