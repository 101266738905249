// src/core/handlers/graph_gen/build_brain/merge/mergeOntology.js
export function mergeOntologyData(safeOntology) {
  const merged = {
    Entities: { Entities: [] },
    Relationships: {
      TypeRelationships: [],
      AttributeRelationships: [],
      DefinitionMapping: []
    },
    RelationshipEntities: {
      AttributeRelationshipEntities: [],
      StructuralRelationshipEntities: []
    }
  };

  // Copy over relationship entities from ontology (and ensure ID is string)
  if (safeOntology.RelationshipEntities) {
    if (Array.isArray(safeOntology.RelationshipEntities.AttributeRelationshipEntities)) {
      merged.RelationshipEntities.AttributeRelationshipEntities =
        safeOntology.RelationshipEntities.AttributeRelationshipEntities.map(re => ({
          ...re,
          ID: String(re.ID)
        }));
    }
  }

  // Remap ontology node IDs
  const ontologyIdMap = new Map();
  safeOntology.Entities.Entities.forEach(node => {
    const newId = String(node.ID);
    ontologyIdMap.set(node.ID, newId);
    merged.Entities.Entities.push({ ...node, ID: newId });
  });

  // Merge ontology relationships for each type.
  ['TypeRelationships', 'AttributeRelationships', 'DefinitionMapping'].forEach(relType => {
    if (Array.isArray(safeOntology.Relationships[relType])) {
      safeOntology.Relationships[relType].forEach(rel => {
        merged.Relationships[relType].push({
          ...rel,
          ID: `edge_${String(rel.ID)}`, // prefix to avoid collision
          // Use the remapped node IDs
          SourceNodeID: ontologyIdMap.get(rel.SourceNodeID),
          TargetNodeID: ontologyIdMap.get(rel.TargetNodeID)
        });
      });
    }
  });

  return { merged, ontologyIdMap };
}