// src/api/apiService.js
import { auth } from '../firebase';

const API_BASE_URL = "https://atlaz-api.com";

/**
 * Generic function to make GET requests using the current user’s token.
 */
export async function getRequest(endpoint) {
    const token = await auth.currentUser.getIdToken(true);
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return response;
  }

/**
 * Generic POST request using the current user’s token.
 */
export async function postRequest(endpoint, payload) {
  const token = await auth.currentUser.getIdToken(true);
  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }
  return response;
}
