
  
  export function highlightMatchesOld(text, query) {
      // Convert < and > to HTML entities
      let safe = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
      
      // If no query, return safe text as-is (no \n -> <br/> here!)
      if (!query.trim()) {
        return safe;
      }
    
      // Escape special regex chars in query
      const escaped = query.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
      const regex = new RegExp(`(${escaped})`, 'gi');
    
      // Wrap matches in <mark>
      let replaced = safe.replace(regex, '<mark class="bg-green-300">$1</mark>');
      
      return replaced;
    }
   