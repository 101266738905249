// src/pages/MorphingPage.jsx
import React from 'react';
import InteractiveMarbleGraphHeart from '../../core/ui/general/demo/InteractiveMarbleGraphHeart';

const MorphingPage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
      <h1 className="text-3xl font-bold mb-6">Interactive Marble Stone Graph</h1>
      <InteractiveMarbleGraphHeart />
    </div>
  );
};

export default MorphingPage;