// src/organizations/species/pages/SpeciesCardsSection.tsx

import React from "react";
import { SpeciesCard } from "./SpeciesCard.tsx";

interface SpeciesCardsSectionProps {
  species: any[]; // adjust type as needed
  onDeleteSpecies: (speciesId: number) => void;
  showCards: boolean;
  onToggleShowCards: () => void;
}

export const SpeciesCardsSection: React.FC<SpeciesCardsSectionProps> = ({
  species,
  onDeleteSpecies,
  showCards,
  onToggleShowCards,
}) => {
  return (
    <div>
      <button
        onClick={onToggleShowCards}
        className="text-sm font-medium text-[#1d1d22] hover:text-[#1d1d22]/60 transition-colors"
      >
        {showCards ? "Hide Data" : "Show Data"}
      </button>
      {showCards && (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6 mt-4">
          {species.map((sp) => (
            <SpeciesCard key={sp.id} species={sp} onDelete={onDeleteSpecies} />
          ))}
        </div>
      )}
    </div>
  );
};