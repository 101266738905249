import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDyakcwGBTcYRqCvBC2Rb5_7M_Fm-8Fsa0",
  authDomain: "atlaz-bbd30.firebaseapp.com",
  projectId: "atlaz-bbd30",
  storageBucket: "atlaz-bbd30.firebasestorage.app",
  messagingSenderId: "711381341812",
  appId: "1:711381341812:web:39ba07e3a7b11013caa321",
  measurementId: "G-Q2KGQGQJP2"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);