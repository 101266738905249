// src/core/helpers/graph_gen/common/knowledge_graph/prepareFilteredGraphElements.js
import { prepareNewGraphElements } from './prepareNewGraphElements';

export function prepareFilteredGraphElements(data, colorMode = "colorful") {
  console.log("[PFGE] prepareFilteredGraphElements called. colorMode:", colorMode);

  // Pass colorMode so that prepareNewGraphElements uses the proper mode
  const allElements = prepareNewGraphElements(data, colorMode);

  const nodes = allElements.filter(el => el.data && !el.data.source);
  const edges = allElements.filter(el => el.data && el.data.source);
  const connectedNodeIds = new Set();
  edges.forEach(edge => {
    connectedNodeIds.add(edge.data.source);
    connectedNodeIds.add(edge.data.target);
  });

  const filteredNodes = nodes.filter(node => connectedNodeIds.has(node.data.id));
  const finalElements = [...filteredNodes, ...edges];

  console.log(
    `[PFGE] After filtering: ${filteredNodes.length} nodes, ${edges.length} edges.`
  );

  return finalElements;
}