import React from 'react';
import PropTypes from 'prop-types';
import Button from '../general/controls/Button';
import { MdTune } from 'react-icons/md';

const GraphGeneratorOld = ({
  isProcessing,
  status,
  onSubmit,
  showCustomization,
  onToggleCustomization,
  customizationText,
  onCustomizationChange,
  graphData,
  selectedElementData,
  onElementSelect,
}) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Button onClick={onSubmit} disabled={isProcessing} variant="success">
            {isProcessing ? 'Processing...' : 'Generate Graph'}
          </Button>
          <button
            onClick={onToggleCustomization}
            title="Toggle customization"
            className="focus:outline-none"
            disabled={isProcessing}
          >
            <MdTune className="text-success" size={24} />
          </button>
        </div>
        <span className="text-gray-600">{status}</span>
      </div>

      {showCustomization && (
        <textarea
          className="w-full bg-white rounded p-2 overflow-auto border border-white focus:outline-none focus:border-success focus:ring-0 mt-2 h-20"
          value={customizationText}
          onChange={onCustomizationChange}
          disabled={isProcessing}
          placeholder="Optional - Specify key entities or relationships you want to focus."
        />
      )}
    </div>
  );
};

GraphGeneratorOld.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showCustomization: PropTypes.bool.isRequired,
  onToggleCustomization: PropTypes.func.isRequired,
  customizationText: PropTypes.string.isRequired,
  onCustomizationChange: PropTypes.func.isRequired,
  graphData: PropTypes.object,
  selectedElementData: PropTypes.object,
  onElementSelect: PropTypes.func.isRequired,
  availableOntologies: PropTypes.array,
  selectedOntology: PropTypes.object,
  setSelectedOntology: PropTypes.func,
};

export default GraphGeneratorOld;