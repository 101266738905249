import React from 'react';
import PropTypes from 'prop-types';

const EdgeDetails = ({ selectedElementData, graphNodes }) => {
  const getNumericID = (id) => {
    return parseInt(id, 10);
  };

  const getEntityName = (id) => {
    if (id == null) return 'Unknown';
    const numericID = getNumericID(id);
    const found = graphNodes.find((ent) => getNumericID(ent.ID) === numericID);
    return found ? found.Name : String(id);
  };

  let computedName = '';
  let computedType = '';

  if (selectedElementData.relationshipType === 'DefinitionMapping') {
    computedName = selectedElementData.label;
    computedType = 'Relationship Definition';
  } else {
    const sourceName = getEntityName(selectedElementData.source);
    const targetName = getEntityName(selectedElementData.target);
    const relEntityName =
      selectedElementData.relationshipEntityName ||
      selectedElementData.label ||
      'Relationship';
    computedName = `${sourceName} - ${relEntityName} - ${targetName}`;
    computedType = relEntityName;
  }

  return (
    <div>
      <p>
        <strong>Name:</strong> {computedName}
      </p>
      <p>
        <strong>Type:</strong> {computedType}
      </p>
      <p>
        <strong>Info:</strong> {selectedElementData.info}
      </p>
    </div>
  );
};

EdgeDetails.propTypes = {
  selectedElementData: PropTypes.object.isRequired,
  graphNodes: PropTypes.array.isRequired,
};

export default EdgeDetails;