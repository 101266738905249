// src/components/graph_gen/manual_graph/GraphEditorManual.jsx
import React, { forwardRef } from 'react';
import GraphVisualization from '../../../ui/graph_gen/manual_graph/GraphVisualizationManual';
import SelectedElementPanel from '../../../ui/graph_gen/manual_graph/SelectedElementPanelManual';
import NewOntologyButton from '../../../ui/graph_gen/manual_graph/NewOntologyButtonManual';
import AddEntityPopoverManual from './AddEntityPopoverManual';
import { addTypeRelationshipHandlerManual, addAttributeRelationshipInstanceHandlerManual } from '../../../handlers/graph_gen/manual_graph/graphHandlersManual';

const GraphEditorManual = forwardRef(({
  allGenerations,
  selectedGenerationId,
  setSelectedGenerationId,
  colorMode,
  setColorMode,
  handleAddNode,
  handleAddRelationshipEntity,
  transformedData,
  safeData,
  updateGraphData,
  handleElementSelect,
  overlayProps,
}, ref) => {
  // Filter node options to exclude relationship entities
  const nodeOptions = (safeData?.Entities?.Entities || []).filter(
    (entity) => !entity.isRelationshipEntity
  );

  // Get relationship definitions for attribute relationships from safeData.
  const relationshipDefinitionOptions = safeData?.RelationshipEntities?.AttributeRelationshipEntities || [];

  return (
    <div className="relative">
      <NewOntologyButton updateGraphData={updateGraphData} />

      {/* Place the popover button on the right side */}
      <div className="absolute top-4 right-4 z-50">
        <AddEntityPopoverManual
          onAddNode={(nodeData) => handleAddNode(nodeData)}
          onAddRelationship={(entityData) => handleAddRelationshipEntity(entityData)}
          onAddTypeRelationship={(relationshipData) => addTypeRelationshipHandlerManual(relationshipData, updateGraphData)}
          onAddAttributeInstance={(data) => addAttributeRelationshipInstanceHandlerManual(data, updateGraphData)}
          nodeOptions={nodeOptions}
          relationshipDefinitionOptions={relationshipDefinitionOptions}
        />
      </div>

      <GraphVisualization
        ref={ref}
        graphData={transformedData}
        graphHeight={600}
        onElementSelect={handleElementSelect}
        overlayProps={overlayProps}
      />

      <SelectedElementPanel
        selectedElementData={overlayProps.selectedElementData}
        overlayProps={overlayProps}
      />

      <div className="mt-4 p-4 bg-gray-100 border rounded">
        <h2 className="text-lg font-bold mb-2">Graph JSON</h2>
        <pre className="overflow-auto text-xs">
          {JSON.stringify(transformedData, null, 2)}
        </pre>
      </div>
    </div>
  );
});

export default GraphEditorManual;