// src/core/helpers/graph_gen/common/myCustomLayout.js
import Cytoscape from 'cytoscape';

// Define your custom layout constructor.
function MyCustomLayout(options) {
  this.options = options;
  this.cy = options.cy;
}

MyCustomLayout.prototype.run = function () {
  const cy = this.cy;
  // 1. Basic grouping: Group nodes by Type relationships.
  const allNodes = cy.nodes();
  const typeEdges = cy.edges().filter(edge => edge.data('relationshipType') === 'Type');
  const groups = {};
  typeEdges.forEach(edge => {
    const subtype = edge.source();
    const supertype = edge.target();
    const key = supertype.id();
    if (!groups[key]) {
      groups[key] = { supertype, subtypes: [] };
    }
    groups[key].subtypes.push(subtype);
  });
  // For nodes not in any group, create a group for themselves.
  allNodes.forEach(node => {
    let inGroup = false;
    for (let key in groups) {
      if (
        groups[key].supertype.id() === node.id() ||
        groups[key].subtypes.some(sub => sub.id() === node.id())
      ) {
        inGroup = true;
        break;
      }
    }
    if (!inGroup) {
      groups[node.id()] = { supertype: node, subtypes: [] };
    }
  });

  // 2. Basic layout: assign each group a fixed column and initial vertical positions.
  const groupKeys = Object.keys(groups);
  const columnGap = 300;
  const startingX = 100;
  const initialY = 100; // baseline top position for supertype
  const universalHeight = 30; // default height if none provided
  const gap = 10; // desired vertical gap
  groupKeys.forEach((key, i) => {
    const group = groups[key];
    const x = startingX + i * columnGap;
    // Place the supertype.
    group.supertype.position({ x, y: initialY });
    // Arrange subtypes sequentially.
    let currentY = initialY + universalHeight + gap;
    group.subtypes.forEach(subtype => {
      const nodeHeight = subtype.data('nodeHeight') || universalHeight;
      subtype.position({ x, y: currentY });
      subtype.initialCenter = currentY + nodeHeight / 2;
      currentY += nodeHeight + gap;
    });
  });

  // 3. Identify specific groups and adjust positions if both exist.
  let startupGroup = null;
  let foundingGroup = null;
  groupKeys.forEach(key => {
    const group = groups[key];
    const label = group.supertype.data('label');
    if (label === 'Startup') {
      startupGroup = group;
    } else if (label === 'Founding Year of Startup') {
      foundingGroup = group;
    }
  });

  if (startupGroup && foundingGroup) {
    let startupRightBoundary = -Infinity;
    [startupGroup.supertype, ...startupGroup.subtypes].forEach(node => {
      const nodeWidth = node.data('nodeWidth') || 100;
      const nodeRight = node.position().x + nodeWidth;
      if (nodeRight > startupRightBoundary) startupRightBoundary = nodeRight;
    });
    const gapBetweenGroups = 20;
    const newFoundingX = startupRightBoundary + gapBetweenGroups;
    foundingGroup.supertype.position({ x: newFoundingX, y: foundingGroup.supertype.position().y });
    foundingGroup.subtypes.forEach(node => {
      node.position({ x: newFoundingX, y: node.position().y });
    });
  }

  // 4. Adjust positions for attribute edges.
  let attributeEdges = [];
  if (startupGroup && foundingGroup) {
    attributeEdges = cy.edges().filter(edge => {
      const type = edge.data('relationshipType');
      return type === 'Defined' || type === 'DefinitionMapping';
    }).filter(edge => {
      const src = edge.source();
      const tgt = edge.target();
      return (
        (startupGroup.subtypes.includes(src) && foundingGroup.subtypes.includes(tgt)) ||
        (startupGroup.subtypes.includes(tgt) && foundingGroup.subtypes.includes(src))
      );
    });
  }

  // 5. Iterative adjustments for alignment and collision resolution.
  const iterations = 20;
  const learningRate = 0.5;
  for (let iter = 0; iter < iterations; iter++) {
    attributeEdges.forEach(edge => {
      const src = edge.source();
      const tgt = edge.target();
      const srcHeight = src.data('nodeHeight') || universalHeight;
      const tgtHeight = tgt.data('nodeHeight') || universalHeight;
      const srcCenter = src.position().y + srcHeight / 2;
      const tgtCenter = tgt.position().y + tgtHeight / 2;
      const diff = srcCenter - tgtCenter;
      const adjustment = diff * learningRate;
      src.position({ y: src.position().y - adjustment / 2 });
      tgt.position({ y: tgt.position().y + adjustment / 2 });
    });

    const resolveCollisions = (nodes) => {
      nodes.sort((a, b) => a.position().y - b.position().y);
      for (let i = 1; i < nodes.length; i++) {
        const prev = nodes[i - 1];
        const curr = nodes[i];
        const prevHeight = prev.data('nodeHeight') || universalHeight;
        const currHeight = curr.data('nodeHeight') || universalHeight;
        const prevBottom = prev.position().y + prevHeight;
        if (curr.position().y < prevBottom + gap) {
          const overlap = (prevBottom + gap) - curr.position().y;
          curr.position({ y: curr.position().y + overlap });
        }
      }
    };

    if (startupGroup) resolveCollisions(startupGroup.subtypes);
    if (foundingGroup) resolveCollisions(foundingGroup.subtypes);
  }

  // 6. Process one-to-one attribute relationships.
  const oneToOneEdges = cy.edges().filter(edge => edge.data('oneToOne') === true);
  oneToOneEdges.forEach(edge => {
    const source = edge.source();
    const target = edge.target();
    const sourceWidth = source.data('nodeWidth') || 100;
    const targetWidth = target.data('nodeWidth') || 100;
    const targetPos = target.position();
    const newSourceX = targetPos.x - (sourceWidth + targetWidth) / 2;
    source.position({ x: newSourceX, y: targetPos.y });
  });

  this.cy.emit('layoutstop');
};

MyCustomLayout.prototype.stop = function () {
  // Optional cleanup.
};

// Register the custom layout with Cytoscape.
Cytoscape('layout', 'myCustomLayout', MyCustomLayout);