
import React, { useState, useMemo, useLayoutEffect, useRef } from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw"
import { highlightMultipleMatches2Species } from "./highlightMatchesSpecies.js"

interface PDFViewerProps {
  markdown: string
  highlightText?: string | null
  highlightVersion?: number
}

export const PDFViewer = ({ markdown, highlightText, highlightVersion }: PDFViewerProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const containerRef = useRef<HTMLDivElement>(null)

  // Build an array of query strings from both highlightText and searchTerm
  const queries = useMemo(() => {
    const queriesArray: string[] = []
    if (highlightText?.trim()) {
      queriesArray.push(highlightText.trim())
    }
    if (searchTerm.trim()) {
      queriesArray.push(searchTerm.trim())
    }
    return queriesArray
  }, [highlightText, searchTerm])

  // Process the markdown using the new highlight function.
  // Including highlightVersion forces recalculation when switching data points.
  const processedMarkdown = useMemo(() => {
    return highlightMultipleMatches2Species(markdown, queries)
  }, [markdown, queries, highlightVersion])

  // Use a layout effect with a slight delay to ensure the updated <mark> elements are in place.
  useLayoutEffect(() => {
    if ((highlightText?.trim() || searchTerm.trim()) && containerRef.current) {
      // Delay scrolling a bit to allow for final layout updates.
      setTimeout(() => {
        const container = containerRef.current
        if (!container) return
        const firstMark = container.querySelector("mark")
        if (firstMark) {
          // Use the browser's built-in scrolling.
          firstMark.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }, 50) // You can tweak this delay if needed.
    }
  }, [highlightText, searchTerm, processedMarkdown, highlightVersion])

  return (
    <div
      className="border rounded bg-gray-50 p-4 max-h-[600px] overflow-auto"
      ref={containerRef}
    >
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search in document..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>
      <ReactMarkdown
        children={processedMarkdown}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
      />
    </div>
  )
}

export default PDFViewer