// src/features/species/components/details/SpeciesDetailHeader.tsx
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../common/button.tsx";
import { ArrowLeft } from "lucide-react";

export default function SpeciesDetailHeader({ species }) {
  return (
    <div className="flex items-center space-x-4">
      <Link to="/species">
        <Button variant="outline" size="sm" className="flex items-center">
          <ArrowLeft className="mr-1 h-4 w-4" />
          Back
        </Button>
      </Link>
      <h1 className="text-xl sm:text-2xl font-bold">
        {species.name} (<em>{species.latinName}</em>)
      </h1>
    </div>
  );
}