import React from 'react';
import ElementInfoOverlay from '../common/element_info_overlay/ElementInfoOverlay';

const SelectedElementPanel = ({ selectedElementData, overlayProps }) => {
  if (!selectedElementData) return null;
  return (
    <div className="absolute top-20 right-4 w-64 z-50">
      <ElementInfoOverlay {...overlayProps} selectedElementData={selectedElementData} />
    </div>
  );
};

export default SelectedElementPanel;