// src/utils/graph_gen/modeModifiers/KnowledgeDataModifier.js

export default function modifyDataKnowledge(rawData) {
  // Deep clone the raw data so we don't modify it directly.
  const dataCopy = JSON.parse(JSON.stringify(rawData));

  // 1) Remove the generic entity with ID 1.
  dataCopy.Entities.Entities = dataCopy.Entities.Entities.filter(
    (entity) => entity.ID !== 1
  );

  // 2) Add relationship entity nodes just like Extensive mode.
  const relEntityNodes = [];
  if (dataCopy.RelationshipEntities) {
    if (Array.isArray(dataCopy.RelationshipEntities.AttributeRelationshipEntities)) {
      dataCopy.RelationshipEntities.AttributeRelationshipEntities.forEach((relEntity) => {
        // Optionally skip certain IDs if needed.
        if ([100, 101, 102].includes(relEntity.ID)) return;
        relEntityNodes.push({
          ID: relEntity.ID,
          Name: relEntity.Name,
          Definition: relEntity.Definition,
          references: relEntity.references,
          isRelationshipEntity: true,
        });
      });
    }
    if (Array.isArray(dataCopy.RelationshipEntities.StructuralRelationshipEntities)) {
      dataCopy.RelationshipEntities.StructuralRelationshipEntities.forEach((relEntity) => {
        if ([100, 101, 102].includes(relEntity.ID)) return;
        relEntityNodes.push({
          ID: relEntity.ID,
          Name: relEntity.Name,
          Definition: relEntity.Definition,
          references: relEntity.references,
          isRelationshipEntity: true,
        });
      });
    }
  }
  // Concatenate the relationship entity nodes to the main entities list.
  dataCopy.Entities.Entities = dataCopy.Entities.Entities.concat(relEntityNodes);

  // Helper function to remove transitive edges.
  function removeTransitiveEdges(edges) {
    const adj = {};
    edges.forEach((edge) => {
      const { SourceNodeID, TargetNodeID } = edge;
      if (!adj[SourceNodeID]) adj[SourceNodeID] = [];
      adj[SourceNodeID].push(TargetNodeID);
    });
    // Returns true if there exists an alternate (≥ 2-hop) path from source to target.
    const hasAlternatePath = (source, target) => {
      const visited = new Set();
      const stack = [];
      if (adj[source]) {
        for (const neighbor of adj[source]) {
          if (neighbor !== target) stack.push(neighbor);
        }
      }
      while (stack.length > 0) {
        const node = stack.pop();
        if (node === target) return true;
        if (!visited.has(node)) {
          visited.add(node);
          if (adj[node]) {
            for (const nb of adj[node]) {
              stack.push(nb);
            }
          }
        }
      }
      return false;
    };

    return edges.filter((edge) => {
      const { SourceNodeID, TargetNodeID } = edge;
      return !hasAlternatePath(SourceNodeID, TargetNodeID);
    });
  }

  // 3) In Knowledge mode, use TypeRelationships to assign each entity’s Type.
  //    First, filter out transitive edges so only direct relationships are considered.
  if (dataCopy.Relationships && Array.isArray(dataCopy.Relationships.TypeRelationships)) {
    const directTypeEdges = removeTransitiveEdges(dataCopy.Relationships.TypeRelationships);
    // Preserve the direct type relationships in a hidden property for computing node type.
    dataCopy.Relationships.HiddenTypeRelationships = directTypeEdges;
    directTypeEdges.forEach((rel) => {
      const sourceEntity = dataCopy.Entities.Entities.find(
        (entity) => entity.ID === rel.SourceNodeID
      );
      const targetEntity = dataCopy.Entities.Entities.find(
        (entity) => entity.ID === rel.TargetNodeID
      );
      if (sourceEntity && targetEntity) {
        // Assign the source entity's Name as the Type for the target, if not already set.
        if (!targetEntity.Type) {
          targetEntity.Type = sourceEntity.Name;
        }
      }
    });
    // Now remove the TypeRelationships so they are not displayed.
    dataCopy.Relationships.TypeRelationships = [];
  }

  // 4) Update AttributeRelationships so that if relationshipEntityName is missing,
  //    we capitalize the provided Name (to mimic Extensive mode).
  if (dataCopy.Relationships && Array.isArray(dataCopy.Relationships.AttributeRelationships)) {
    dataCopy.Relationships.AttributeRelationships = dataCopy.Relationships.AttributeRelationships.map((rel) => {
      if (!rel.relationshipEntityName && rel.Name) {
        rel.relationshipEntityName = rel.Name.charAt(0).toUpperCase() + rel.Name.slice(1);
      }
      return rel;
    });
  }

  // Note: Do NOT filter out any nodes based on connectivity.
  // In Extensive mode, all nodes (and relationship entity nodes) are preserved.

  // 5) Also, do not filter out any relationships (Attribute or DefinitionMapping)
  //    based on whether both endpoints exist—the raw data is preserved.

  return dataCopy;
}