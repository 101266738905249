import React from 'react';
import { 
  FaFileAlt, 
  FaDatabase, 
  FaFileExcel, 
  FaGlobe, 
  FaBrain, 
  FaFlask, // laboratory icon
  FaFileCsv 
} from 'react-icons/fa';
import { SiJson } from 'react-icons/si';

const KnowledgeGraphWithFiles = () => {
  // Array of file icons arranged around the circle
  const icons = [
    { Component: FaFileAlt, title: 'Text File' },
    { Component: FaDatabase, title: 'Database' },
    { Component: FaFileExcel, title: 'Spreadsheet' },
    { Component: FaGlobe, title: 'Internet' },
    { Component: FaFlask, title: 'Laboratory' },
    { Component: SiJson, title: 'JSON' },
    { Component: FaFileCsv, title: 'CSV' },
  ];

  // Container configuration
  const containerSize = 250;
  const center = containerSize / 2;

  // Central icon configuration:
  const centralIconSize = 60; // base size for the central element
  const brainIconSize = centralIconSize * 0.8; // actual brain icon size
  const brainRadius = brainIconSize / 2;
  // Overlap offset: curves will start this many pixels inside the brain icon edge
  const overlapOffset = 4;
  const adjustedBrainRadius = brainRadius - overlapOffset;

  // Icon placement configuration for peripheral icons
  const iconRadius = 50; // distance from container center to peripheral icon center
  const iconSize = 20;
  const gap = 5;
  const iconOffset = iconSize / 2 + gap; // offset for peripheral icon connection endpoint
  const ringTangentOffset = 3;          // offset along the brain icon's edge

  // The amount (in pixels) to pull the control point in the radial direction.
  const curveOffset = 35;

  return (
    <div className="relative mx-auto" style={{ width: containerSize, height: containerSize, marginTop: '95px'  }}>
      {/* Title */}
      <div
        className="absolute font-bold text-[#1d1d22] text-xl"
        style={{
          top: '8px',
          left: '50%',
          transform: 'translateX(-50%)',
          whiteSpace: 'nowrap',
        }}
      >
        Connect your data
      </div>

  

      {/* Central brain icon, centered without a background circle */}
      <div
        className="absolute"
        style={{
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <FaBrain size={brainIconSize} color="#1d1d22" />
      </div>

      {/* Peripheral icons arranged around the circle */}
      {icons.map((icon, i) => {
        const baseAngleDeg = (360 / icons.length) * i - 90;
        const baseAngleRad = (Math.PI / 180) * baseAngleDeg;
        const x = center + iconRadius * Math.cos(baseAngleRad);
        const y = center + iconRadius * Math.sin(baseAngleRad);
        const offset = iconSize / 2;
        return (
          <div
            key={i}
            className="absolute"
            style={{
              left: x - offset,
              top: y - offset,
              width: iconSize,
              height: iconSize,
            }}
          >
            <icon.Component size={iconSize} color="#1d1d22" title={icon.title} />
          </div>
        );
      })}
    </div>
  );
};

export default KnowledgeGraphWithFiles;