import React from 'react';
import { 
  HiOutlineLightBulb, 
  HiOutlineOfficeBuilding, // Updated from HiOutlineBuildingOffice2
  HiOutlinePencil, // Replaced HiOutlinePencilSquare with HiOutlinePencil
  HiOutlineCubeTransparent, 
  HiOutlineSparkles 
} from 'react-icons/hi';
import { 
  FiEdit3, 
  FiPenTool, 
  FiZap, 
  FiLayers, 
  FiSettings, 
  FiSliders, 
  FiHome, 
  FiCast, 
  FiGrid, 
  FiMonitor,
  FiCamera,
  FiBookOpen,
  FiBookmark,
  FiAward
} from 'react-icons/fi';
import { 
  RiLightbulbFlashFill, 
  RiLightbulbLine, 
  RiPencilFill, 
  RiBuilding3Fill, 
  RiPaintBrushFill 
  // Removed RiSketching2Fill as it is unavailable
} from 'react-icons/ri';
import { 
  MdOutlineDesignServices, 
  MdArchitecture, 
  MdOutlineHome, 
  MdOutlineApartment, 
  MdOutlineBusiness, 
  MdDesignServices 
} from 'react-icons/md';
import { 
  FaPencilAlt, 
  FaPaintBrush, 
  FaRegLightbulb, 
  FaLightbulb, 
  FaBrain 
} from 'react-icons/fa';
import { 
  GiBrain, 
  GiPencilBrush, 
  GiPaintRoller, 
  GiModernCity, 
  GiCastle, 
  GiArchBridge, 
  GiHouse, 
  GiBrickWall, 
  GiFactory, 
  GiWhiteTower, 
  GiEgyptianPyramids 
} from 'react-icons/gi';
import { VscLightbulb } from 'react-icons/vsc';
import { TiLightbulb } from 'react-icons/ti';
import { FcIdea } from 'react-icons/fc';

const modernIcons = [
  { Component: HiOutlineLightBulb, title: "HiOutlineLightBulb" },
  { Component: HiOutlineOfficeBuilding, title: "HiOutlineOfficeBuilding" },
  { Component: HiOutlinePencil, title: "HiOutlinePencil" },
  { Component: HiOutlineCubeTransparent, title: "HiOutlineCubeTransparent" },
  { Component: HiOutlineSparkles, title: "HiOutlineSparkles" },
  { Component: FiEdit3, title: "FiEdit3" },
  { Component: FiPenTool, title: "FiPenTool" },
  { Component: FiZap, title: "FiZap" },
  { Component: FiLayers, title: "FiLayers" },
  { Component: FiSettings, title: "FiSettings" },
  { Component: FiSliders, title: "FiSliders" },
  { Component: FiHome, title: "FiHome" },
  { Component: FiCast, title: "FiCast" },
  { Component: FiGrid, title: "FiGrid" },
  { Component: FiMonitor, title: "FiMonitor" },
  { Component: FiCamera, title: "FiCamera" },
  { Component: FiBookOpen, title: "FiBookOpen" },
  { Component: FiBookmark, title: "FiBookmark" },
  { Component: FiAward, title: "FiAward" },
  { Component: RiLightbulbFlashFill, title: "RiLightbulbFlashFill" },
  { Component: RiLightbulbLine, title: "RiLightbulbLine" },
  { Component: RiPencilFill, title: "RiPencilFill" },
  { Component: RiBuilding3Fill, title: "RiBuilding3Fill" },
  { Component: RiPaintBrushFill, title: "RiPaintBrushFill" },
  { Component: MdOutlineDesignServices, title: "MdOutlineDesignServices" },
  { Component: MdArchitecture, title: "MdArchitecture" },
  { Component: MdOutlineHome, title: "MdOutlineHome" },
  { Component: MdOutlineApartment, title: "MdOutlineApartment" },
  { Component: MdOutlineBusiness, title: "MdOutlineBusiness" },
  { Component: MdDesignServices, title: "MdDesignServices" },
  { Component: FaPencilAlt, title: "FaPencilAlt" },
  { Component: FaPaintBrush, title: "FaPaintBrush" },
  { Component: FaRegLightbulb, title: "FaRegLightbulb" },
  { Component: FaLightbulb, title: "FaLightbulb" },
  { Component: FaBrain, title: "FaBrain" },
  { Component: GiBrain, title: "GiBrain" },
  { Component: GiPencilBrush, title: "GiPencilBrush" },
  { Component: GiPaintRoller, title: "GiPaintRoller" },
  { Component: GiModernCity, title: "GiModernCity" },
  { Component: GiCastle, title: "GiCastle" },
  { Component: GiArchBridge, title: "GiArchBridge" },
  { Component: GiHouse, title: "GiHouse" },
  { Component: GiBrickWall, title: "GiBrickWall" },
  { Component: GiFactory, title: "GiFactory" },
  { Component: GiWhiteTower, title: "GiWhiteTower" },
  { Component: GiEgyptianPyramids, title: "GiEgyptianPyramids" },
  { Component: VscLightbulb, title: "VscLightbulb" },
  { Component: TiLightbulb, title: "TiLightbulb" },
  { Component: FcIdea, title: "FcIdea" },
];

const colors = [
  "#1d1d22", "#ff5733", "#33c3ff", "#28a745",
  "#ffc107", "#6f42c1", "#e83e8c", "#20c997"
];

const ModernElegantDesignIcons = () => {
  const variants = [];
  let count = 0;
  // Cycle through the modernIcons array until we have 50 variants.
  while (variants.length < 50) {
    for (let i = 0; i < modernIcons.length && variants.length < 50; i++) {
      const color = colors[count % colors.length];
      variants.push({
        Component: modernIcons[i].Component,
        title: modernIcons[i].title + " " + (count + 1),
        color: color,
      });
      count++;
    }
  }

  const iconSize = 32;
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">
        Modern Elegant Design Icons for Architecture &amp; Innovation
      </h2>
      <div className="grid grid-cols-5 gap-4">
        {variants.map((variant, index) => {
          const IconComponent = variant.Component;
          return (
            <div key={index} className="flex flex-col items-center">
              <IconComponent size={iconSize} color={variant.color} title={variant.title} />
              <span className="text-xs mt-1 text-center">{variant.title}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ModernElegantDesignIcons;