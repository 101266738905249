// src/core/helpers/graph_gen/common/knowledge_graph/prepare_new_graph_elements/prepareDefinitionMappingEdges.js

export const prepareDefinitionMappingEdges = (data) => {
  const edges = [];
  if (data.Relationships && Array.isArray(data.Relationships.DefinitionMapping)) {
    data.Relationships.DefinitionMapping.forEach(edge => {
      edges.push({ 
        data: { 
          ...edge, 
          // Set edge color explicitly to black
          color: "#000000",
        } 
      });
    });
  }
  if (data.RelationshipEntities && Array.isArray(data.RelationshipEntities.AttributeRelationshipEntities)) {
    data.RelationshipEntities.AttributeRelationshipEntities.forEach((defEntity) => {
      const exists = edges.some(e => String(e.data.id) === String(defEntity.ID));
      if (!exists) {
        edges.push({
          data: {
            id: String(defEntity.ID),
            source: String(defEntity.SourceNodeTypeID),
            target: String(defEntity.TargetNodeTypeID),
            label: defEntity.Name,
            relationshipType: "DefinitionMapping",
            PluralityType: defEntity.PluralityType,
            // Force edge color to black
            color: "#000000",
          }
        });
      }
    });
  }
  return edges;
};