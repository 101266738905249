import React, { useState } from "react";
import { flushSync } from "react-dom";
import { saveExtractedData, deleteExtractionStatus } from "../../../services/api.ts";
import { ReviewTable, TableRowData } from "./ReviewTable.tsx";
import { ReviewDetailsPanel } from "./ReviewDetailsPanel.tsx";
import { PDFPreviewPanel } from "./PDFPreviewPanel.tsx";
import { buildExtractedDataPayload } from "../../home/upload/PayloadBuilder.ts";
import { Check } from "lucide-react";

interface FinalChunk {
  data?: TableRowData[];
  markdown?: string;
  pdf_key?: string;
  status?: string;
}

interface ReviewFormProps {
  extractedData: FinalChunk | null;
  selectedFile: File;
  speciesName: string;
  onConfirm: () => void;
}

const nameMapping: Record<string, string> = {
  Extant_Populations: "Extant Populations",
  Extinct_Populations: "Extinct Populations",
  Population_Sizes_Lower_Bound: "Population Sizes (Lower Bound)",
  Population_Sizes_Upper_Bound: "Population Sizes (Upper Bound)",
  Population_Less_Than_5000_Individuals: "Populations - Less Than 5000 Individuals",
  Population_More_Than_5000_Individuals: "Populations - More Than 5000 Individuals",
  Phylogenetic_Geographic_Studies: "Phylogenetic/Geographic Studies",
  Population_Genetic_Studies: "Population Genetic Studies",
  DNA_based_Temporal_Monitoring: "DNA-based Temporal Monitoring",
  Dispersal_Distance: "Dispersal Distance",
  Generation_Time: "Generation Time",
  Count_Each_Extant_Population: "Number of individuals in each population",
};

const fieldTypes: Record<string, "number" | "boolean" | "array"> = {
  Extant_Populations: "number",
  Extinct_Populations: "number",
  Population_Sizes_Lower_Bound: "number",
  Population_Sizes_Upper_Bound: "number",
  Population_Less_Than_5000_Individuals: "number",
  Population_More_Than_5000_Individuals: "number",
  Phylogenetic_Geographic_Studies: "boolean",
  Population_Genetic_Studies: "boolean",
  DNA_based_Temporal_Monitoring: "boolean",
  Dispersal_Distance: "number",
  Generation_Time: "number",
  Count_Each_Extant_Population: "array",
};

export const ReviewForm = ({
  extractedData,
  selectedFile,
  speciesName,
  onConfirm,
}: ReviewFormProps) => {
  const safeData = Array.isArray(extractedData?.data) ? extractedData.data : [];
  const safeMarkdown = extractedData?.markdown ?? "";
  const initialTableData = Object.keys(nameMapping).map((key) => {
    const found = safeData.find((row) => row.Name === key);
    return found || { Name: key, DataPoint: "", Source: "", Motivation: "" };
  });

  const [tableData, setTableData] = useState<TableRowData[]>(initialTableData);
  const [editIndexes, setEditIndexes] = useState<Record<number, boolean>>({});
  const [sourceIndex, setSourceIndex] = useState<number | null>(null);
  const [selectedSourceText, setSelectedSourceText] = useState<string | null>(null);
  const [highlightVersion, setHighlightVersion] = useState(0);

  const handleDataPointChange = (rowIndex: number, newValue: string) => {
    setTableData((prev) => {
      const updated = [...prev];
      const key = updated[rowIndex].Name;
      const expectedType = fieldTypes[key] || "number";

      if (expectedType === "boolean") {
        updated[rowIndex].DataPoint =
          !newValue.trim() ? null : newValue.toLowerCase() === "yes";
      } else if (expectedType === "array") {
        updated[rowIndex].DataPoint = newValue
          .split(",")
          .map((s) => s.trim())
          .filter((s) => s !== "");
      } else {
        if (!newValue.trim()) {
          updated[rowIndex].DataPoint = null;
        } else {
          const numericValue = Number(newValue);
          updated[rowIndex].DataPoint =
            isNaN(numericValue) ? null : numericValue;
        }
      }
      return updated;
    });
  };

  const handleEditClick = (rowIndex: number) => {
    setEditIndexes((prev) => ({ ...prev, [rowIndex]: !prev[rowIndex] }));
  };

  const handleInfoClick = (rowIndex: number) => {
    const newSource = tableData[rowIndex].Source;
    flushSync(() => {
      setSelectedSourceText(null);
      setSourceIndex(null);
      setHighlightVersion((prev) => prev + 1);
    });
    flushSync(() => {
      setSelectedSourceText(newSource);
      setSourceIndex(rowIndex);
      setHighlightVersion((prev) => prev + 1);
    });
  };

  const handleDeleteClick = (rowIndex: number) => {
    setTableData((prev) => prev.filter((_, idx) => idx !== rowIndex));
    if (sourceIndex === rowIndex) {
      setSourceIndex(null);
      setSelectedSourceText(null);
    } else if (sourceIndex !== null && rowIndex < sourceIndex) {
      setSourceIndex((old) => (old != null ? old - 1 : null));
    }
    setEditIndexes((prev) => {
      const updated = { ...prev };
      delete updated[rowIndex];
      const newEdit: Record<number, boolean> = {};
      Object.keys(updated).forEach((k) => {
        const idx = Number(k);
        newEdit[idx > rowIndex ? idx - 1 : idx] = updated[k];
      });
      return newEdit;
    });
  };

  async function handleSaveExtractedData() {
    try {
      const pdfKey = extractedData?.pdf_key ?? "";
      const payload = buildExtractedDataPayload(tableData, pdfKey, speciesName);

      const result = await saveExtractedData(payload);
      if (extractedData?.extraction_id) {
        try {
          await deleteExtractionStatus(extractedData.extraction_id);
        } catch (delErr) {
          // Handle deletion error if needed.
        }
      }
      onConfirm();
    } catch (err) {
      console.error("[ReviewForm] handleSaveExtractedData error:", err);
      alert("Failed to save data. See console for details.");
    }
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Left Column: Table and Details */}
        <div>
          {tableData.length > 0 ? (
            <ReviewTable
              tableData={tableData}
              editIndexes={editIndexes}
              sourceIndex={sourceIndex}
              nameMapping={nameMapping}
              fieldTypes={fieldTypes} // Pass the mapping here
              onDataPointChange={handleDataPointChange}
              onEditClick={handleEditClick}
              onInfoClick={handleInfoClick}
              onDeleteClick={handleDeleteClick}
            />
          ) : (
            <p className="text-gray-600 italic">No rows found in the extraction.</p>
          )}
          {sourceIndex !== null && tableData[sourceIndex] && (
            <ReviewDetailsPanel
              row={tableData[sourceIndex]}
              nameMapping={nameMapping}
            />
          )}
        </div>
        <div>
          <PDFPreviewPanel
            markdown={safeMarkdown}
            highlightText={selectedSourceText}
            highlightVersion={highlightVersion}
          />
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <button
          onClick={handleSaveExtractedData}
          disabled={Object.values(editIndexes).some(Boolean)}
          title="Confirm Data"
          className="inline-flex items-center justify-center bg-transparent border-none m-0 p-0 text-success hover:text-successDark disabled:opacity-50 focus:outline-none focus:ring-0"
        >
          <Check className="h-8 w-8" />
        </button>
      </div>
    </div>
  );
};

export default ReviewForm;