// src/pages/graph_gen/GraphBuilderPage.jsx
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import GraphDataProviderManual from '../../core/containers/graph_gen/manual_graph/GraphDataProviderManual';
import GraphEditorManual from '../../core/containers/graph_gen/manual_graph/GraphEditorManual';
import { addNodeHandlerManual, addRelationshipEntityHandlerManual } from '../../core/handlers/graph_gen/manual_graph/graphHandlersManual';
import { createGraph, updateGraph, getUserGraphs } from '../../services/api/apiHandlers';

const GraphBuilderContent = ({
  graphData,
  safeData,
  transformedData,
  updateGraphData,
  colorMode,
  setColorMode,
  isNewGraph,
  graphId,
  setGraphId
}) => {
  useEffect(() => {
    if (graphData) {
      updateGraphData(graphData);
    }
  }, [graphData, updateGraphData]);

  const [graphName, setGraphName] = useState("");

  useEffect(() => {
    if (graphData && graphData.graph_name) {
      setGraphName(graphData.graph_name);
    }
  }, [graphData]);

  // New state for handling the existing graphs dropdown
  const [showGraphDropdown, setShowGraphDropdown] = useState(false);
  const [userGraphs, setUserGraphs] = useState([]);
  const [selectedGraph, setSelectedGraph] = useState("");

  async function handleSaveGraph() {
    const payload = {
      graph_name: graphName,
      graph_data: safeData
    };
    try {
      if (!graphId) {
        // Create new graph
        const data = await createGraph(payload);
        setGraphId(data.graph_id);
        toast.success("Graph saved successfully!");
      } else {
        // Update existing graph
        await updateGraph({ graph_id: graphId, ...payload });
        toast.success("Graph updated successfully!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while saving the graph.");
    }
  }

  // Function to fetch the list of graphs
  async function handleLoadExistingGraphs() {
    try {
      const graphsResponse = await getUserGraphs();
      const graphList = Array.isArray(graphsResponse)
        ? graphsResponse
        : (graphsResponse?.graphs || []);
      setUserGraphs(graphList);
      setShowGraphDropdown(true);
    } catch (error) {
      toast.error("Error fetching graphs.");
      console.error(error);
    }
  }

  function handleSelectGraph() {
    const selected = userGraphs.find(graph => graph.graph_id === selectedGraph);
    if (selected) {
      let parsedGraphData = selected.graph_data;
      // If graph_data is a string, parse it into an object.
      if (typeof parsedGraphData === "string") {
        try {
          parsedGraphData = JSON.parse(parsedGraphData);
        } catch (error) {
          console.error("Error parsing graph_data", error);
          toast.error("Error parsing graph data.");
          return;
        }
      }
      updateGraphData(parsedGraphData);
      setGraphName(selected.graph_name || "");
      setGraphId(selected.graph_id || null);
      toast.success("Graph loaded successfully.");
      setShowGraphDropdown(false);
    } else {
      toast.error("Please select a valid graph.");
    }
  }

  return (
    <div className="min-h-screen p-4 bg-gray-50">
      <h1 className="text-2xl font-bold mb-4">
        {isNewGraph ? "Create New Graph" : "Edit Existing Graph"}
      </h1>
      <div className="mb-4">
        <button
          onClick={() => {
            updateGraphData(null);
            setGraphId(null);
            toast.info("Creating a new graph...");
          }}
          className="mr-4 px-4 py-2 bg-green-600 text-white rounded"
        >
          New Graph
        </button>
        <button
          onClick={handleLoadExistingGraphs}
          className="px-4 py-2 bg-blue-600 text-white rounded"
        >
          Load Existing Graph
        </button>
      </div>
      {showGraphDropdown && (
        <div className="mb-4">
          <select
            value={selectedGraph}
            onChange={(e) => setSelectedGraph(e.target.value)}
            className="p-2 border rounded"
          >
            <option value="">Select a graph</option>
            {userGraphs.map((graph) => (
              <option key={graph.graph_id} value={graph.graph_id}>
                {graph.graph_name}
              </option>
            ))}
          </select>
          <button
            onClick={handleSelectGraph}
            className="ml-2 px-4 py-2 bg-blue-600 text-white rounded"
          >
            Load Graph
          </button>
        </div>
      )}
      <div className="mb-4">
        <label htmlFor="graphName" className="block text-sm font-medium mb-1">
          Graph Name:
        </label>
        <input
          id="graphName"
          type="text"
          value={graphName}
          onChange={(e) => setGraphName(e.target.value)}
          className="w-full p-2 border rounded"
          placeholder="Enter graph name"
        />
      </div>
      <div className="mb-4">
        <button
          onClick={handleSaveGraph}
          className="px-4 py-2 bg-purple-600 text-white rounded"
        >
          Save Graph
        </button>
      </div>
      <GraphEditorManual
        colorMode={colorMode}
        setColorMode={setColorMode}
        handleAddNode={(nodeData) => addNodeHandlerManual(nodeData, updateGraphData)}
        handleAddRelationshipEntity={(entityData) =>
          addRelationshipEntityHandlerManual(entityData, updateGraphData)
        }
        transformedData={transformedData}
        safeData={safeData}
        updateGraphData={updateGraphData}
        handleElementSelect={(data) => console.log("Selected element:", data)}
        overlayProps={{}}
      />
    </div>
  );
};

const ManualGraph = () => {
  const [graphData, setGraphData] = useState(null);
  const [isNewGraph, setIsNewGraph] = useState(true);
  const [colorMode, setColorMode] = useState("colorless");
  const [graphId, setGraphId] = useState(null);

  return (
    <GraphDataProviderManual mode="ExtensiveOntology" colorMode={colorMode}>
      {({ safeData, transformedData, updateGraphData }) => (
        <GraphBuilderContent
          graphData={graphData}
          safeData={safeData}
          transformedData={transformedData}
          updateGraphData={updateGraphData}
          colorMode={colorMode}
          setColorMode={setColorMode}
          isNewGraph={isNewGraph}
          graphId={graphId}
          setGraphId={setGraphId}
        />
      )}
    </GraphDataProviderManual>
  );
};

export default ManualGraph;