// src/organizations/biodiversity/SpeciesPortal.jsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import HomeSpeciesPage from "./pages/HomeSpeciesPage.tsx";
import SpeciesDetail from "./pages/SpeciesDetailPage.tsx";
import ReviewPage from "./pages/ReviewPage.jsx";
import UploadPage from "./pages/UploadPage.tsx"; // Import the new Upload page
import { SpeciesErrorBoundaryWrapper } from "./services/SpeciesErrorBoundary.tsx";

const queryClient = new QueryClient();

const SpeciesPortal = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="relative min-h-screen w-full bg-paper">
        <main className="">
          <SpeciesErrorBoundaryWrapper>
            <Routes>
              <Route index element={<HomeSpeciesPage />} />
              <Route path="upload" element={<UploadPage />} /> {/* New upload route */}
              <Route path=":id" element={<SpeciesDetail />} />
              <Route path="review" element={<ReviewPage />} />
            </Routes>
          </SpeciesErrorBoundaryWrapper>
        </main>
      </div>
    </QueryClientProvider>
  );
};

export default SpeciesPortal;