// src/pages/demo/MorphingSpreadsheetHighScale.jsx
import React, { useState } from 'react';
import './MorphingSpreadsheetHighScale.css';

// Define grid dimensions for 150,000 cells (300 rows x 500 columns)
const rows = 300;
const columns = 500;
const cellWidth = 20;   // in pixels
const cellHeight = 10;  // in pixels

// Total grid size (used for centering the flat layout)
const totalWidth = columns * cellWidth;
const totalHeight = rows * cellHeight;

// --- Flat Layout ---
// Arrange cells in a simple 2D grid centered in the scene.
const generateFlatPositions = () => {
  const positions = [];
  for (let r = 0; r < rows; r++) {
    for (let c = 0; c < columns; c++) {
      const x = c * cellWidth - totalWidth / 2 + cellWidth / 2;
      const y = r * cellHeight - totalHeight / 2 + cellHeight / 2;
      positions.push({ x, y, z: 0 });
    }
  }
  return positions;
};

// --- Cylindrical Layout ---
// Map the columns to an arc from -90° to +90° while keeping rows flat.
const generateCylinderPositions = () => {
  const positions = [];
  const angleStart = -Math.PI / 2;
  const angleEnd = Math.PI / 2;
  const cylinderRadius = totalWidth / (angleEnd - angleStart);
  for (let r = 0; r < rows; r++) {
    for (let c = 0; c < columns; c++) {
      const t = c / (columns - 1);
      const angle = angleStart + t * (angleEnd - angleStart);
      const x = cylinderRadius * Math.sin(angle);
      const z = cylinderRadius * (Math.cos(angle) - 1);
      const y = r * cellHeight - totalHeight / 2 + cellHeight / 2;
      positions.push({ x, y, z });
    }
  }
  return positions;
};

// --- Helix Layout ---
// For a cooler twist, each row becomes its own helix (3 full turns).
const generateHelixPositions = () => {
  const positions = [];
  const turns = 3;
  // Choose a radius relative to the grid’s width (adjust as desired)
  const helixRadius = totalWidth / (6 * Math.PI);
  for (let r = 0; r < rows; r++) {
    for (let c = 0; c < columns; c++) {
      const t = c / (columns - 1);
      const angle = t * (2 * Math.PI * turns);
      const x = helixRadius * Math.cos(angle);
      const z = helixRadius * Math.sin(angle);
      const y = r * cellHeight - totalHeight / 2 + cellHeight / 2;
      positions.push({ x, y, z });
    }
  }
  return positions;
};

const shapes = {
  flat: generateFlatPositions(),
  cylinder: generateCylinderPositions(),
  helix: generateHelixPositions(),
};

const MorphingSpreadsheetHighScale = () => {
  const [currentShape, setCurrentShape] = useState('flat');
  const [rotation, setRotation] = useState(0);
  const positions = shapes[currentShape];

  return (
    <div className="highscale-scene">
      <div className="controls">
        <button onClick={() => setCurrentShape('flat')}>Flat</button>
        <button onClick={() => setCurrentShape('cylinder')}>Cylinder</button>
        <button onClick={() => setCurrentShape('helix')}>Helix</button>
        <label>
          Rotate:
          <input
            type="range"
            min="0"
            max="360"
            value={rotation}
            onChange={(e) => setRotation(e.target.value)}
          />
          <span>{rotation}°</span>
        </label>
      </div>
      <div
        className="highscale-structure"
        style={{ transform: `rotateY(${rotation}deg)` }}
      >
        {positions.map((pos, index) => (
          <div
            key={index}
            className="highscale-cell"
            style={{
              width: `${cellWidth}px`,
              height: `${cellHeight}px`,
              transform: `translate3d(${pos.x}px, ${pos.y}px, ${pos.z}px)`,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default MorphingSpreadsheetHighScale;