// src/core/ui/graph_gen/common/GraphOverlayContainer.jsx
import React from 'react';
import PropTypes from 'prop-types';
import KnowledgeGraph from './KnowledgeGraph';
import ElementInfoOverlay from './element_info_overlay/ElementInfoOverlay';

const GraphOverlayContainer = React.forwardRef(
  ({ graphData, graphHeight, onElementSelect, styleSheet, ...rest }, ref) => (
    <div className="relative" style={{ height: graphHeight }} ref={ref}>
      <div className="relative flex flex-grow">
        <div className="w-full relative" style={{ height: graphHeight, overflowY: 'auto' }}>
          <KnowledgeGraph
            newGraphData={graphData}
            height={graphHeight}
            onElementSelect={onElementSelect}
            layoutType={rest.layoutType}
            colorMode={rest.colorMode}
            mode={rest.mode}
            styleSheet={styleSheet}  
          />
        </div>

        {rest.selectedElementData && (
          <ElementInfoOverlay {...rest} />
        )}
      </div>
    </div>
  )
);

GraphOverlayContainer.propTypes = {
  graphData: PropTypes.object.isRequired,
  graphHeight: PropTypes.number.isRequired,
  onElementSelect: PropTypes.func.isRequired,
  styleSheet: PropTypes.array, // Expecting an array of style rules
  overlayProps: PropTypes.object,
};

export default GraphOverlayContainer;