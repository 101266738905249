// src/organizations/biodiversity/pages/UploadPage.tsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { Button } from "../core/common/button.tsx";
import { useExtractionProcess } from "../core/home/upload/useExtractionProcess.ts";
import { UploadSection } from "../core/home/upload/components/UploadSection.tsx";

const UploadPage = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedSpecies, setSelectedSpecies] = useState({ id: "", name: "" });
  const [isUploading, setIsUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  const { handleDirectS3Upload } = useExtractionProcess();

  const handleExtractData = async () => {
    const { handleExtractData } = await import(
      "../core/home/upload/extractionHandlers.ts"
    );
    await handleExtractData(
      selectedFiles,
      selectedSpecies,
      handleDirectS3Upload,
      setIsUploading,
      setSelectedFiles
    );
    setUploadComplete(true);
    setTimeout(() => {
      setUploadComplete(false);
      setSelectedFiles([]);
    }, 3000);
  };

  return (
    <div className="min-h-screen bg-paper p-6 space-y-8">
      {/* Header with Back Button and Centered Title (aligned similarly to ReviewPage) */}
      <div className="relative flex items-center justify-center">
        <div className="absolute left-0">
          <Link to="/species" title="Back to Species Home">
            <Button variant="outline" size="sm" className="flex items-center">
              <ArrowLeft className="mr-1 h-4 w-4" />
              Back
            </Button>
          </Link>
        </div>
        <h1 className="text-3xl sm:text-3xl font-bold text-center">
          Upload Files for Extraction
        </h1>
      </div>

      <UploadSection
        selectedFiles={selectedFiles}
        onFileSelect={setSelectedFiles}
        onSpeciesSelect={setSelectedSpecies}
        onExtractData={handleExtractData}
        isUploading={isUploading}
        uploadComplete={uploadComplete}
      />
    </div>
  );
};

export default UploadPage;