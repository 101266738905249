import React from 'react';
import PropTypes from 'prop-types';
import ElementDetails from './ElementDetails';
import SourceTextPanel from './SourceTextPanel';

const ElementInfoOverlay = ({
  sliderStyle,
  stateIndex,
  stateLabels,
  sliderMessage,
  onSliderChange,
  showSearchInput,
  toggleSearchInput,
  searchTerm,
  setSearchTerm,
  sourceContainerRef,
  sourceText,
  selectedElementData,
  traceRefs,
  graphNodes,
  relationships,
}) => {
  const hasSelection =
    selectedElementData && Object.keys(selectedElementData).length > 0;

  return (
    <>
      {hasSelection && (
        <div className="absolute top-2 right-2 z-20 w-64 flex flex-col gap-2">
          <ElementDetails
            selectedElementData={selectedElementData}
            graphNodes={graphNodes}
            relationships={relationships}
          />
          <SourceTextPanel
            showSearchInput={showSearchInput}
            toggleSearchInput={toggleSearchInput}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            sourceContainerRef={sourceContainerRef}
            sourceText={sourceText}
            traceRefs={traceRefs}
          />
        </div>
      )}
    </>
  );
};

ElementInfoOverlay.propTypes = {
  sliderStyle: PropTypes.object,
  stateIndex: PropTypes.number,
  stateLabels: PropTypes.arrayOf(PropTypes.string),
  sliderMessage: PropTypes.string,
  onSliderChange: PropTypes.func,
  showSearchInput: PropTypes.bool.isRequired,
  toggleSearchInput: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  sourceContainerRef: PropTypes.object.isRequired,
  sourceText: PropTypes.string.isRequired,
  selectedElementData: PropTypes.object,
  traceRefs: PropTypes.arrayOf(PropTypes.string),
  graphNodes: PropTypes.array,
  relationships: PropTypes.array,
};

ElementInfoOverlay.defaultProps = {
  sliderStyle: null,
  stateIndex: 0,
  stateLabels: [],
  sliderMessage: '',
  onSliderChange: () => {},
  selectedElementData: {},
  traceRefs: [],
  graphNodes: [],
  relationships: [],
};

export default ElementInfoOverlay;