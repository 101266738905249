// src/utils/graph_gen/build_brain/useGraphGeneration.js
import react, {useState}  from 'react';
import { toast } from 'react-toastify';
import { fetchGraph } from '../../../../services/api/apiHandlers';

export function useGraphGeneration(domainId, selectedOntology) {
  const [inputText, setInputText] = useState('');
  const [generatedSourceText, setGeneratedSourceText] = useState('');

  const processStream = async (response, skeletonFlag = false) => {
    try {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split("\n");
        buffer = lines.pop();
        for (const line of lines) {
          if (line.trim()) {
            try {
              const chunk = JSON.parse(line);
              if (chunk.status === "still_processing") {
                // Optionally update status
              } else if (chunk.status === "completed" && chunk.data) {
                // Normalize the returned graph data
                const normalizedData = chunk.data;
                if (chunk.text) setGeneratedSourceText(chunk.text);

              }
            } catch (err) {
              console.error("[processStream] Error parsing JSON chunk:", err, "Line:", line);
            }
          }
        }
      }
    } catch (err) {
      console.error("[processStream] Error reading stream:", err);
    }
  };
  
const handleSkeletonSubmit = async (text, docId) => {
  const textToProcess = text !== undefined ? text : inputText;
  if (!docId) {
    toast.error("No valid document id provided for extraction");
    return;
  }
  // Ensure an ontology has been selected.
  if (!selectedOntology) {
    toast.error("Please select an ontology before generating the skeleton.");
    console.error("[handleSkeletonSubmit] No selectedOntology found.");
    return;
  }
  if (!selectedOntology.ontology_data) {
    toast.error("Selected ontology does not have valid ontology data.");
    console.error("[handleSkeletonSubmit] Invalid ontology_data in selectedOntology:", selectedOntology);
    return;
  }
  let clonedSkeleton;
  if (typeof selectedOntology.ontology_data === "string") {
    try {
      clonedSkeleton = JSON.parse(selectedOntology.ontology_data);
    } catch (e) {
      toast.error("Selected ontology data is not valid JSON.");
      console.error("[handleSkeletonSubmit] Error parsing ontology_data:", e);
      return;
    }
  } else {
    clonedSkeleton = JSON.parse(JSON.stringify(selectedOntology.ontology_data));
  }

  // *** Normalize the ontology data to legacy format ***
  const normalizedSkeleton = clonedSkeleton;

  setGeneratedSourceText(textToProcess);
  setInputText('');
  try {
    const payload = {
      text: textToProcess,
      customization: "",
      document_id: docId,
      domain_id: domainId,
      skeleton: normalizedSkeleton,
      ontology_id: selectedOntology.ontology_id,
      endpoint: "/models/raspberry_8_with_chunking"
    };
    console.log("[handleSkeletonSubmit] Payload for skeleton generation:", payload);
    const response = await fetchGraph(payload);
    await processStream(response, true);
  } catch (error) {
    console.error("[handleSkeletonSubmit] Error during skeleton generation:", error);
    toast.error("Failed to generate skeleton graph");
  }
};

  return {
    inputText,
    setInputText,
    generatedSourceText,
    handleSkeletonSubmit,
  };
}

export default useGraphGeneration;