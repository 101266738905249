import modifyDataExtensive from './prepare_filtered_graph_elements/ExtensiveDataModifier';
import modifyDataOntology from './prepare_filtered_graph_elements/OntologyDataModifier';
import modifyDataKnowledge from './prepare_filtered_graph_elements/KnowledgeDataModifier';

export const transformModeData = (data, mode, colorMode) => {
  const dataCopy = JSON.parse(JSON.stringify(data));
  if (mode === "Attribute") {
    return dataCopy;
  }
  if (mode === "Extensive") {
    Object.assign(dataCopy, modifyDataExtensive(dataCopy));
  } else if (mode === "Ontology") {
    Object.assign(dataCopy, modifyDataOntology(dataCopy));
  } else if (mode === "Knowledge") {
    Object.assign(dataCopy, modifyDataKnowledge(dataCopy));
  }
  if (dataCopy.Entities && Array.isArray(dataCopy.Entities.Entities)) {
    if (colorMode === "colorless") {
      dataCopy.Entities.Entities.forEach(entity => {
        entity.baseColor = "#ffffff";
      });
    } else if (colorMode === "colorful") {
      const uniqueTypes = Array.from(new Set(
        dataCopy.Entities.Entities.map(entity => entity.Type || "default")
      )).sort();
      const minGrey = 50;
      const maxGrey = 230;
      const numTypes = uniqueTypes.length;
      const typeMapping = {};
      uniqueTypes.forEach((type, index) => {
        const greyValue = numTypes > 1 
          ? Math.round(minGrey + ((maxGrey - minGrey) * index) / (numTypes - 1))
          : Math.round((minGrey + maxGrey) / 2);
        const hex = greyValue.toString(16).padStart(2, '0');
        typeMapping[type] = `#${hex}${hex}${hex}`;
      });
      dataCopy.Entities.Entities.forEach(entity => {
        const type = entity.Type || "default";
        entity.baseColor = typeMapping[type];
      });
    }
  }
  if (dataCopy.Relationships) {
    if (dataCopy.Relationships.TypeRelationships && Array.isArray(dataCopy.Relationships.TypeRelationships)) {
      dataCopy.Relationships.TypeRelationships.forEach(rel => {
        rel.relationshipType = "Type";
        if (colorMode === "colorless") {
          rel.baseColor = "#000000";
          rel.color = "#000000";
          rel.forceBlack = true;
        }
      });
    }
    if (dataCopy.Relationships.AttributeRelationships && Array.isArray(dataCopy.Relationships.AttributeRelationships)) {
      dataCopy.Relationships.AttributeRelationships.forEach(rel => {
        rel.relationshipType = "Defined";
        if (typeof rel.oneToOne === 'undefined') {
          rel.oneToOne = false;
        }
        if (colorMode === "colorless") {
          rel.baseColor = "#000000";
          rel.color = "#000000";
          rel.forceBlack = true;
        } else {
          rel.baseColor = "#555555";
          rel.color = "#555555";
        }
      });
    }
    if (dataCopy.Relationships.DefinitionMapping && Array.isArray(dataCopy.Relationships.DefinitionMapping)) {
      dataCopy.Relationships.DefinitionMapping.forEach(rel => {
        if (rel.PluralityType) {
          if (rel.PluralityType === "OneToOne") {
            rel.oneToOne = true;
          } else {
            rel.oneToOne = false;
          }
          rel.relationshipType = "Defined";
        } else {
          rel.relationshipType = "DefinitionMapping";
        }
        if (colorMode === "colorless") {
          rel.baseColor = "#000000";
          rel.color = "#000000";
          rel.forceBlack = true;
        } else {
          rel.baseColor = "#555555";
          rel.color = "#555555";
        }
      });
    }
  }
  return dataCopy;
};

export default transformModeData;