// src/core/ui/general/home/ArchitectureDesignTriangle.jsx
import React from 'react';
import { FaDraftingCompass, FaPencilAlt, FaLightbulb } from 'react-icons/fa';
import { HiOutlineCubeTransparent } from 'react-icons/hi';
const ArchitectureDesignTriangle = () => {
  const iconSize = 70; // Adjust as needed

  return (
    <div className="relative" style={{ width: '200px', height: '200px' }}>
      <div className="absolute inset-0 flex flex-col justify-center items-center pointer-events-none">
        <span
          style={{ transform: 'translateY(-95px) translateX(0px)' }}
          className="text-xl font-bold text-[#1d1d22] whitespace-nowrap mb-1"
        >
          Design your dataset
        </span>
      </div>
      <div
        className="absolute"
        style={{
          top: '38%',
          left: '50%',
          transform: 'translate(-40%, -40%)',
        }}
      >
        <HiOutlineCubeTransparent size={iconSize} color="#1d1d22" title="Architecture" />
      </div>

      <div
        className="absolute"
        style={{
          top: '59%',
          left: '38%',
          transform: 'translate(-40%, -40%)',
        }}
      >
        <HiOutlineCubeTransparent size={iconSize} color="#1d1d22" title="Design" />
      </div>
      <div
        className="absolute"
        style={{
          top: '59%',
          left: '62%',
          transform: 'translate(-40%, -40%)',
        }}
      >
        < HiOutlineCubeTransparent size={iconSize} color="#1d1d22" title="Innovation" />
      </div>
    </div>
  );
};

export default ArchitectureDesignTriangle;