import React from 'react';
import PropTypes from 'prop-types';
import { FaCog, FaPlus, FaTimes } from 'react-icons/fa';

const DomainCard = ({ 
  domain, 
  documents, 
  formState, 
  toggleFormVisibility, 
  handleInputChange, 
  handleDocumentSubmit,
  handleNavigateToBuildBrain
}) => {
  return (
    <div className="bg-white shadow rounded p-6 flex flex-col space-y-4 relative">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-bold">{domain.name}</h2>
        <button
          onClick={() => handleNavigateToBuildBrain(domain)}
          title="View Build Brain for this domain"
          className="p-2 rounded-full bg-primary text-white hover:bg-primaryForeground focus:outline-none"
        >
          <FaCog size={18} />
        </button>
      </div>
      {/* Existing Documents */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold">Documents:</h3>
        {documents && documents.length > 0 ? (
          <ul className="list-disc pl-5">
            {documents.map((doc) => (
              <li key={doc.document_id}>
                <p className="font-semibold">{doc.name}</p>
                <p className="text-sm text-gray-600 truncate">{doc.text}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">{documents ? "No documents yet." : "Loading documents..."}</p>
        )}
      </div>
      {/* Toggle Document Form Button */}
      <button
        onClick={() => toggleFormVisibility(domain.domain_id)}
        className="p-2 rounded-full bg-secondary hover:bg-gray-200 focus:outline-none"
        title={formState?.showForm ? "Cancel" : "Add Document"}
      >
        {formState?.showForm ? <FaTimes size={18} /> : <FaPlus size={18} />}
      </button>
      {formState?.showForm && (
        <form
          onSubmit={(e) => handleDocumentSubmit(domain.domain_id, e)}
          className="space-y-4 mt-4"
        >
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Document Name
            </label>
            <input
              type="text"
              value={formState.documentName}
              onChange={(e) =>
                handleInputChange(domain.domain_id, "documentName", e.target.value)
              }
              placeholder="Enter document name"
              className="w-full border rounded px-3 py-2 focus:outline-none focus:border-success"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Document Text
            </label>
            <textarea
              value={formState.documentText}
              onChange={(e) =>
                handleInputChange(domain.domain_id, "documentText", e.target.value)
              }
              placeholder="Enter document text"
              className="w-full border rounded px-3 py-2 focus:outline-none focus:border-success"
              rows="4"
            />
          </div>
          <button
            type="submit"
            className="w-full p-2 rounded bg-green-500 text-white hover:bg-green-600 focus:outline-none"
          >
            Create Document
          </button>
        </form>
      )}
    </div>
  );
};

DomainCard.propTypes = {
  domain: PropTypes.object.isRequired,
  documents: PropTypes.array,
  formState: PropTypes.object,
  toggleFormVisibility: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleDocumentSubmit: PropTypes.func,
  handleNavigateToBuildBrain: PropTypes.func,
};

DomainCard.defaultProps = {
  documents: [],
  formState: { showForm: false, documentName: '', documentText: '' },
  toggleFormVisibility: () => {},
  handleInputChange: () => {},
  handleDocumentSubmit: () => {},
  handleNavigateToBuildBrain: () => {},
};

export default DomainCard;