// src/core/ui/general/home/HeroSection.jsx
import React from 'react';
import Button from '../../general/controls/Button';

const HeroSection = () => {
  // Define the scheduling URL
  const schedulingUrl = "https://calendar.google.com/calendar/appointments/schedules/AcZssZ12c-di5o0hYiT-FYvpv78_SQk8ziuxQlTcwmWa3J8mFXp2nwApjk0I-2p3waQObwoEnVmzX0tU?gv=true";

  // Handler that opens the scheduling URL in a new tab
  const handleBookDemoClick = () => {
    window.open(schedulingUrl, "_blank");
  };

  return (
    <header>
      <div className="max-w-7xl mx-auto px-6 py-10 text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Unlocking Knowledge
        </h1>
        <p className="text-gray-600 leading-relaxed max-w-3xl mx-auto">
          Atlaz helps you construct, curate and enrich your datasets, your imagination sets the limits.
        </p>
        <div className="mt-8 flex justify-center space-x-4">
          <Button onClick={handleBookDemoClick} variant="secondary">
            Learn More
          </Button>
          <Button onClick={handleBookDemoClick} variant="success">
            Book a Demo
          </Button>
        </div>
      </div>
    </header>
  );
};

export default HeroSection;
