// src/organizations/biodiversity/handlers/speciesHandlers.ts
import { toast } from "sonner";
import { deleteSpecies } from "../../../services/api.ts";
import { QueryClient } from "@tanstack/react-query";

/**
 * Deletes a species and refreshes the species query.
 *
 * @param speciesId - The ID of the species to delete.
 * @param queryClient - The React Query client instance.
 */
export async function handleDeleteSpecies(
  speciesId: number,
  queryClient: QueryClient
) {
  try {
    await deleteSpecies(speciesId);
    toast.success("Species deleted successfully!");
    queryClient.invalidateQueries({ queryKey: ["species"] });
  } catch (err: any) {
    toast.error("Error deleting species: " + err.message);
  }
}