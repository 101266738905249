// src/pages/general/PdfViewer.jsx
import React from 'react';

const PdfViewer = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="w-1/5 h-[80vh] border rounded shadow-md overflow-auto bg-white">
        <iframe
          src="/ex.pdf"
          title="PDF Viewer"
          className="w-full h-full"
          frameBorder="0"
        />
      </div>
    </div>
  );
};

export default PdfViewer;