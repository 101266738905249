// src/organizations/biodiversity/core/home/upload/components/UploadForm.tsx
import { FileUpload } from "./FileUpload.tsx";
import { SpeciesSelect } from "./SpeciesSelect.tsx";
import { Button } from "../../../common/button.tsx";
import { Settings } from "lucide-react"; // Using the cog icon

interface UploadFormProps {
  onSpeciesSelect: (speciesData: { id: string; name: string }) => void;
  onFileSelect: (files: File[]) => void;
  onExtractData: () => void;
  selectedFiles: File[];
  isUploading?: boolean;
  uploadComplete?: boolean; // New optional prop
}

export const UploadForm = ({
  selectedFiles,
  onFileSelect,
  onExtractData,
  onSpeciesSelect,
  isUploading,
  uploadComplete,
}: UploadFormProps) => {
  return (
    <div className="">
      <div className="bg-white rounded-lg">
        <FileUpload
          files={selectedFiles}
          onFileSelect={onFileSelect}
          isUploading={isUploading}
          uploadComplete={uploadComplete} // Pass along the state
        />
      </div>
      <div className="flex justify-end items-center mt-4 gap-4">
        <SpeciesSelect
          onSelect={(selectedSpecies) => {
            onSpeciesSelect(selectedSpecies);
          }}
        />
        <Button
          onClick={onExtractData}
          variant="outline"
          className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 text-[#1d1d22] hover:bg-gray-50 transition-colors"
        >
          <Settings className="mr-2 h-4 w-4" />
          Extract
        </Button>
      </div>
    </div>
  );
};

export default UploadForm;