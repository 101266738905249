// src/core/ui/graph_gen/common/KnowledgeGraph.jsx
import React, { useMemo } from 'react';
import Cytoscape from 'cytoscape';
import fcose from 'cytoscape-fcose';
import dagre from 'cytoscape-dagre';
import { prepareNewGraphElements } from '../../../helpers/graph_gen/common/knowledge_graph/prepareNewGraphElements';
import { prepareFilteredGraphElements } from '../../../helpers/graph_gen/common/knowledge_graph/prepareFilteredGraphElements';
import GraphContainer from '../../../containers/graph_gen/common/GraphContainer';
import { getTextWidth } from '../../../helpers/graph_gen/common/knowledge_graph/prepare_new_graph_elements/prepareNodes';

Cytoscape.use(fcose);
Cytoscape.use(dagre);

const KnowledgeGraph = ({
  newGraphData,
  height = 600,
  onElementSelect,
  layoutType,
  colorMode = "colorful",
  mode = "Extensive",
  styleSheet
}) => {
  const initialElements = useMemo(() => {
    if (mode === "Knowledge") {
      // Pass colorMode so that even in Knowledge mode the grey mode is respected.
      return prepareFilteredGraphElements(newGraphData, colorMode);
    }
    return prepareNewGraphElements(newGraphData, colorMode);
  }, [newGraphData, mode, colorMode]);

  const dynamicElements = useMemo(() => {
    const nodes = initialElements.filter(el => !el.data.source);
    let maxWidth = 0;
    let maxHeight = 0;
    const paddingWidth = 4;
    const paddingHeight = 4;
    nodes.forEach(node => {
      const label = node.data.label || "";
      const width = getTextWidth(label, "16px serif") + paddingWidth;
      const height = 16 + paddingHeight;
      if (width > maxWidth) maxWidth = width;
      if (height > maxHeight) maxHeight = height;
    });
    return initialElements.map(el => {
      if (!el.data.source) {
        return { 
          ...el, 
          data: { 
            ...el.data, 
            nodeWidth: maxWidth, 
            nodeHeight: maxHeight 
          } 
        };
      }
      return el;
    });
  }, [initialElements]);

  return (
    <GraphContainer 
      elements={dynamicElements}
      layoutType={layoutType}
      onElementSelect={onElementSelect}
      stylesheet={styleSheet}
      height={`${height}px`}
    />
  );
};

export default KnowledgeGraph;