import { useState } from "react";
import { Loader2 } from "lucide-react";
import { Card } from "../../common/card.tsx";
import { toast } from "sonner";
import { Button } from "../../common/button.tsx";
import { gatherImagesForSpecies, saveImageForSpecies } from "../../../services/api.ts";

export const ImageSearch = ({ speciesName, onImageSelect }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasGathered, setHasGathered] = useState(false);

  async function handleGatherImages() {
    if (!speciesName) {
      toast.error("No species name provided!");
      return;
    }
    setLoading(true);
    setError(null);
    setHasGathered(true);
    try {
      const data = await gatherImagesForSpecies(speciesName);
      if (data.images && data.images.length > 0) {
        setImages(data.images);
      } else {
        setImages([]);
        toast.error("No images found for this species.");
      }
    } catch (err) {
      console.error("Error fetching images:", err);
      setError(err.message || "An unexpected error occurred.");
      toast.error(err.message || "An unexpected error occurred while fetching images.");
    } finally {
      setLoading(false);
    }
  }

  async function handleImageClick(imgUrl) {
    try {
      const data = await saveImageForSpecies(speciesName, imgUrl);
      toast.success(data.message);
      if (onImageSelect) {
        onImageSelect(imgUrl);
      }
    } catch (error) {
      toast.error("An error occurred while saving the image.");
      console.error("Error saving image:", error);
    }
  }

  return (
    <Card className="p-6">
      <h3 className="text-lg font-medium mb-4">
        Gather Images for: <em>{speciesName}</em>
      </h3>
      <Button onClick={handleGatherImages} disabled={loading} className="text-white">
        {loading ? "Loading..." : "Gather Images"}
      </Button>

      {loading && (
        <div className="flex justify-center items-center mt-4">
          <Loader2 className="animate-spin h-6 w-6 text-primary" />
          <span className="ml-2">Loading images...</span>
        </div>
      )}

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {!loading && images.length > 0 && (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 mt-4">
          {images.map((imgUrl, index) => (
            <img
              key={index}
              src={imgUrl}
              alt={`${speciesName} result #${index + 1}`}
              className="object-cover w-full h-32 rounded-md border shadow hover:shadow-lg transition-shadow cursor-pointer"
              onClick={() => handleImageClick(imgUrl)}
            />
          ))}
        </div>
      )}
      {!loading && images.length === 0 && !error && hasGathered && (
        <p className="text-gray-500 mt-4">
          No images available. Try gathering again or check your query.
        </p>
      )}
    </Card>
  );
};