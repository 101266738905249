// src/organizations/biodiversity/core/home/vis_species/SpeciesHeaderSection.tsx
import React, { useEffect, useRef, useState } from "react";
import { Search, PlusIcon } from "lucide-react";
import { Input } from "../common/input.tsx";
import { Button } from "../../common/button.tsx";
import { AddSpeciesForm } from "../species_add/AddSpeciesForm.tsx";

interface SpeciesHeaderSectionProps {
  searchQuery: string;
  onSearchChange: (value: string) => void;
  onToggleNewSpecies: () => void;
  showNewForm: boolean;
}

export const SpeciesHeaderSection: React.FC<SpeciesHeaderSectionProps> = ({
  searchQuery,
  onSearchChange,
  onToggleNewSpecies,
  showNewForm,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [popupHeight, setPopupHeight] = useState(0);
  const extraPadding = 10; // extra space in pixels

  useEffect(() => {
    if (!showNewForm || !popupRef.current) {
      setPopupHeight(0);
      return;
    }

    // Create a ResizeObserver to update the height whenever the popup changes
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setPopupHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(popupRef.current);

    return () => resizeObserver.disconnect();
  }, [showNewForm]);

  return (
    // The container’s bottom padding is set to the popup’s height plus an extra offset.
    <div
      className="w-full sm:w-1/2 relative"
      style={{ paddingBottom: popupHeight ? `${popupHeight + extraPadding}px` : undefined }}
    >
      <div className="flex items-center space-x-4">
        {/* Search Input */}
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <Search className="h-5 w-5 text-primary" />
          </div>
          <Input
            type="search"
            placeholder="Search species..."
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
            className="pl-10 min-w-[150px] max-w-[200px]"
          />
        </div>

        {/* Button and Popup Container */}
        <div className="relative inline-block">
          <Button
            onClick={onToggleNewSpecies}
            className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 text-[#1d1d22] hover:bg-gray-50 transition-colors"
          >
            <PlusIcon className="w-6 h-6 mr-2" style={{ strokeWidth: 2 }} />
            New Species
          </Button>

          {/* Popup positioned immediately to the right of the button */}
          {showNewForm && (
            <div ref={popupRef} className="absolute top-0 left-full ml-2 w-[400px]">
              <AddSpeciesForm />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
