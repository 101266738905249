import React from "react";
import { FileText, Trash } from "lucide-react";

/**
 * Renders the table of data for a single section.
 *  - Only the first section (sectionIndex === 0) will display icons (document/trash).
 */
export default function IndicatorsTable({
  sectionIndex,
  fields,
  pdfExtractions,
  fieldLabels,
  formatValue,
  onDeleteExtraction,
}) {
  return (
    <table className="table-fixed w-full text-[10px] sm:text-[11px]">
      <colgroup>
        <col className="whitespace-nowrap" />
        {pdfExtractions.map((_, i) => (
          <col key={i} style={{ width: "40px" }} />
        ))}
      </colgroup>

      <thead>
        <tr className="text-xs">
          {/* First column for the field label */}
          <th className="text-left p-1"></th>

          {/* One column per PDF extraction */}
          {pdfExtractions.map((pdf, i) => (
            <th key={i} className="font-normal align-middle text-right p-1">
              {sectionIndex === 0 && (
                <div className="relative group flex flex-col items-end justify-center h-10 w-full leading-none">
                  <FileText className="h-3 w-3 text-gray-600" />
                  <Trash
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteExtraction?.(pdf.id);
                    }}
                    className="mt-1 h-3 w-3 text-red-600 hover:text-red-800"
                    title="Delete PDF Extraction"
                  />
                  <span
                    className="absolute bottom-full mb-1 hidden whitespace-nowrap rounded bg-black px-1 py-0.5 text-[8px] text-white group-hover:block transition-opacity duration-200 ease-in-out"
                    role="tooltip"
                  >
                    {pdf.pdfName}
                  </span>
                </div>
              )}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {fields.map((field) => (
          <tr key={field}>
            <td className="whitespace-nowrap font-medium text-left pr-1 align-middle p-1">
              {fieldLabels[field]}
            </td>
            {pdfExtractions.map((pdf, i) => (
              <td
                key={i}
                className="text-right whitespace-nowrap align-middle p-1"
              >
                {formatValue(field, pdf.fields[field])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}