// src/utils/cytoscapeStyles.js
import colors from '../../../helpers/legacy/theme_colors.js';

export const cyStylesheet = [
  {
    selector: 'node[color = "#1d1d22"], node[color = "#45454a"], node[color = "#3b3b40"], node[color = "#2a2a2f"], node[color = "#5C5C62"], node[color = "#5c5c62"]',
    style: { color: '#ffffff' },
  },
  {
    selector: 'node:parent[color = "#1d1d22"], node:parent[color = "#45454a"], node:parent[color = "#3b3b40"], node:parent[color = "#2a2a2f"], node:parent[color = "#5C5C62"], node:parent[color = "#5c5c62"]',
    style: { color: '#ffffff' },
  },
  {
    selector: 'node',
    style: {
      'background-color': 'data(color)',
      shape: 'data(shape)',
      label: 'data(label)',
      'text-wrap': 'wrap',
      'text-max-width': 100,
      'font-size': '14px',
      'text-valign': 'center',
      'text-halign': 'center',
      width: 'label',
      height: 'label',
      padding: '10px',
      'border-color': '#000000',
      'border-width': '1px',
      'z-index': 10,
      cursor: 'pointer',
    },
  },
  {
    selector: 'node:parent',
    style: {
      'background-color': 'data(color)',
      shape: 'data(shape)',
      label: 'data(label)',
      'text-valign': 'top',
      'text-halign': 'center',
      'text-wrap': 'wrap',
      'text-max-width': 150,
      'font-size': '16px',
      padding: '20px',
      'border-color': '#000000',
      'border-width': '1px',
      'z-index': 10,
      cursor: 'pointer',
    },
  },
  {
    selector: 'edge',
    style: {
      'line-color': 'data(color)',
      width: '1',
      'line-style': 'data(style)',
      'target-arrow-shape': 'data(arrowShape)',
      'target-arrow-color': 'data(color)',
      'curve-style': 'bezier',
      label: 'data(label)',
      'font-size': '12px',
      color: '#1d1d22',
      'text-background-color': '#fff',
      'text-background-opacity': 0.7,
      'text-rotation': 'autorotate',
      'text-margin-y': '-10px',
      'edge-text-rotation': 'autorotate',
      'z-index': 10,
      cursor: 'pointer',
    },
  },
  {
    selector: '.cluster',
    style: {
      'background-color': 'data(color)',
      shape: 'data(shape)',
      label: 'data(label)',
      'text-valign': 'top',
      'text-halign': 'center',
      'font-size': '16px',
      'z-index': 10,
      cursor: 'pointer',
    },
  },
  {
    selector: '.highlighted',
    style: {
      'border-color': colors.graphBorderHighlight,
      'border-width': 4,
      'background-color': colors.graphHighlight,
      width: 100,
      height: 100,
      'font-size': '16px',
    },
  },
];