// src/core/handlers/graph_gen/build_brain/merge/processOntology.js
import { makeSafeData } from '../../../../helpers/graph_gen/common/makeSafeData';

export function processOntology(ontologyData) {
  const safeOntology = makeSafeData(ontologyData);

  // if (safeOntology.Entities && Array.isArray(safeOntology.Entities.Entities)) {
    // safeOntology.Entities.Entities = safeOntology.Entities.Entities.filter(node => {
    //   const isFlagged = node.isRelationshipEntity === true ||
    //                     String(node.isRelationshipEntity).toLowerCase() === 'true';
    //   return !isFlagged;
    // });
  // }

  return safeOntology;
}