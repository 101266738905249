// src/core/handlers/graph_gen/build_brain/merge/mergeExtractions.js
import { makeSafeData } from '../../../../helpers/graph_gen/common/makeSafeData';

export function mergeExtractionData(validatedExtractions, safeOntology, merged, ontologyIdMap) {
  let nextExId = 1;
  const extractionNodeMap = new Map(); // "Name-Definition" => new ID
  const extractionIdMap = new Map();   // old extraction node ID (as string) => new ID

  validatedExtractions.forEach(extraction => {
    let parsedData = {};
    try {
      parsedData = typeof extraction.generated_data === "string"
        ? JSON.parse(extraction.generated_data)
        : extraction.generated_data;
    } catch (e) {
      console.error("Error parsing extraction data:", e);
      return;
    }

    // Determine which property holds the graph (supporting a few formats).
    const sourceGraph = parsedData.data && parsedData.data.Entities
      ? parsedData.data
      : parsedData.graph && parsedData.graph.Entities
        ? parsedData.graph
        : parsedData;
    const safeGraph = makeSafeData(sourceGraph);

    // Merge relationship entities from extraction.
    if (safeGraph.RelationshipEntities) {
      if (Array.isArray(safeGraph.RelationshipEntities.AttributeRelationshipEntities)) {
        safeGraph.RelationshipEntities.AttributeRelationshipEntities.forEach(rEnt => {
          const exists = merged.RelationshipEntities.AttributeRelationshipEntities.find(
            mEnt => String(mEnt.ID) === String(rEnt.ID)
          );
          if (!exists) {
            merged.RelationshipEntities.AttributeRelationshipEntities.push({ ...rEnt });
          }
        });
      }
      if (Array.isArray(safeGraph.RelationshipEntities.StructuralRelationshipEntities)) {
        safeGraph.RelationshipEntities.StructuralRelationshipEntities.forEach(rEnt => {
          const exists = merged.RelationshipEntities.StructuralRelationshipEntities.find(
            mEnt => String(mEnt.ID) === String(rEnt.ID)
          );
          if (!exists) {
            merged.RelationshipEntities.StructuralRelationshipEntities.push({ ...rEnt });
          }
        });
      }
    }

    // Process extraction nodes (skip those flagged as relationship entities).
    safeGraph.Entities.Entities.forEach(node => {
      const isFlagged = node.isRelationshipEntity === true ||
                        String(node.isRelationshipEntity).toLowerCase() === 'true';
      if (isFlagged) return;

      // Check if node already exists in ontology.
      const isInOntology = safeOntology.Entities.Entities.some(
        ontNode => ontNode.Name === node.Name && ontNode.Definition === node.Definition
      );
      if (isInOntology) return;

      const key = `${node.Name}-${node.Definition}`;
      if (!extractionNodeMap.has(key)) {
        const newId = `${nextExId++}`;
        extractionNodeMap.set(key, newId);
        extractionIdMap.set(String(node.ID), newId);
      } else if (!extractionIdMap.has(String(node.ID))) {
        extractionIdMap.set(String(node.ID), extractionNodeMap.get(key));
      }
      const newNodeId = extractionNodeMap.get(key);
      if (!merged.Entities.Entities.find(m => m.ID === newNodeId)) {
        merged.Entities.Entities.push({ ...node, ID: newNodeId });
      }
    });

    // Merge extraction relationships.
    ['TypeRelationships', 'AttributeRelationships', 'DefinitionMapping'].forEach(relType => {
      if (!Array.isArray(safeGraph.Relationships[relType])) return;
      safeGraph.Relationships[relType].forEach(rel => {
        const existsInOntology =
          safeOntology.Relationships &&
          Array.isArray(safeOntology.Relationships[relType]) &&
          safeOntology.Relationships[relType].some(
            ontRel =>
              ontRel.SourceNodeID === rel.SourceNodeID &&
              ontRel.TargetNodeID === rel.TargetNodeID &&
              (ontRel.Name === rel.Name || ontRel.relationshipEntityName === rel.relationshipEntityName)
          );
        if (existsInOntology) return;

        const newRel = { ...rel };
        newRel.ID = `edge_ex_${nextExId++}`;

        // Remap source.
        const srcCandidate = safeGraph.Entities.Entities.find(n => n.ID === rel.SourceNodeID);
        if (srcCandidate) {
          const ontMatch = safeOntology.Entities.Entities.find(
            ontNode => ontNode.Name === srcCandidate.Name && ontNode.Definition === srcCandidate.Definition
          );
          if (ontMatch) {
            newRel.SourceNodeID = ontologyIdMap.get(ontMatch.ID);
          } else {
            const srcKey = `${srcCandidate.Name}-${srcCandidate.Definition}`;
            if (extractionNodeMap.has(srcKey)) {
              newRel.SourceNodeID = extractionNodeMap.get(srcKey);
            }
          }
        }

        // Remap target.
        const tgtCandidate = safeGraph.Entities.Entities.find(n => n.ID === rel.TargetNodeID);
        if (tgtCandidate) {
          const ontMatch = safeOntology.Entities.Entities.find(
            ontNode => ontNode.Name === tgtCandidate.Name && ontNode.Definition === tgtCandidate.Definition
          );
          if (ontMatch) {
            newRel.TargetNodeID = ontologyIdMap.get(ontMatch.ID);
          } else {
            const tgtKey = `${tgtCandidate.Name}-${tgtCandidate.Definition}`;
            if (extractionNodeMap.has(tgtKey)) {
              newRel.TargetNodeID = extractionNodeMap.get(tgtKey);
            }
          }
        }

        // For attribute relationships, adjust the label.
        if (relType === 'AttributeRelationships') {
          let newLabel = '';
          if (merged.RelationshipEntities &&
              Array.isArray(merged.RelationshipEntities.AttributeRelationshipEntities)) {
            let foundRelEntity = null;
            if (rel.EntityID) {
              foundRelEntity = merged.RelationshipEntities.AttributeRelationshipEntities.find(
                ent => String(ent.ID) === String(rel.EntityID)
              );
            }
            if (foundRelEntity && foundRelEntity.Name) {
              newLabel = foundRelEntity.Name;
            } else if (merged.RelationshipEntities.AttributeRelationshipEntities.length === 1) {
              newLabel = merged.RelationshipEntities.AttributeRelationshipEntities[0].Name;
            } else {
              newLabel = rel.relationshipEntityName || rel.Name || 'Attribute Relationship';
            }
          } else {
            newLabel = rel.relationshipEntityName || rel.Name || 'Attribute Relationship';
          }
          newRel.label = newLabel;
        }

        merged.Relationships[relType].push(newRel);
      });
    });
  });

  return { merged, extractionIdMap };
}