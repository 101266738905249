import { processOntology } from './merge/processOntology';
import { mergeOntologyData } from './merge/mergeOntology';
import { mergeExtractionData } from './merge/mergeExtractions';
import { remapRelationshipEntities } from './merge/remapRelationshipEntities';

function cleanupEdges(merged) {
  const validNodeIds = new Set(merged.Entities.Entities.map(n => n.ID));
  // Loop over each relationship type and filter edges
  Object.keys(merged.Relationships).forEach(relType => {
    merged.Relationships[relType] = merged.Relationships[relType].filter(edge =>
      validNodeIds.has(edge.SourceNodeID) && validNodeIds.has(edge.TargetNodeID)
    );
  });
  return merged;
}

function normalizeEdgeKeys(merged) {
  Object.keys(merged.Relationships).forEach(relType => {
    merged.Relationships[relType] = merged.Relationships[relType].map(edge => {
      // If the edge does not have `source` and `target`, but has SourceNodeID/TargetNodeID, use those.
      if (!edge.source && edge.SourceNodeID) {
        return { ...edge, source: edge.SourceNodeID, target: edge.TargetNodeID };
      }
      return edge;
    });
  });
  return merged;
}

export function mergeValidatedExtractionsWithOntology(validatedExtractions, ontologyData) {
  const safeOntology = processOntology(ontologyData);
  const { merged, ontologyIdMap } = mergeOntologyData(safeOntology);
  const { merged: mergedAfterExtractions, extractionIdMap } = mergeExtractionData(
    validatedExtractions,
    safeOntology,
    merged,
    ontologyIdMap
  );
  const finalMerged = remapRelationshipEntities(mergedAfterExtractions, ontologyIdMap, extractionIdMap);
  const normalized = normalizeEdgeKeys(finalMerged);  
  return cleanupEdges(normalized);
}