// src/components/graph_gen/NewOntologyButton.jsx
import React from 'react';

const NewOntologyButton = ({ updateGraphData }) => {
  const blankOntology = {
    Entities: {
      Entities: [],
      PrimitiveDataTypeEntities: [],
    },
    Relationships: {
      TypeRelationships: [],
      AttributeRelationships: [],
      EquivalenceRelationships: [],
    },
    Decompositions: {
      TypeDecompositions: [],
      AttributeFunctionalDecompositions: [],
      AttributeAggregationDecompositions: [],
    },
    RelationshipEntities: {
      AttributeRelationshipEntities: [],
      StructuralRelationshipEntities: [],
    },
  };

  const handleNewOntology = () => {
    if (window.confirm("Are you sure you want to create a new ontology? This will clear the current graph data.")) {
      updateGraphData(blankOntology);
    }
  };

  return (
    <button
      onClick={handleNewOntology}
      className="px-4 py-2 bg-success text-white rounded mb-4 "
    >
      New Ontology
    </button>
  );
};

export default NewOntologyButton;