import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSpeciesDetail, getPdfExtractions } from "../services/api.ts";
import SpeciesDetailMain from "../core/details/SpeciesDetailMain.tsx";

export default function SpeciesDetailPage() {
  const { id } = useParams();
  const speciesId = Number(id);

  const {
    data: species,
    isLoading: isSpeciesLoading,
    isError: isSpeciesError,
    error: speciesError,
  } = useQuery({
    queryKey: ["speciesDetail", speciesId],
    queryFn: () => getSpeciesDetail(speciesId),
    enabled: !isNaN(speciesId),
  });

  const {
    data: extractionData,
    isLoading: isExtractionLoading,
    isError: isExtractionError,
    error: extractionError,
  } = useQuery({
    queryKey: ["pdfExtractions", speciesId],
    queryFn: () => getPdfExtractions(String(speciesId)),
    enabled: !isNaN(speciesId),
  });

  if (isSpeciesLoading || isExtractionLoading) {
    return <div className="p-6">Loading data for species {speciesId}...</div>;
  }
  if (isSpeciesError) {
    return (
      <div className="p-6">
        <p className="text-red-500">
          Error loading species details: {speciesError.message}
        </p>
      </div>
    );
  }
  if (isExtractionError) {
    return (
      <div className="p-6">
        <p className="text-red-500">
          Error loading PDF extractions: {extractionError.message}
        </p>
      </div>
    );
  }
  if (!species) {
    return (
      <div className="p-6">
        <p className="text-red-500">Species not found.</p>
      </div>
    );
  }

  return <SpeciesDetailMain species={species} extractionData={extractionData} />;
}